import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Layout, Menu, Avatar } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { _ROUTES } from "../../constants/GlobalConst";

const { SubMenu } = Menu;

class Header extends Component {
  _loadMenuFoldIcon = () => {
    const { isMobile, collapsed } = this.props;

    if (isMobile) {
      return collapsed ? MenuFoldOutlined : MenuUnfoldOutlined;
    } else {
      return collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
    }
  }

  _handleClickHeaderMenu = e => {
    e.key === 'SignOut' && this.props.history.push(_ROUTES.PATH_LOGOUT);
  }

  render() {
    const { isMobile, collapsed, name } = this.props;
    
    return (
      <React.Fragment>
        <Layout.Header className={`site-header ${collapsed ? `collapsed` : ``} ${isMobile ? `mobile` : ``}`}>
          <div>
            {React.createElement(this._loadMenuFoldIcon(), {
              className: 'trigger',
              onClick: this.props.toggleSidebar,
            })}
          </div>
          <div>
            <Menu key="user" mode="horizontal" onClick={this._handleClickHeaderMenu}>
              <SubMenu
                mode="horizontal"
                title={
                  <React.Fragment>
                    <span>{name}</span>
                    <Avatar style={{ marginLeft: 8, backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                  </React.Fragment>
                }>
                <Menu.Item key="SignOut">
                  Sign out
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Layout.Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: _.get(state, 'auth.login.result.response.data.email', ''),
  };
};

export default connect(mapStateToProps, null)(Header);
