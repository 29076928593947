import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Button, Row, Col, Form, Input } from 'antd';
import {
  SendOutlined,
} from '@ant-design/icons';
import { _ROUTES, _CONST } from '../../constants/GlobalConst';
import { _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class AdminRegister extends React.Component {

  constructor(props) {
    super(props)
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { create, history } = this.props;

    if (prevProps.create !== create) {
      const { result } = create;
      if (result.success) {
        _notify({ type: 'success', description: 'Admin Account is successfully created' });
        history.push(_ROUTES.PATH_ADMINS);
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
  }

  _handleFormFinish = (values) => {
    if (!this._checkCustomFieldValidation(values)) {
      this.props.createAdminAccountRequest({
        ..._.omit(values, ['confirm_password', 'role'])
      });
    }
  }

  _handleFormFinishFailed = ({ values }) => {
    this._checkCustomFieldValidation(values);
  }

  _checkCustomFieldValidation = (values) => {
    let hasError = false;

    if (values.password !== values.confirm_password) {
      hasError = true;
      this.formRef.current.setFields([{
        name: 'confirm_password',
        errors: [`Confirm Passowrd doesn't match with Password field`],
      }]);
    }

    return hasError;
  }

  render() {
    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Register Admin Account'}>
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this._handleFormFinish}
            onFinishFailed={this._handleFormFinishFailed}
            initialValues={{ role: 'admin' }}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="email" label="Email"
                  rules={[
                    { type: 'email', message: 'Email is not valid' },
                    { required: true, message: 'Email is required' }
                  ]}>
                  <Input size="large" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="role" label="Role">
                  <Input size="large" disabled />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="password" label="Password"
                  rules={[
                    { required: true, message: 'Password is required' }
                  ]}>
                  <Input type="password" size="large" placeholder="Password" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="confirm_password" label="Confirm Password"
                  rules={[
                    { required: true, message: 'Confirm Password is required' }
                  ]}>
                  <Input type="password" size="large" placeholder="Confirm Password" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="sk-button-group">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<SendOutlined />}
                  >Register</Button>
                  <Button
                    size="large"
                    onClick={() => this.props.history.push(_ROUTES.PATH_ADMINS)}
                  >Back</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  create: _.get(state, 'admin.create', {}),
});

const mapDispatchToProps = (dispatch) => ({
  createAdminAccountRequest: payload => dispatch({ type: reduxActions.CREATE_ADMIN_ACCOUNT_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRegister);
