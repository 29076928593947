import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Button, Row, Col, Form, Input, InputNumber } from 'antd';
import {
  SendOutlined,
} from '@ant-design/icons';
import { _ROUTES, _CONST } from '../../constants/GlobalConst';
import { _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class PackageAdd extends React.Component {

  constructor(props) {
    super(props)
    this.formRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { add, history } = this.props;

    if (prevProps.add !== add) {
      const { result } = add;
      if (result.success) {
        _notify({ type: 'success', description: 'Lesson Package is successfully added' });
        history.push(_ROUTES.PATH_LESSON_PACKAGES);
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
  }

  _handleFormFinish = (values) => {
    this.props.addLessonPackageRequest({
      ...values,
      items: _.map((!_.isEmpty(values.items) ? _.split(values.items, ',') : []), item => { return _.trim(item) }),
    });
  }

  render() {
    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Add Lesson Package'}>
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this._handleFormFinish}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item name="name" label="Name"
                  rules={[
                    { required: true, message: 'Name is required' }
                  ]}>
                  <Input size="large" placeholder="Lesson Package Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="number_of_lessons" label="Number of Lessons"
                  rules={[
                    { required: true, message: 'Number of Lessons is required' }
                  ]}>
                  <InputNumber
                    size="large"
                    placeholder="Number of Lessons"
                    className="full-width"
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="amount" label="Amount"
                  rules={[
                    { required: true, message: 'Amount is required' },
                    { pattern: _CONST.RX_NUMBER_ONLY, message: 'Amount is not valid' },
                  ]}>
                  <Input
                    size="large"
                    placeholder="Amount"
                    className="full-width"
                    maxLength={10}
                    prefix={_CONST.CURRENCY_SIGN}
                    suffix={"HKD"}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item name="description" label="Description"
                  rules={[
                    { required: true, message: 'Description is required' }
                  ]}>
                  <Input.TextArea
                    size="large"
                    placeholder="Lesson Package Description"
                    autoSize={{ minRows: 2, maxRows: 6 }} />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item name="items" label="Items (Need to be separated by comma)">
                  <Input.TextArea
                    size="large"
                    placeholder="Items separated by comma"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="sk-button-group">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<SendOutlined />}
                  >Add</Button>
                  <Button
                    size="large"
                    onClick={() => this.props.history.push(_ROUTES.PATH_LESSON_PACKAGES)}
                  >Back</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  add: _.get(state, 'lesson_package.create', {}),
});

const mapDispatchToProps = (dispatch) => ({
  addLessonPackageRequest: payload => dispatch({ type: reduxActions.CREATE_LESSON_PACKAGE_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackageAdd);
