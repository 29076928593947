import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllStudentAccounts,
  getStudentAccount,
  editStudentAccount,
  deactivateStudentAccount,
  getStudentCreditReport,
  getStudentCourse
} from '../../services/student.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getStudentAccountsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllStudentAccounts, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getStudentAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getStudentAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_STUDENT_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_STUDENT_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* editStudentAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(editStudentAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.EDIT_STUDENT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.EDIT_STUDENT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* deactivateStudentAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(deactivateStudentAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.DEACTIVATE_STUDENT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.DEACTIVATE_STUDENT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getStudentReportWorker(action){
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getStudentCreditReport, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REPORT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REPORT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getStudentSelectedCourse(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getStudentCourse, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.STUDENT_SELECTED_COURSE_SUCCESS,
        payload: resp?.response,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.STUDENT_SELECTED_COURSE_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}


/* WATCHERS SAGA */

export function* getStudentAccountsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_STUDENT_ACCOUNTS_REQUEST, getStudentAccountsWorker);
}

export function* getStudentAccountWatcher() {
  yield takeLatest(reduxActions.GET_STUDENT_ACCOUNT_REQUEST, getStudentAccountWorker);
}

export function* editStudentAccountWatcher() {
  yield takeLatest(reduxActions.EDIT_STUDENT_REQUEST, editStudentAccountWorker);
}

export function* deactivateStudentAccountWatcher() {
  yield takeLatest(reduxActions.DEACTIVATE_STUDENT_REQUEST, deactivateStudentAccountWorker);
}

export function* getStudentReportWatcher(){
  yield takeLatest(reduxActions.GET_ALL_STUDENT_CREDIT_REPORT, getStudentReportWorker);
}

export function* getStudentSelectedCourseWatcher(){
  yield takeLatest(reduxActions.STUDENT_SELECTED_COURSE_REQUEST, getStudentSelectedCourse);
}

export default function* rootSaga() {
  yield all([
    fork(getStudentAccountsWatcher),
    fork(getStudentAccountWatcher),
    fork(editStudentAccountWatcher),
    fork(deactivateStudentAccountWatcher),
    fork(getStudentReportWatcher),
    fork(getStudentSelectedCourseWatcher)
  ]);
}
