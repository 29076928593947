import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Upload,
  Table,
  InputNumber,
  Switch,
} from "antd";
import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import { _ROUTES, _CONST, _COURSE_TYPES } from "../../constants/GlobalConst";
import { _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";
import Papa from "papaparse";

class CourseAdd extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { Id },
      },
    } = props;

    this.state = {
      course_uuid: Id,
      course: {},
      mode: !_.isEmpty(Id) ? "edit" : "add",
      csv: null,
      isCourseStatus: true
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { mode, course_uuid } = this.state;
    const { getCourseDetailRequest } = this.props;

    mode === "edit" && getCourseDetailRequest({ uuid: course_uuid });
    if (mode === "add") {
      const { lastCourse } = this.props.location.state;
      lastCourse && this.formRef.current.setFieldsValue({ order: lastCourse + 1 });
    } 
  }

  componentDidUpdate(prevProps) {
    const { add, edit, view, history } = this.props;

    if (prevProps.add !== add) {
      const { result } = add;
      if (result.success) {
        _notify({
          type: "success",
          description: "Course is successfully added",
        });
        history.push(_ROUTES.PATH_COURSES);
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.edit !== edit) {
      const { result } = edit;
      if (result.success) {
        _notify({
          type: "success",
          description: "Course is successfully updated",
        });
        history.push(_ROUTES.PATH_COURSES);
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.view !== view) {
      const { result } = view;
    if (result.success) {
        const { lastCourse } = this.props.location.state;
        const course = _.get(result, "response.data", {});
        this.setState(
          {
            course,
            csv: Boolean(_.get(course, "csv_data", ""))
              ? _.get(course, "csv_data", "")
              : null,
              isCourseStatus: _.get(course, "is_active", ""),
          },
          () => {
            this.formRef.current.setFieldsValue({
              name: _.get(course, "name", ""),
              type: _.get(course, "type", ""),
              level: _.get(course, "level", ""),
              unit: _.get(course, "unit", ""),
              lesson: _.get(course, "lesson", ""),
              description: _.get(course, "description", ""),
              order: _.get(course, "order", "") || lastCourse + 1,
              is_active: _.get(course, "is_active", ""),
            });
          }
        );
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
        history.push(_ROUTES.PATH_COURSES);
      }
    }
  }

  _handleFormFinish = (values) => {
    const { mode, course_uuid, csv } = this.state;
    const { addCourseRequest, editCourseRequest } = this.props;

    let allFormValues = { ...values, csv_data: csv, is_active: this.state.isCourseStatus };
    mode === "add"
      ? addCourseRequest(allFormValues)
      : editCourseRequest({ uuid: course_uuid, data: allFormValues });
  };

  _loadOptions = (limit) => {
    try {
      return _.map(_.range(1, limit + 1), (val, key) => {
        return (
          <Select.Option value={val} key={key}>
            {val}
          </Select.Option>
        );
      });
    } catch {
      return null;
    }
  };

  onSwitchChange = (checked) => {
    if(checked === true){
      this.setState({isCourseStatus: true})
    }else{
      this.setState({isCourseStatus: false})
    }
  }

  _parseCSVFile = async (file) => {
    const parseFile = (rawFile) => {
      return new Promise((resolve) => {
        Papa.parse(rawFile, {
          skipEmptyLines: "greedy",
          complete: (results) => {
            resolve(results.data);
          },
        });
      });
    };
    let parsedCSVData = await parseFile(file);
    if (parsedCSVData) {
      this.setState({ csv: JSON.stringify(parsedCSVData) });
    }
    return parsedCSVData;
  };
  render() {
    const { mode } = this.state;
    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={`${mode === "add" ? "Add New" : "Update"} Course`}
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this._handleFormFinish}
            initialValues={{ type: "", level: "", unit: "", lesson: "" }}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input size="large" placeholder="Course Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="order"
                  label="Order"
                  rules={[{ required: true, message: "Order is required" }]}
                >
                  <InputNumber size="large" style={{ width: '100%' }} type="number" className="orderInput" min={1} placeholder="Course Order" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="type"
                  label="Type"
                  rules={[{ required: true, message: "Type is required" }]}
                >
                  <Select size="large">
                    <Select.Option value="">Select Course Type</Select.Option>
                    {_.map(_COURSE_TYPES, (ct, key) => {
                      return (
                        <Select.Option value={ct.value} key={key}>
                          {ct.text}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="level"
                  label="Level"
                  rules={[{ required: true, message: "Level is required" }]}
                >
                  <Select size="large">
                    <Select.Option value="">Select Course Level</Select.Option>
                    {this._loadOptions(10)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="unit"
                  label="Unit"
                  rules={[{ required: true, message: "Unit is required" }]}
                >
                  <Select size="large">
                    <Select.Option value="">Select Course Unit</Select.Option>
                    {this._loadOptions(12)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="lesson"
                  label="Lesson"
                  rules={[{ required: true, message: "Lesson is required" }]}
                >
                  <Select size="large">
                    <Select.Option value="">Select Course Lesson</Select.Option>
                    {this._loadOptions(12)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: "Description is required" },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    placeholder="Course Description"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Upload
                    action={null}
                    beforeUpload={(file) => {
                      this._parseCSVFile(file);
                      return false;
                    }}
                    onRemove={(file) => {
                      this.setState({ csv: null });
                    }}
                    accept=".csv"
                  >
                    <Button icon={<UploadOutlined />}>
                      Click to Import CSV
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              {mode !== "add" && <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="is_active"
                  label="Course Status"
                >
                 <Switch onChange={this.onSwitchChange} checked={this.state.isCourseStatus}  />
                </Form.Item>
              </Col>}
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="sk-button-group">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<SendOutlined />}
                  >{`${mode === "add" ? "Add" : "Update"}`}</Button>
                  <Button
                    size="large"
                    onClick={() =>
                      this.props.history.push(_ROUTES.PATH_COURSES)
                    }
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  add: _.get(state, "course.create", {}),
  edit: _.get(state, "course.edit", {}),
  view: _.get(state, "course.view", {}),
});

const mapDispatchToProps = (dispatch) => ({
  addCourseRequest: (payload) =>
    dispatch({ type: reduxActions.CREATE_COURSE_REQUEST, payload }),
  editCourseRequest: (payload) =>
    dispatch({ type: reduxActions.EDIT_COURSE_REQUEST, payload }),
  getCourseDetailRequest: (payload) =>
    dispatch({ type: reduxActions.GET_COURSE_DETAIL_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseAdd);
