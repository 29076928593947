import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_TEACHER_ACCOUNTS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_TEACHER_ACCOUNTS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const view = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_TEACHER_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_TEACHER_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const create = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.CREATE_TEACHER_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CREATE_TEACHER_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const edit = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.EDIT_TEACHER_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.EDIT_TEACHER_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  list,
  view,
  create,
  edit,
});
