import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Table, Pagination, Button } from 'antd';
import {
  PlusCircleOutlined,
} from '@ant-design/icons';
import { _ROUTES, _PAGESIZE, _DEFAULT_ROWS_PER_PAGE, _CONST } from '../../constants/GlobalConst';
import { _makeQueryString, _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class AdminsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      total_records: 0,
      admins: [],
    };
  }

  componentDidMount() {
    this._loadAdmins();
  }

  componentDidUpdate(prevProps) {
    const { list } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          admins: _.get(result, 'response.data', []),
          total_records: _.get(result, 'response.pagination.totalItems', 0),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
  }

  _loadAdmins = () => {
    const { pagination } = this.state;

    this.props.getAllAdminAccountsRequest({
      qs: _makeQueryString(pagination),
    });
  }

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        ...pagination,
        size,
        page,
      },
    }, () => this._loadAdmins());
  }

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        size,
        page: 1,
      },
    }, () => this._loadAdmins());
  }

  render() {
    const { pagination, admins, total_records } = this.state;
    let COLUMNS = [
      {
        title: 'Email',
        key: 'uuid',
        dataIndex: 'email'
      },
      {
        title: 'Role',
        render: (text, record) => 'admin',
      },
    ];

    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Admin Accounts'}
          extra={<div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(_ROUTES.PATH_ADMINS_ADD)}>Create</Button>
          </div>}>
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive"
              columns={COLUMNS}
              dataSource={admins}
              pagination={false}
            />
            {(!_.isEmpty(admins)) && <div className="sk-pagination">
              <Pagination
                current={pagination.page}
                onChange={this._handlePageChange}
                pageSize={pagination.size}
                pageSizeOptions={_PAGESIZE}
                showSizeChanger={true}
                onShowSizeChange={this._handlePageSizeChange}
                total={total_records}
              />
            </div>}
          </div>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, 'admin.list', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllAdminAccountsRequest: payload => dispatch({ type: reduxActions.GET_ALL_ADMIN_ACCOUNTS_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminsList);
