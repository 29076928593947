import React from 'react';
import {
  DashboardOutlined,
  TeamOutlined,
  SolutionOutlined,
  ReadOutlined,
  CreditCardOutlined,
  OneToOneOutlined,
  SafetyCertificateOutlined,
  ContactsOutlined,
  UserAddOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { _ROUTES } from '../../constants/GlobalConst';

export const Menus = [
  {
    name: 'Dashboard',
    path: _ROUTES.PATH_DASHBOARD,
    icon: <DashboardOutlined />,
    key: 'dashboard',
    access: ['admin', 'sub-admin']
  },
  {
    name: 'Teacher Accounts',
    path: _ROUTES.PATH_TEACHERS,
    icon: <TeamOutlined />,
    key: 'teacher-accounts',
    access: ['admin']
  },
  {
    name: 'Calendar',
    path: _ROUTES.PATH_CALENDAR,
    icon: <CalendarOutlined />,
    key: 'calendar',
    access: ['admin', 'sub-admin']
  },
  {
    name: 'Sub-Admin Accounts',
    path: _ROUTES.PATH_SUB_ADMINS,
    icon: <SolutionOutlined />,
    key: 'sub-admin-accounts',
    access: ['admin']
  },
  {
    name: 'Student Accounts',
    path: _ROUTES.PATH_STUDENTS,
    icon: <ContactsOutlined />,
    key: 'student-accounts',
    access: ['admin']
  },
  {
    name: 'Admin Accounts',
    path: _ROUTES.PATH_ADMINS,
    icon: <UserAddOutlined />,
    key: 'admin-accounts',
    access: ['admin']
  },
  {
    name: 'Courses',
    path: _ROUTES.PATH_COURSES,
    icon: <SafetyCertificateOutlined />,
    key: 'courses',
    access: ['admin', 'sub-admin']
  },
  {
    name: 'Lesson Packages',
    path: _ROUTES.PATH_LESSON_PACKAGES,
    icon: <ReadOutlined />,
    key: 'lesson-packages',
    access: ['admin']
  },
  {
    name: 'Credits',
    path: _ROUTES.PATH_CREDITS,
    icon: <CreditCardOutlined />,
    key: 'credits',
    access: ['admin']
  },
  {
    name: 'Classroom Sessions',
    path: _ROUTES.PATH_CLASSROOM_SESSIONS,
    icon: <OneToOneOutlined />,
    key: 'classroom-sessions',
    access: ['admin', 'sub-admin']
  },
];
