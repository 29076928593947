import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllCredits,
  assignCredit,
  getCreditsLog,
} from '../../services/credit.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getCreditsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllCredits, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_CREDITS_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_CREDITS_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* assignCreditWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(assignCredit, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.ASSIGN_CREDIT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.ASSIGN_CREDIT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getCreditsLogWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getCreditsLog, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_CREDITS_LOG_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_CREDITS_LOG_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

export function* getCreditsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_CREDITS_REQUEST, getCreditsWorker);
}

export function* assignCreditWatcher() {
  yield takeLatest(reduxActions.ASSIGN_CREDIT_REQUEST, assignCreditWorker);
}

export function* getCreditsLogWatcher() {
  yield takeLatest(reduxActions.GET_CREDITS_LOG_REQUEST, getCreditsLogWorker);
}

export default function* rootSaga() {
  yield all([
    fork(getCreditsWatcher),
    fork(assignCreditWatcher),
    fork(getCreditsLogWatcher),
  ]);
}
