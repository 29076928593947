import { API } from './API';

// fetch all lesson packages
export function getAllLessonPackages(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/lesson-packages?${req.qs}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// remove lesson package
export function removeLessonPackage(req) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `admin/lesson-packages/${req.uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// create lesson package
export function createLessonPackage(req) {
  return new Promise((resolve, reject) => {
    return API('POST', `admin/lesson-packages`, req).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}
