import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const INIT_STATE_LOADER = {
  isVisible: false,
};

// page loader
const pageLoader = (state = INIT_STATE_LOADER, action) => {
  switch (action.type) {
    case reduxActions.SHOW_PAGE_LOADER:
      return {
        ...state,
        isVisible: true,
      };
    case reduxActions.HIDE_PAGE_LOADER:
      return {
        ...state,
        isVisible: false,
      };

    default:
      return state;
  }
};

export default combineReducers({
  pageLoader,
});
