import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Typography } from 'antd';
class Dashboard extends React.Component {
  render() {
    const { login } = this.props;
    return (
      <React.Fragment>
        <Card className="sk-card">
          <div>
            <Typography.Title level={4}>Welcome {_.get(login, 'result.response.data.email', '')}</Typography.Title>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: _.get(state, 'auth.login', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoginRequest: payload => dispatch({ type: reduxActions.USER_LOGIN_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

