import { combineReducers } from "redux";
import global from "./global.reducer";
import auth from "./auth.reducer";
import teacher from "./teacher.reducer";
import admin from "./admin.reducer";
import student from "./student.reducer";
import lesson_package from "./package.reducer";
import course from "./course.reducer";
import credit from "./credit.reducer";
import room from "./room.reducer";
import event from "./event.reducer";

const reducers = combineReducers({
  global,
  auth,
  teacher,
  admin,
  student,
  lesson_package,
  course,
  credit,
  room,
  event
});

export default reducers;
