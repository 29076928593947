import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const subadmin_list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_SUB_ADMIN_ACCOUNTS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_SUB_ADMIN_ACCOUNTS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const subadmin_remove = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_SUB_ADMIN_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_SUB_ADMIN_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const subadmin_create = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.CREATE_SUB_ADMIN_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CREATE_SUB_ADMIN_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_ADMIN_ACCOUNTS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_ADMIN_ACCOUNTS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const create = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.CREATE_ADMIN_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CREATE_ADMIN_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  subadmin_list,
  subadmin_remove,
  subadmin_create,
  list,
  create,
});
