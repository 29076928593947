import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { _ROUTES } from "../constants/GlobalConst";
import PrivateRoute from '../components/hoc/PrivateRoute';
import Dashboard from './Dashboard';
import Teachers from './Teachers';
import TeacherForm from './Teachers/Form';
import SubAdmins from './SubAdmins';
import SubAdminRegister from './SubAdmins/Register';
import Admins from './Admins';
import AdminRegister from './Admins/Register';
import Students from './Students';
import StudentEdit from './Students/Form';
import Packages from './Packages';
import PackageAdd from './Packages/Add';
import Courses from './Courses';
import CourseForm from './Courses/Form';
import CourseAddSlides from './Courses/AddSlides';
import Credits from './Credits';
import CreditAssign from './Credits/Assign';
import RoomSessions from './RoomSessions';
import RoomSessionAssign from './RoomSessions/Assign';
import Calendar from './Calendar';

class AppRoutes extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <PrivateRoute path={_ROUTES.PATH_DASHBOARD} component={Dashboard} />
          <PrivateRoute exact path={_ROUTES.PATH_HOME} component={Dashboard} />
          <PrivateRoute exact path={_ROUTES.PATH_CALENDAR} component={Calendar} />
          <PrivateRoute exact path={_ROUTES.PATH_TEACHERS} component={Teachers} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_TEACHERS_ADD} component={TeacherForm} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_TEACHERS_EDIT} component={TeacherForm} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_SUB_ADMINS} component={SubAdmins} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_SUB_ADMINS_ADD} component={SubAdminRegister} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_STUDENTS} component={Students} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_STUDENTS_EDIT} component={StudentEdit} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_ADMINS} component={Admins} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_ADMINS_ADD} component={AdminRegister} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_LESSON_PACKAGES} component={Packages} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_LESSON_PACKAGES_ADD} component={PackageAdd} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_CREDITS} component={Credits} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_CREDITS_ASSIGN} component={CreditAssign} allowSubAdmin={false} />
          <PrivateRoute exact path={_ROUTES.PATH_CLASSROOM_SESSIONS_ASSIGN_SLOT} component={RoomSessionAssign} allowSubAdmin={true} />
          <PrivateRoute exact path={_ROUTES.PATH_COURSES} component={Courses} allowSubAdmin={true} />
          <PrivateRoute exact path={_ROUTES.PATH_COURSES_ADD} component={CourseForm} allowSubAdmin={true} />
          <PrivateRoute exact path={_ROUTES.PATH_COURSES_EDIT} component={CourseForm} allowSubAdmin={true} />
          <PrivateRoute exact path={_ROUTES.PATH_COURSES_ADD_SLIDES} component={CourseAddSlides} allowSubAdmin={true} />
          <PrivateRoute exact path={_ROUTES.PATH_CLASSROOM_SESSIONS} component={RoomSessions} allowSubAdmin={true} />
          <PrivateRoute exact path={_ROUTES.PATH_CLASSROOM_SESSIONS_ADD} component={RoomSessionAssign} allowSubAdmin={true} />


          {/* Redirect to default page if no url matched */}
          <Redirect to={`/`} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default AppRoutes;
