import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import rootReducer from '../reducers';

let middleware;
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

// loading required middlewares depending upon the environment
if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(
    sagaMiddleware
  );
} else {
  middleware = composeEnhancers(
    applyMiddleware(
      sagaMiddleware
    )
  );
}

// creating the main store.
export const store = createStore(rootReducer, middleware);

// running saga.
sagaMiddleware.run(rootSaga);

