import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./appRedux/store";
import { _ROUTES } from "./constants/GlobalConst";
import App from './App';
import Login from './Routes/Login';
import Logout from './Routes/Logout';

import './assets/css/site.css';

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path={_ROUTES.PATH_LOGIN} component={Login} />
          <Route exact path={_ROUTES.PATH_LOGOUT} component={Logout} />
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Provider>
  ,
  document.getElementById('root')
);