import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  DatePicker,
  Select,
  Modal,
  Input,
  Radio,
  Switch
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { _ROUTES, _CONST, _COURSE_TYPES } from "../../constants/GlobalConst";
import { _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

const { TextArea } = Input;

class RoomSessionAssign extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: {
        params: { Id },
      },
    } = props;
    this.state = {
      all_students: [],
      all_teachers: [],
      all_courses: [],
      slot_id: Id,
      is_classroom_exists: false,
      existing_classrooms: [],
      selected_student: "",
      event: {},
      sessions: [],
      session_slot: {},
      class_session: {},
      show_warning: false,
      classroomType: "Regular",
      remarks: "",
      isRecordingAllowed: "Not Allowed"
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getAllTeacherAccountsRequest({
      qs: 'cols={"account":["uuid","email"],"profile":["fullname"]}',
    });

    this.props.getAllStudentAccountsRequest({
      qs: 'cols={"account":["uuid","email"],"profile":["fullname"]}',
    });

    this.props.getAllCoursesRequest({ qs: "" });
    if (this.state.slot_id) {
      this.props.getEventRequest({ id: this.state.slot_id });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      assign,
      teachers,
      students,
      courses,
      history,
      get_event,
      time_session,
      student_course
    } = this.props;

    if (prevProps.assign !== assign) {
      const { result } = assign;
      if (result.success) {
        _notify({
          type: "success",
          description: "Classroom Session is successfully assigned",
        });
        history.push(_ROUTES.PATH_CLASSROOM_SESSIONS);
      } else if (!result.success && result.response.data) {
        this.setState({
          ...this.state,
          is_classroom_exists: true,
          existing_classrooms: result.response.data,
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }
   
    if (prevProps.student_course !== student_course) {
      const { result } = student_course;
      if (result.success) {
        this.formRef.current.setFieldsValue({
          course_uuid: _.get(
            result,
            "course.uuid",
            ""
          ),
          // start_at: moment(_.get(result.response.data, "start", "")),
          // end_at: moment(_.get(result.response.data, "end", "")),
        });
      } else {
        this.formRef.current.setFieldsValue({
          course_uuid: null,
          // start_at: moment(_.get(result.response.data, "start", "")),
          // end_at: moment(_.get(result.response.data, "end", "")),
        });
      }
    }
    
    if (prevProps.time_session !== time_session) {
      const { result } = time_session;
      if (result.success) {
        this.setState(
          {
            sessions: _.get(result, "response.data", []),
          },
          () => {
            if (this.state.sessions.count > 0) {
              let flag = true;
              this.state.sessions.rows.map((slot) => {
                if (
                  slot.account &&
                  slot.account.uuid != this.state.selected_student
                ) {
                  this.setState({ session_slot: slot, show_warning: true });
                  flag = false;
                }
              });
              if (flag)
                this.props.assignClassroomSessionRequest({
                  data: this.state.class_session,
                });
            } else {
              this.props.assignClassroomSessionRequest({
                data: this.state.class_session,
              });
            }
          }
        );
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.get_event !== get_event) {
      const { result } = get_event;
      if (result.success) {
        this.setState(
          {
            event: _.get(result, "response.data", []),
          },
          () => {
            this.formRef.current.setFieldsValue({
              teacher_account_uuid: _.get(
                result,
                "response.data.teacher_account.uuid",
                ""
              ),
              student_account_uuid: _.get(
                result,
                "response.data.account.uuid",
                ""
              ),
              start_at: moment(_.get(result.response.data, "start", "")),
              end_at: moment(_.get(result.response.data, "end", "")),
            });
          }
        );
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.time_session !== time_session) {
      const { result } = time_session;
      if (result.success) {
        this.setState(
          {
            sessions: _.get(result, "response.data", []),
          },
          () => {
            if (this.state.sessions.count > 0) {
              let flag = true;
              this.state.sessions.rows.map((slot) => {
                if (
                  slot.account &&
                  slot.account.uuid != this.state.selected_student
                ) {
                  this.setState({ session_slot: slot, show_warning: true });
                  flag = false;
                }
              });
              if (flag)
                this.props.assignClassroomSessionRequest({
                  data: this.state.class_session,
                });
            } else {
              this.props.assignClassroomSessionRequest({
                data: this.state.class_session,
              });
            }
          }
        );
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.get_event !== get_event) {
      const { result } = get_event;
      if (result.success) {
        this.setState(
          {
            event: _.get(result, "response.data", []),
          },
          () => {
            this.formRef.current.setFieldsValue({
              teacher_account_uuid: _.get(
                result,
                "response.data.teacher_account.uuid",
                ""
              ),
              student_account_uuid: _.get(
                result,
                "response.data.account.uuid",
                ""
              ),
              start_at: moment(_.get(result.response.data, "start", "")),
              end_at: moment(_.get(result.response.data, "end", "")),
            });
          }
        );
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.teachers !== teachers) {
      const { result } = teachers;
      if (result.success) {
        this.setState({
          all_teachers: _.get(result, "response.data", []),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.students !== students) {
      const { result } = students;
      if (result.success) {
        this.setState({
          all_students: _.get(result, "response.data", []),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.courses !== courses) {
      const { result } = courses;
      if (result.success) {
        const all_courses = _.chain(_.get(result, "response.data", []))
          // Group the elements of Array based on `level` property
          .groupBy("level")
          // `key` is group's name (level), `value` is the array of objects
          .map((value, key) => ({ level: key, courses: value }))
          .value();

        this.setState({ all_courses });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }
  }

  _handleFormFinish = (values) => {
    let req = {
      ...values,
      start_at: moment(values.start_at).seconds(0).format(),
      end_at: moment(values.end_at).seconds(0).format(),
      forceCreate: this.state.is_classroom_exists ? true : false,
      classroom_type: this.state.classroomType,
      remarks: this.state.remarks,
      allow_video_recording: this.state.isRecordingAllowed
    };

    req.start_at = _.replace(
      req.start_at,
      req.start_at.substring(req.start_at.length - 6),
      "+08:00"
    );
    req.end_at = _.replace(
      req.end_at,
      req.end_at.substring(req.end_at.length - 6),
      "+08:00"
    );
    this.setState({
      selected_student: values.student_account_uuid,
      class_session: req,
    });
    this.props.getTimeEventRequest({
      id: values.teacher_account_uuid,
      startDate: moment(values.start_at).seconds(0).format(),
      endDate: moment(values.end_at).seconds(0).format(),
    });
  };

  assignClass = () => {
    this.setState({ show_warning: false });
    this.props.assignClassroomSessionRequest({
      data: this.state.class_session,
    });
  };

  /*
   * Set end date when start date is already selected
   */
  selectEndDate = () => {
    const {classroom_duration} = this.formRef.current.getFieldsValue();
    const start_time = moment(
      document.getElementById("start_at").value,
      "YYYY-MM-DD hh:mm A",
      true
    );

    if(start_time.isValid()){
      start_time.add(classroom_duration, "minutes");
      this.formRef.current.setFieldsValue({end_at: start_time});
    }
  }

  setEndDate = () => {
    const {classroom_duration} = this.formRef.current.getFieldsValue();
    const start_time = moment(
      document.getElementById("start_at").value,
      "YYYY-MM-DD hh:mm A",
      true
    );

    start_time.add(classroom_duration, "minutes");
    this.formRef.current.setFieldsValue({end_at: start_time});
  }

  _checkSessionStartTime = () => {
    const start_time = moment(
      document.getElementById("start_at").value,
      "YYYY-MM-DD hh:mm a",
      true
    );
    const end_time = moment(
      document.getElementById("end_at").value,
      "YYYY-MM-DD hh:mm a",
      true
    );

    if (!start_time.isValid()) {
      return Promise.reject("Start Time is required");
    } else if (start_time.isSame(end_time)) {
      return Promise.reject("Start Time can't be same as End Time");
    } else if (start_time > end_time) {
      return Promise.reject("Start Time can't be after the End Time");
    } else return Promise.resolve();

    
  };

  _checkSessionEndTime = () => {
    const start_time = moment(
      document.getElementById("start_at").value,
      "YYYY-MM-DD hh:mm a",
      true
    );
    const end_time = moment(
      document.getElementById("end_at").value,
      "YYYY-MM-DD hh:mm a",
      true
    );

    if (!end_time.isValid()) {
      return Promise.reject("End Time is required");
    } else if (start_time.isSame(end_time)) {
      return Promise.reject("End Time can't be same as Start Time");
    } else if (start_time > end_time) {
      return Promise.reject("End Time can't be before the Start Time");
    } else return Promise.resolve();
  };

  _forceCreateClassroom = () => {
    const payload = this.formRef.current.getFieldsValue();
    this._handleFormFinish(payload);
  };

  onRemarkChange = (event) => {
    this.setState({ remarks: event.target.value });
  };

  onSwitchChange = (checked) => {
    if(checked === true){
      this.setState({isRecordingAllowed: "Allowed"})
    }else{
      this.setState({isRecordingAllowed: "Not Allowed"})
    }
  }

  handleClassroomTypeChange = (value) => {
    this.setState({ classroomType: value });
  };
  
  getStudentCourse = (value) => {
    if (value) {
      this.props.getStudentSelectedCourse({ id: value });
    }
  }

  render() {
    const {
      all_students,
      all_teachers,
      all_courses,
      is_classroom_exists,
      existing_classrooms,
    } = this.state;
    const lastAssigned = this.props.student_course?.result?.lastAssigned;
    const teacherAccount = this.props.student_course?.result?.teacherAccount;
    return (
      <React.Fragment>
        <Card className="sk-card" title={"Assign New Classroom Session"}>
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this._handleFormFinish}
            initialValues={{
              extended_min: 15, classroom_duration: 30,
            }}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="teacher_account_uuid"
                  label="Teacher Account"
                  rules={[
                    { required: true, message: "Teacher Account is required" },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    allowClear
                    placeholder="Select Teacher Account"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const result = _.filter(
                        option.children,
                        function (value) {
                          return _.includes(
                            value.props.children &&
                              value.props.children.toLowerCase(),
                            input.toLowerCase()
                          );
                        }
                      );
                      return result.length;
                    }}
                  >
                    {_.map(all_teachers, (teacher, key) => {
                      return (
                        <Select.Option
                          value={teacher.uuid}
                          key={key}
                          className="sk-opt-rows"
                        >
                          <div className="row1">
                            {_.get(teacher, "profile.fullname", "")}
                          </div>
                          <div className="row2">
                            {_.get(teacher, "email", "")}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="student_account_uuid"
                  label="Student Account"
                  rules={[
                    { required: true, message: "Student Account is required" },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    allowClear
                    onChange={this.getStudentCourse}
                    placeholder="Select Student Account"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const result = _.filter(
                        option.children,
                        function (value) {
                          return _.includes(
                            value.props.children &&
                              value.props.children.toLowerCase(),
                            input.toLowerCase()
                          );
                        }
                      );
                      return result.length;
                    }}
                  >
                    {_.map(all_students, (student, key) => {
                      return (
                        <Select.Option
                          value={student.uuid}
                          key={key}
                          className="sk-opt-rows"
                        >
                          <div className="row1">
                            {_.get(student, "profile.fullname", "")}
                          </div>
                          <div className="row2">
                            {_.get(student, "email", "")}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ marginBottom: '24px' }}>
                <Form.Item
                  name="course_uuid"
                  label="Course"
                  style={{ margin: '0px'}}
                  rules={[{ required: true, message: "Course is required" }]}
                >
                  <Select size="large" allowClear placeholder="Select Course">
                    {_.map(all_courses, (course, key) => {
                      return (
                        <Select.OptGroup
                          key={key}
                          label={<b>Level {course.level}</b>}
                        >
                          {_.map(
                            _.sortBy(course.courses, ["name"]),
                            (option) => (
                              option.is_active ? (<Select.Option
                                key={option.uuid}
                                value={option.uuid}
                              >
                                <div>
                                  <span>{option.name}</span>
                                  <span style={{ paddingLeft: "5px" }}>
                                    <b>{`Level ${option.level} - Unit ${option.unit} - Lesson ${option.lesson}`}</b>
                                  </span>
                                </div>
                              </Select.Option>) : null
                            )
                          )}
                        </Select.OptGroup>
                      );
                    })}
                  </Select>
                </Form.Item>
                {lastAssigned && <div className="courseMessage">
                  (Last Assigned: {lastAssigned?.name}, {" "}
                  {teacherAccount?.fullname}, {" "}
                  {moment(lastAssigned?.added_at).format('MM/D/YYYY h:mm A')}
                  - 
                  {moment(lastAssigned?.updated_at).format('MM/D/YYYY h:mm A')}
                  ,{" "} Completed)
                </div>}
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="classroom_duration" label="Classroom Duration">
                  <Select size="large" id="classroom_duration" onChange={this.selectEndDate}>
                    <Select.Option value={30}>30 Minutes</Select.Option>
                    <Select.Option value={45}>45 Minutes</Select.Option>
                    <Select.Option value={60}>1 Hour</Select.Option>
                    <Select.Option value={75}>1 Hour 15 Minutes</Select.Option>
                    <Select.Option value={90}>1 Hour 30 Minutes</Select.Option>
                    <Select.Option value={105}>1 Hour 45 Minutes</Select.Option>
                    <Select.Option value={120}>2 Hours</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="start_at"
                  label="Start At"
                  rules={[
                    {
                      validator: this._checkSessionStartTime,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    className="full-width"
                    format="YYYY-MM-DD hh:mm A"
                    disabledDate={(current) =>
                      current && current < moment().subtract(1, "day")
                    }
                    showTime
                    onOk={this.setEndDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="end_at"
                  label="End At"
                  rules={[
                    {
                      validator: this._checkSessionEndTime,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <DatePicker
                    size="large"
                    className="full-width"
                    format="YYYY-MM-DD hh:mm A"
                    disabledDate={(current) =>
                      current && current < moment().subtract(1, "day")
                    }
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="extended_min" label="Extend Session Time">
                  <Select size="large">
                    <Select.Option value={0}>0 Minutes</Select.Option>
                    <Select.Option value={15}>15 Minutes</Select.Option>
                    <Select.Option value={30}>30 Minutes</Select.Option>
                    <Select.Option value={45}>45 Minutes</Select.Option>
                    <Select.Option value={60}>60 Minutes</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="classroom_type" label="Classroom Types">
                  <Radio.Group
                    defaultValue="Regular"
                    options={[
                      { label: "Regular", value: "Regular" },
                      { label: "Trial", value: "Trial" },
                      { label: "Others", value: "Others" },
                    ]}
                    onChange={({ target: { value } }) =>
                      this.handleClassroomTypeChange(value)
                    }
                    value={this.state.classroomType}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="remarks" label="Type Remarks">
                  <TextArea
                    placeholder="Add remarks for classroom types"
                    allowClear
                    onChange={this.onRemarkChange}
                    value={this.state.remarks}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="allow_video_recording"
                  label="Allow Video Recording"
                >
                 <Switch onChange={this.onSwitchChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="sk-button-group">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<SendOutlined />}
                  >
                    Assign
                  </Button>
                  <Button
                    size="large"
                    onClick={() =>
                      this.props.history.push(_ROUTES.PATH_CLASSROOM_SESSIONS)
                    }
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
            {is_classroom_exists && (
              <Modal
                visible={is_classroom_exists}
                title="Existing Classroom Sessions"
                onOk={this._forceCreateClassroom}
                onCancel={() => {
                  this.setState({ is_classroom_exists: false });
                }}
              >
                <p>He's already having sessions at following times</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <p style={{ fontWeight: "bold" }}>Start Time</p>
                    {existing_classrooms.map((x) => (
                      <p key={x.uuid}>
                        {" "}
                        {moment(x.start_at).format(
                          _CONST.LONG_DATETIME_FORMAT
                        )}{" "}
                      </p>
                    ))}
                  </div>
                  <div>
                    <p p style={{ fontWeight: "bold" }}>
                      End Time
                    </p>
                    {existing_classrooms.map((x) => (
                      <p key={x.uuid}>
                        {" "}
                        {moment(x.end_at).format(
                          _CONST.LONG_DATETIME_FORMAT
                        )}{" "}
                      </p>
                    ))}
                  </div>
                </div>

                <p style={{ marginTop: "2%" }}>
                  Are you sure to assign session ? Please press ok to continue
                </p>
              </Modal>
            )}
          </Form>
        </Card>
        <Modal visible={this.state.show_warning} footer={null} closable={false}>
          <div>
            <Row gutter={[16, 0]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <p>{`For ${moment(this.state.session_slot.start).format(
                  "DD-MMM-YYYY"
                )} (${moment(this.state.session_slot.start).format(
                  "hh:mm"
                )}-${moment(this.state.session_slot.end).format(
                  "hh:mm"
                )}) this slot has been reserved for student ${
                  this.state.session_slot.account &&
                  this.state.session_slot.account.profile.fullname
                },\n Are you still want to assign new classroom session ?`}</p>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                style={{ textAlign: "right" }}
              >
                <Button
                  key="back"
                  size="small"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    this.setState({ show_warning: false });
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Button
                  key="submit"
                  size="small"
                  type="primary"
                  style={{ textAlign: "center" }}
                  onClick={() => this.assignClass()}
                >
                  OK
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  teachers: _.get(state, "teacher.list", {}),
  students: _.get(state, "student.list", {}),
  student_course: _.get(state, "student.student_course", {}),
  assign: _.get(state, "room.assign", {}),
  courses: _.get(state, "course.list", {}),
  get_event: _.get(state, "event.get_event", {}),
  time_session: _.get(state, "event.time_session", {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllTeacherAccountsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_TEACHER_ACCOUNTS_REQUEST, payload }),
  getAllStudentAccountsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_REQUEST, payload }),
  getAllCoursesRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_COURSES_REQUEST, payload }),
  assignClassroomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.ASSIGN_CLASSROOM_SESSION_REQUEST, payload }),
  getEventRequest: (payload) =>
    dispatch({ type: reduxActions.GET_EVENT_REQUEST, payload }),
  getTimeEventRequest: (payload) =>
    dispatch({ type: reduxActions.GET_TIME_EVENT_REQUEST, payload }),
  getStudentSelectedCourse: payload => 
    dispatch({ type: reduxActions.STUDENT_SELECTED_COURSE_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomSessionAssign);
