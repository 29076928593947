import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Card,
  Drawer,
  Button,
  Tooltip,
  Table,
  Row,
  Col,
  Form,
  Pagination,
  Tag,
} from "antd";
import { EyeOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  _ROUTES,
  _PAGESIZE,
  _DEFAULT_ROWS_PER_PAGE,
  _CONST,
} from "../../constants/GlobalConst";
import {
  _makeQueryString,
  _formatDateTime,
  _notify,
} from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class CreditsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      total_records: 0,
      credits: [],
      credit: {},
      showViewCreditDrawer: false,
    };
  }

  componentDidMount() {
    this._loadCredits();
  }

  componentDidUpdate(prevProps) {
    const { list } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          credits: _.get(result, "response.data", []),
          total_records: _.get(result, "response.pagination.totalItems", 0),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }
  }

  _loadCredits = () => {
    const { pagination } = this.state;

    this.props.getAllCreditsRequest({
      qs: `${_makeQueryString(pagination)}`,
    });
  };

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState(
      {
        pagination: {
          ...pagination,
          size,
          page,
        },
      },
      () => this._loadCredits()
    );
  };

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          size,
          page: 1,
        },
      },
      () => this._loadCredits()
    );
  };

  _handleViewCredit = (record) => {
    this.setState({
      credit: record,
      showViewCreditDrawer: true,
    });
  };

  render() {
    const { pagination, credits, credit, total_records, showViewCreditDrawer } =
      this.state;
    let COLUMNS = [
      {
        title: "Owner Account",
        render: (text, record) => (
          <div>{_.get(record, "student_account.profile.fullname", "")}</div>
        ),
      },
      {
        title: "Credits (Regular)",
        dataIndex: "number_of_credits_regular",
        align: "right",
      },
      {
        title: "Credits (Marketing)",
        dataIndex: "number_of_credits_marketing",
        align: "right",
      },
      {
        title: "Order UUID",
        dataIndex: "order_uuid",
      },
      {
        title: "Order Type",
        dataIndex: "order_type",
      },
      {
        title: "Added By",
        dataIndex: ["admin_account", "email"],
      },
      {
        title: "Added At",
        render: (text, record) => (
          <div>
            {_formatDateTime(record.added_at, _CONST.LONG_DATETIME_FORMAT)}
          </div>
        ),
      },
      {
        title: "Status",
        align: "center",
        render: (text, record) => (
          <Tag color={record.status === "active" ? `#87d068` : `#f50`}>
            <span className="text-capitalize">{record.status}</span>
          </Tag>
        ),
      },
      {
        title: "Action",
        align: "center",
        render: (text, record) => (
          <Tooltip placement="top" title="View">
            <Button
              type="default"
              size="small"
              onClick={() => this._handleViewCredit(record)}
            >
              <EyeOutlined />
            </Button>
          </Tooltip>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={"Credits"}
          extra={
            <div>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() =>
                  this.props.history.push(_ROUTES.PATH_CREDITS_ASSIGN)
                }
              >
                Assign
              </Button>
            </div>
          }
        >
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive"
              columns={COLUMNS}
              dataSource={credits}
              pagination={false}
            />
            {!_.isEmpty(credits) && (
              <div className="sk-pagination">
                <Pagination
                  current={pagination.page}
                  onChange={this._handlePageChange}
                  pageSize={pagination.size}
                  pageSizeOptions={_PAGESIZE}
                  showSizeChanger={true}
                  onShowSizeChange={this._handlePageSizeChange}
                  total={total_records}
                />
              </div>
            )}
          </div>
        </Card>

        {showViewCreditDrawer && (
          <Drawer
            title={"View Credit"}
            className="sk-drawer"
            closable={true}
            onClose={() => this.setState({ showViewCreditDrawer: false })}
            visible={showViewCreditDrawer}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item label="UUID">
                    {_.get(credit, "uuid", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Owner Account UUID">
                    {_.get(credit, "owner_account_uuid", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Owner Account Name">
                    {_.get(credit, "student_account.profile.fullname", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Number Of Credits (Regular)">
                    {_.isNumber(credit.number_of_credits_regular)
                      ? credit.number_of_credits_regular
                      : "N/A"}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Number Of Credits (Marketing)">
                    {_.isNumber(credit.number_of_credits_marketing)
                      ? credit.number_of_credits_marketing
                      : "N/A"}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Order UUID">
                    {!_.isEmpty(credit.order_uuid) ? credit.order_uuid : "N/A"}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Order Type">
                    {!_.isEmpty(credit.order_type) ? credit.order_type : "N/A"}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Status">
                    <Tag
                      color={credit.status === "active" ? `#87d068` : `#f50`}
                    >
                      <span className="text-capitalize">{credit.status}</span>
                    </Tag>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Added By">
                    {_.get(credit, "admin_account.email", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Remarks">
                    {_.get(credit, "remarks", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Added At">
                    {_formatDateTime(
                      credit.added_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Inserted At">
                    {_formatDateTime(
                      credit.inserted_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Updated At">
                    {_formatDateTime(
                      credit.updated_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, "credit.list", {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllCreditsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_CREDITS_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditsList);
