import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllRoomSessions,
  getRoomSession,
  removeRoomSession,
  assignRoomSession,
  exportRoomSessions,
  updateClassroomSession,
  getAllRoomSessionsByTeacher,
  getRoomSessionRecordedVideos,
} from "../../services/room.service";
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getRoomSessionsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllRoomSessions, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_ROOM_SESSIONS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_ROOM_SESSIONS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getRoomSessionsByTeacherWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllRoomSessionsByTeacher, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getRoomSessionRecordedVideoWorker(action) {
  try {
    const resp = yield call(getRoomSessionRecordedVideos, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ROOM_SESSION_RECORDED_VIDEOS_SUCCESS,
        payload: resp,
      });
    }
  } catch (error) {
    yield put({
      type: reduxActions.GET_ROOM_SESSION_RECORDED_VIDEOS_FAILURE,
      payload: error,
    });
  }
}

function* getRoomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getRoomSession, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ROOM_SESSION_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ROOM_SESSION_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeRoomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeRoomSession, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_ROOM_SESSION_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_ROOM_SESSION_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* assignRoomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(assignRoomSession, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.ASSIGN_CLASSROOM_SESSION_SUCCESS,
        payload: resp,
      });
      yield put({
        type: reduxActions.STUDENT_SELECTED_COURSE_SUCCESS,
        payload: '',
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.ASSIGN_CLASSROOM_SESSION_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* exportRoomSessionsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(exportRoomSessions, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.EXPORT_CLASSROOM_SESSIONS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.EXPORT_CLASSROOM_SESSIONS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* updateClassroomSessionWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(updateClassroomSession, action.payload);
    if (resp.success) {
      yield put({ type: reduxActions.HIDE_PAGE_LOADER });
    }
  } catch (error) {
    yield put({
      type: reduxActions.HIDE_PAGE_LOADER,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

export function* getRoomSessionsRecordedVideoWatcher() {
  yield takeLatest(
    reduxActions.GET_ROOM_SESSION_RECORDED_VIDEOS_REQUEST,
    getRoomSessionRecordedVideoWorker
  );
}

export function* getRoomSessionsWatcher() {
  yield takeLatest(
    reduxActions.GET_ALL_ROOM_SESSIONS_REQUEST,
    getRoomSessionsWorker
  );
}

export function* getRoomSessionsByTeacherWatcher() {
  yield takeLatest(
    reduxActions.GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER,
    getRoomSessionsByTeacherWorker
  );
}

export function* getRoomSessionWatcher() {
  yield takeLatest(reduxActions.GET_ROOM_SESSION_REQUEST, getRoomSessionWorker);
}

export function* removeRoomSessionWatcher() {
  yield takeLatest(
    reduxActions.REMOVE_ROOM_SESSION_REQUEST,
    removeRoomSessionWorker
  );
}

export function* assignRoomSessionWatcher() {
  yield takeLatest(
    reduxActions.ASSIGN_CLASSROOM_SESSION_REQUEST,
    assignRoomSessionWorker
  );
}

export function* exportRoomSessionsWatcher() {
  yield takeLatest(
    reduxActions.EXPORT_CLASSROOM_SESSIONS_REQUEST,
    exportRoomSessionsWorker
  );
}

export function* updateClassroomSessionWatcher() {
  yield takeLatest(
    reduxActions.UPDATE_CLASS_ROOM_SESSION_REQUEST,
    updateClassroomSessionWorker
  );
}

export default function* rootSaga() {
  yield all([
    fork(getRoomSessionsWatcher),
    fork(getRoomSessionsByTeacherWatcher),
    fork(getRoomSessionWatcher),
    fork(removeRoomSessionWatcher),
    fork(assignRoomSessionWatcher),
    fork(exportRoomSessionsWatcher),
    fork(updateClassroomSessionWatcher),
    fork(getRoomSessionsRecordedVideoWatcher),
  ]);
}
