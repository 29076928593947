import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import { Form, Input, Row, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import StarkidLogo from '../../assets/images/starkid_logo.png';
import { _notify } from "../../helpers/Utils";
import { _ROUTES } from "../../constants/GlobalConst";
import * as reduxActions from "../../constants/ActionTypes";

import './LoginStyle.css';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { login, history } = this.props;

    // Redirect user back to dashboard if token exists
    const token = _.get(login, 'result.response.token', '');
    if (!_.isEmpty(token)) {
      history.push(_ROUTES.PATH_HOME);
    }
  }

  componentDidUpdate(prevProps) {
    const { login, history } = this.props;

    if (prevProps.login !== login) {
      const { result } = login;
      if (result.success) {
        localStorage.setItem('auth', JSON.stringify(result));
        history.push(_ROUTES.PATH_HOME);
      } else {
        this.setState({ loading: false }, () => {
          _notify({ type: 'error', description: _.get(result, 'response.message', '') });
        });
      }
    }
  }

  _handleFormFinish = values => {
    this.setState({ loading: true }, () => {
      this.props.userLoginRequest({
        email: values.email,
        password: values.password,
        role: 'admin',
      });
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        <div className="login-form">
          <div className="logo">
            <img alt="logo" src={StarkidLogo} />
          </div>
          <Form ref={this.formRef} onFinish={this._handleFormFinish}>
            <Form.Item
              name="email"
              hasFeedback
              rules={[
                { type: 'email', message: 'Email is not valid' },
                { required: true, message: 'Email is required' }
              ]}
              >
              <Input
                size="large"
                placeholder="Email"
                prefix={<UserOutlined />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              hasFeedback
              rules={[{ required: true, message: 'Password is required' }]}
              >
              <Input
                type="password"
                size="large"
                placeholder="Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>
            <Row>
              <Button type="primary" size="large" htmlType="submit" loading={loading}>Sign in</Button>
            </Row>
          </Form>
        </div>
        <div className="login-footer">
          <footer className="globalFooter">
            <div className="copyright">&#169; 2020 Starkid</div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    login: _.get(state, 'auth.login', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  userLoginRequest: payload => dispatch({ type: reduxActions.USER_LOGIN_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
