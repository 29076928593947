import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_ROOM_SESSIONS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_ROOM_SESSIONS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const get_all_sessions_by_teacher = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const get_all_sessions_recorded_videos = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ROOM_SESSION_RECORDED_VIDEOS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ROOM_SESSION_RECORDED_VIDEOS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const view = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ROOM_SESSION_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ROOM_SESSION_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const remove = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_ROOM_SESSION_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_ROOM_SESSION_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const assign = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.ASSIGN_CLASSROOM_SESSION_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.ASSIGN_CLASSROOM_SESSION_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const csv_export = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.EXPORT_CLASSROOM_SESSIONS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.EXPORT_CLASSROOM_SESSIONS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  get_all_sessions_by_teacher,
  view,
  remove,
  assign,
  csv_export,
  get_all_sessions_recorded_videos,
});
