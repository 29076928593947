import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllTeacherAccounts,
  getTeacherAccount,
  createTeacherAccount,
  editTeacherAccount,
} from '../../services/teacher.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getTeacherAccountsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllTeacherAccounts, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_TEACHER_ACCOUNTS_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_TEACHER_ACCOUNTS_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getTeacherAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getTeacherAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_TEACHER_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_TEACHER_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* createTeacherAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(createTeacherAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CREATE_TEACHER_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.CREATE_TEACHER_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* editTeacherAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(editTeacherAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.EDIT_TEACHER_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.EDIT_TEACHER_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

export function* getTeacherAccountsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_TEACHER_ACCOUNTS_REQUEST, getTeacherAccountsWorker);
}

export function* getTeacherAccountWatcher() {
  yield takeLatest(reduxActions.GET_TEACHER_ACCOUNT_REQUEST, getTeacherAccountWorker);
}

export function* createTeacherAccountWatcher() {
  yield takeLatest(reduxActions.CREATE_TEACHER_ACCOUNT_REQUEST, createTeacherAccountWorker);
}

export function* editTeacherAccountWatcher() {
  yield takeLatest(reduxActions.EDIT_TEACHER_ACCOUNT_REQUEST, editTeacherAccountWorker);
}

export default function* rootSaga() {
  yield all([
    fork(getTeacherAccountsWatcher),
    fork(getTeacherAccountWatcher),
    fork(createTeacherAccountWatcher),
    fork(editTeacherAccountWatcher),
  ]);
}
