export const SHOW_PAGE_LOADER = "SHOW_PAGE_LOADER";
export const HIDE_PAGE_LOADER = "HIDE_PAGE_LOADER";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const GET_ALL_TEACHER_ACCOUNTS_REQUEST =
  "GET_ALL_TEACHER_ACCOUNTS_REQUEST";
export const GET_ALL_TEACHER_ACCOUNTS_SUCCESS =
  "GET_ALL_TEACHER_ACCOUNTS_SUCCESS";
export const GET_ALL_TEACHER_ACCOUNTS_FAILURE =
  "GET_ALL_TEACHER_ACCOUNTS_FAILURE";

export const GET_TEACHER_ACCOUNT_REQUEST = "GET_TEACHER_ACCOUNT_REQUEST";
export const GET_TEACHER_ACCOUNT_SUCCESS = "GET_TEACHER_ACCOUNT_SUCCESS";
export const GET_TEACHER_ACCOUNT_FAILURE = "GET_TEACHER_ACCOUNT_FAILURE";

export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const GET_LAST_WEEK_EVENTS_REQUEST = "GET_LAST_WEEK_EVENTS_REQUEST";
export const GET_LAST_WEEK_EVENTS_SUCCESS = "GET_LAST_WEEK_EVENTS_SUCCESS";
export const GET_LAST_WEEK_EVENTS_FAILURE = "GET_LAST_WEEK_EVENTS_FAILURE";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const GET_TIME_EVENT_REQUEST = "GET_TIME_EVENT_REQUEST";
export const GET_TIME_EVENT_SUCCESS = "GET_TIME_EVENT_SUCCESS";
export const GET_TIME_EVENT_FAILURE = "GET_TIME_EVENT_FAILURE";

export const GET_OPEN_SLOT_REQUEST = "GET_OPEN_SLOT_REQUEST";
export const GET_OPEN_SLOT_SUCCESS = "GET_OPEN_SLOT_SUCCESS";
export const GET_OPEN_SLOT_FAILURE = "GET_OPEN_SLOT_FAILURE";

export const GET_ALL_EVENT_REQUEST = "GET_ALL_EVENT_REQUEST";
export const GET_ALL_EVENT_SUCCESS = "GET_ALL_EVENT_SUCCESS";
export const GET_ALL_EVENT_FAILURE = "GET_ALL_EVENT_FAILURE";

export const GET_ALL_EVENT_BY_TEACHER_REQUEST =
  "GET_ALL_EVENT_BY_TEACHER_REQUEST";
export const GET_ALL_EVENT_BY_TEACHER_SUCCESS =
  "GET_ALL_EVENT_BY_TEACHER_SUCCESS";
export const GET_ALL_EVENT_BY_TEACHER_FAILURE =
  "GET_ALL_EVENT_BY_TEACHER_FAILURE";

export const ADD_EVENT_REQUEST = "ADD_EVENT_REQUEST";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAILURE = "ADD_EVENT_FAILURE";

export const ADD_EVENT_BULK_REQUEST = "ADD_EVENT_BULK_REQUEST";
export const ADD_EVENT_BULK_SUCCESS = "ADD_EVENT_BULK_SUCCESS";
export const ADD_EVENT_BULK_FAILURE = "ADD_EVENT_BULK_FAILURE";

export const REMOVE_EVENT_REQUEST = "REMOVE_EVENT_REQUEST";
export const REMOVE_EVENT_SUCCESS = "REMOVE_EVENT_SUCCESS";
export const REMOVE_EVENT_FAILURE = "REMOVE_EVENT_FAILURE";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const GET_ALL_STUDENT_REQUEST = "GET_ALL_STUDENT_REQUEST";
export const GET_ALL_STUDENT_SUCCESS = "GET_ALL_STUDENT_SUCCESS";
export const GET_ALL_STUDENT_FAILURE = "GET_ALL_STUDENT_FAILURE";

export const CREATE_TEACHER_ACCOUNT_REQUEST = "CREATE_TEACHER_ACCOUNT_REQUEST";
export const CREATE_TEACHER_ACCOUNT_SUCCESS = "CREATE_TEACHER_ACCOUNT_SUCCESS";
export const CREATE_TEACHER_ACCOUNT_FAILURE = "CREATE_TEACHER_ACCOUNT_FAILURE";

export const EDIT_TEACHER_ACCOUNT_REQUEST = "EDIT_TEACHER_ACCOUNT_REQUEST";
export const EDIT_TEACHER_ACCOUNT_SUCCESS = "EDIT_TEACHER_ACCOUNT_SUCCESS";
export const EDIT_TEACHER_ACCOUNT_FAILURE = "EDIT_TEACHER_ACCOUNT_FAILURE";

export const GET_ALL_SUB_ADMIN_ACCOUNTS_REQUEST =
  "GET_ALL_SUB_ADMIN_ACCOUNTS_REQUEST";
export const GET_ALL_SUB_ADMIN_ACCOUNTS_SUCCESS =
  "GET_ALL_SUB_ADMIN_ACCOUNTS_SUCCESS";
export const GET_ALL_SUB_ADMIN_ACCOUNTS_FAILURE =
  "GET_ALL_SUB_ADMIN_ACCOUNTS_FAILURE";

export const REMOVE_SUB_ADMIN_ACCOUNT_REQUEST =
  "REMOVE_SUB_ADMIN_ACCOUNT_REQUEST";
export const REMOVE_SUB_ADMIN_ACCOUNT_SUCCESS =
  "REMOVE_SUB_ADMIN_ACCOUNT_SUCCESS";
export const REMOVE_SUB_ADMIN_ACCOUNT_FAILURE =
  "REMOVE_SUB_ADMIN_ACCOUNT_FAILURE";

export const CREATE_SUB_ADMIN_ACCOUNT_REQUEST =
  "CREATE_SUB_ADMIN_ACCOUNT_REQUEST";
export const CREATE_SUB_ADMIN_ACCOUNT_SUCCESS =
  "CREATE_SUB_ADMIN_ACCOUNT_SUCCESS";
export const CREATE_SUB_ADMIN_ACCOUNT_FAILURE =
  "CREATE_SUB_ADMIN_ACCOUNT_FAILURE";

export const GET_ALL_STUDENT_ACCOUNTS_REQUEST =
  "GET_ALL_STUDENT_ACCOUNTS_REQUEST";
export const GET_ALL_STUDENT_ACCOUNTS_SUCCESS =
  "GET_ALL_STUDENT_ACCOUNTS_SUCCESS";
export const GET_ALL_STUDENT_ACCOUNTS_FAILURE =
  "GET_ALL_STUDENT_ACCOUNTS_FAILURE";
export const GET_ALL_STUDENT_CREDIT_REPORT = 
  "GET_ALL_STUDENT_CREDIT_REPORT";

export const GET_STUDENT_ACCOUNT_REQUEST = "GET_STUDENT_ACCOUNT_REQUEST";
export const GET_STUDENT_ACCOUNT_SUCCESS = "GET_STUDENT_ACCOUNT_SUCCESS";
export const GET_STUDENT_ACCOUNT_FAILURE = "GET_STUDENT_ACCOUNT_FAILURE";

export const EDIT_STUDENT_REQUEST = "EDIT_STUDENT_REQUEST";
export const EDIT_STUDENT_SUCCESS = "EDIT_STUDENT_SUCCESS";
export const EDIT_STUDENT_FAILURE = "EDIT_STUDENT_FAILURE";

export const DEACTIVATE_STUDENT_REQUEST = "DEACTIVATE_STUDENT_REQUEST";
export const DEACTIVATE_STUDENT_SUCCESS = "DEACTIVATE_STUDENT_SUCCESS";
export const DEACTIVATE_STUDENT_FAILURE = "DEACTIVATE_STUDENT_FAILURE";

export const STUDENT_SELECTED_COURSE_REQUEST = "STUDENT_SELECTED_COURSE_REQUEST";
export const STUDENT_SELECTED_COURSE_SUCCESS = "STUDENT_SELECTED_COURSE_SUCCESS";
export const STUDENT_SELECTED_COURSE_FAILURE = "STUDENT_SELECTED_COURSE_FAILURE";

export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_FAILURE = "REPORT_FAILURE"

export const GET_ALL_LESSON_PACKAGES_REQUEST =
  "GET_ALL_LESSON_PACKAGES_REQUEST";
export const GET_ALL_LESSON_PACKAGES_SUCCESS =
  "GET_ALL_LESSON_PACKAGES_SUCCESS";
export const GET_ALL_LESSON_PACKAGES_FAILURE =
  "GET_ALL_LESSON_PACKAGES_FAILURE";

export const REMOVE_LESSON_PACKAGE_REQUEST = "REMOVE_LESSON_PACKAGE_REQUEST";
export const REMOVE_LESSON_PACKAGE_SUCCESS = "REMOVE_LESSON_PACKAGE_SUCCESS";
export const REMOVE_LESSON_PACKAGE_FAILURE = "REMOVE_LESSON_PACKAGE_FAILURE";

export const CREATE_LESSON_PACKAGE_REQUEST = "CREATE_LESSON_PACKAGE_REQUEST";
export const CREATE_LESSON_PACKAGE_SUCCESS = "CREATE_LESSON_PACKAGE_SUCCESS";
export const CREATE_LESSON_PACKAGE_FAILURE = "CREATE_LESSON_PACKAGE_FAILURE";

export const GET_ALL_COURSES_REQUEST = "GET_ALL_COURSES_REQUEST";
export const GET_ALL_COURSES_SUCCESS = "GET_ALL_COURSES_SUCCESS";
export const GET_ALL_COURSES_FAILURE = "GET_ALL_COURSES_FAILURE";

export const GET_COURSE_DETAIL_REQUEST = "GET_COURSE_DETAIL_REQUEST";
export const GET_COURSE_DETAIL_SUCCESS = "GET_COURSE_DETAIL_SUCCESS";
export const GET_COURSE_DETAIL_FAILURE = "GET_COURSE_DETAIL_FAILURE";

export const REMOVE_COURSE_REQUEST = "REMOVE_COURSE_REQUEST";
export const REMOVE_COURSE_SUCCESS = "REMOVE_COURSE_SUCCESS";
export const REMOVE_COURSE_FAILURE = "REMOVE_COURSE_FAILURE";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAILURE = "CREATE_COURSE_FAILURE";

export const EDIT_COURSE_REQUEST = "EDIT_COURSE_REQUEST";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";

export const GET_COURSE_MATERIAL_SLIDES_REQUEST =
  "GET_COURSE_MATERIAL_SLIDES_REQUEST";
export const GET_COURSE_MATERIAL_SLIDES_SUCCESS =
  "GET_COURSE_MATERIAL_SLIDES_SUCCESS";
export const GET_COURSE_MATERIAL_SLIDES_FAILURE =
  "GET_COURSE_MATERIAL_SLIDES_FAILURE";

export const UPDATE_SLIDE_INDEX_REQUEST = "UPDATE_SLIDE_INDEX_REQUEST";
export const UPDATE_SLIDE_INDEX_SUCCESS = "UPDATE_SLIDE_INDEX_SUCCESS";
export const UPDATE_SLIDE_INDEX_FAILURE = "UPDATE_SLIDE_INDEX_FAILURE";

export const EDIT_COURSE_SLIDE_REQUEST = "EDIT_COURSE_SLIDE_REQUEST";
export const EDIT_COURSE_SLIDE_SUCCESS = "EDIT_COURSE_SLIDE_SUCCESS";
export const EDIT_COURSE_SLIDE_FAILURE = "EDIT_COURSE_SLIDE_FAILURE";
export const REMOVE_SLIDE_AUDIO_REQUEST = "REMOVE_SLIDE_AUDIO_REQUEST";

export const REMOVE_COURSE_SLIDE_REQUEST = "REMOVE_COURSE_SLIDE_REQUEST";
export const REMOVE_COURSE_SLIDE_SUCCESS = "REMOVE_COURSE_SLIDE_SUCCESS";
export const REMOVE_COURSE_SLIDE_FAILURE = "REMOVE_COURSE_SLIDE_FAILURE";

export const GET_ALL_ADMIN_ACCOUNTS_REQUEST = "GET_ALL_ADMIN_ACCOUNTS_REQUEST";
export const GET_ALL_ADMIN_ACCOUNTS_SUCCESS = "GET_ALL_ADMIN_ACCOUNTS_SUCCESS";
export const GET_ALL_ADMIN_ACCOUNTS_FAILURE = "GET_ALL_ADMIN_ACCOUNTS_FAILURE";

export const CREATE_ADMIN_ACCOUNT_REQUEST = "CREATE_ADMIN_ACCOUNT_REQUEST";
export const CREATE_ADMIN_ACCOUNT_SUCCESS = "CREATE_ADMIN_ACCOUNT_SUCCESS";
export const CREATE_ADMIN_ACCOUNT_FAILURE = "CREATE_ADMIN_ACCOUNT_FAILURE";

export const GET_ALL_CREDITS_REQUEST = "GET_ALL_CREDITS_REQUEST";
export const GET_ALL_CREDITS_SUCCESS = "GET_ALL_CREDITS_SUCCESS";
export const GET_ALL_CREDITS_FAILURE = "GET_ALL_CREDITS_FAILURE";

export const ASSIGN_CREDIT_REQUEST = "ASSIGN_CREDIT_REQUEST";
export const ASSIGN_CREDIT_SUCCESS = "ASSIGN_CREDIT_SUCCESS";
export const ASSIGN_CREDIT_FAILURE = "ASSIGN_CREDIT_FAILURE";

export const GET_CREDITS_LOG_REQUEST = "GET_CREDITS_LOG_REQUEST";
export const GET_CREDITS_LOG_SUCCESS = "GET_CREDITS_LOG_SUCCESS";
export const GET_CREDITS_LOG_FAILURE = "GET_CREDITS_LOG_FAILURE";

export const GET_ALL_ROOM_SESSIONS_REQUEST = "GET_ALL_ROOM_SESSIONS_REQUEST";
export const GET_ALL_ROOM_SESSIONS_SUCCESS = "GET_ALL_ROOM_SESSIONS_SUCCESS";
export const GET_ALL_ROOM_SESSIONS_FAILURE = "GET_ALL_ROOM_SESSIONS_FAILURE";

export const GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER =
  "GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER";
export const GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER =
  "GET_ALL_ROOM_SESSIONS_SUCCESS_BY_TEACHER";
export const GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER =
  "GET_ALL_ROOM_SESSIONS_FAILURE_BY_TEACHER";

export const GET_ROOM_SESSION_REQUEST = "GET_ROOM_SESSION_REQUEST";
export const GET_ROOM_SESSION_SUCCESS = "GET_ROOM_SESSION_SUCCESS";
export const GET_ROOM_SESSION_FAILURE = "GET_ROOM_SESSION_FAILURE";

export const GET_ROOM_SESSION_RECORDED_VIDEOS_REQUEST = "GET_ROOM_SESSION_RECORDED_VIDEOS_REQUEST";
export const GET_ROOM_SESSION_RECORDED_VIDEOS_SUCCESS = "GET_ROOM_SESSION_RECORDED_VIDEOS_SUCCESS";
export const GET_ROOM_SESSION_RECORDED_VIDEOS_FAILURE = "GET_ROOM_SESSION_RECORDED_VIDEOS_FAILURE";

export const REMOVE_ROOM_SESSION_REQUEST = "REMOVE_ROOM_SESSION_REQUEST";
export const REMOVE_ROOM_SESSION_SUCCESS = "REMOVE_ROOM_SESSION_SUCCESS";
export const REMOVE_ROOM_SESSION_FAILURE = "REMOVE_ROOM_SESSION_FAILURE";

export const ASSIGN_CLASSROOM_SESSION_REQUEST =
  "ASSIGN_CLASSROOM_SESSION_REQUEST";
export const ASSIGN_CLASSROOM_SESSION_SUCCESS =
  "ASSIGN_CLASSROOM_SESSION_SUCCESS";
export const ASSIGN_CLASSROOM_SESSION_FAILURE =
  "ASSIGN_CLASSROOM_SESSION_FAILURE";

export const EXPORT_CLASSROOM_SESSIONS_REQUEST =
  "EXPORT_CLASSROOM_SESSIONS_REQUEST";
export const EXPORT_CLASSROOM_SESSIONS_SUCCESS =
  "EXPORT_CLASSROOM_SESSIONS_SUCCESS";
export const EXPORT_CLASSROOM_SESSIONS_FAILURE =
  "EXPORT_CLASSROOM_SESSIONS_FAILURE";

export const UPDATE_CLASS_ROOM_SESSION_REQUEST =
  "UPDATE_CLASS_ROOM_SESSION_REQUEST";
