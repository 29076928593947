import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import moment from "moment-timezone";
import {
  Card,
  Button,
  Tooltip,
  Table,
  Pagination,
  Modal,
  Tag,
  Form,
  Drawer,
  Row,
  Col,
  Collapse,
  Select,
  DatePicker,
  Radio,
  Input,
} from "antd";
import {
  DeleteOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  ExportOutlined,
  ReconciliationOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {
  _ROUTES,
  _PAGESIZE,
  _DEFAULT_ROWS_PER_PAGE,
  _CONST,
  _COURSE_TYPES,
  _ENGLISH_EXPOSURE_LEVEL,
  _CLASSROOM_SESSION_STATUS
} from "../../constants/GlobalConst";
import {
  _makeQueryString,
  _formatDateTime,
  _notify,
} from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";
import "./RoomStyle.css";

const { TextArea, label } = Input;

class RoomSessionsList extends React.Component {
  constructor(props) {
    super(props);

    const defaultFilter = {
      course_uuid: "",
      teacher_account_uuid: "",
      student_account_uuid: "",
      start_from: "",
      start_to: "",
      classroom_session_status: "",
      deleted_at: ""
    }

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      defaultFilter: defaultFilter,
      filter: defaultFilter,
      all_students: [],
      all_teachers: [],
      all_courses: [],
      total_records: 0,
      room_sessions: [],
      room_session_recorded_videos: [],
      room_session: {},
      teacher: {},
      student: {},
      showViewRoomSession: false,
      showViewTeacherDrawer: false,
      showViewStudentDrawer: false,
      showUpdateStatusModal: false,
      currentStatusRecord: {},
      currentSelectedOption: "",
      currentRadioSelection: "",
      currentStatusReason: "",
      sub_status_error: "",
      showDeleteClassroomModal: false,
      deleteClassroomRecord: {},
      deleteClassroomReason: "",
      showDeletedRecord: false,
      all_status: _CLASSROOM_SESSION_STATUS
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this._loadRoomSessions();

    this.props.getAllTeacherAccountsRequest({
      qs: 'cols={"account":["uuid","email"],"profile":["fullname"]}',
    });

    this.props.getAllStudentAccountsRequest({
      qs: 'cols={"account":["uuid","email"],"profile":["fullname"]}',
    });

    this.props.getAllCoursesRequest({ qs: "" });
  }

  componentDidUpdate(prevProps) {
    const {
      list,
      get_all_sessions_recorded_videos,
      view,
      view_teacher,
      view_student,
      remove,
      teachers,
      students,
      courses,
      csv_export,
    } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          room_sessions: _.get(result, "response.data", []),
          total_records: _.get(result, "response.pagination.totalItems", 0),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (
      prevProps.get_all_sessions_recorded_videos !==
      get_all_sessions_recorded_videos
    ) {
      const { result } = get_all_sessions_recorded_videos;
      if (result.success) {
        this.setState({
          room_session_recorded_videos: _.get(result, "response.data", []),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.view !== view) {
      const { result } = view;
      if (result.success) {
        this.setState({
          room_session: _.get(result, "response.data", {}),
          showViewRoomSession: true,
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.remove !== remove) {
      const { result } = remove;
      if (result.success) {
        _notify({
          type: "success",
          description: "Classroom Session is successfully removed",
        });
        this._resetPagination();
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.view_teacher !== view_teacher) {
      const { result } = view_teacher;
      if (result.success) {
        this.setState({
          teacher: _.get(result, "response.data", {}),
          showViewTeacherDrawer: true,
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.view_student !== view_student) {
      const { result } = view_student;
      if (result.success) {
        this.setState({
          student: _.get(result, "response.data", {}),
          showViewStudentDrawer: true,
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.teachers !== teachers) {
      const { result } = teachers;
      if (result.success) {
        this.setState({
          all_teachers: _.get(result, "response.data", []),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.students !== students) {
      const { result } = students;
      if (result.success) {
        this.setState({
          all_students: _.get(result, "response.data", []),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.courses !== courses) {
      const { result } = courses;
      if (result.success) {
        const all_courses = _.chain(_.get(result, "response.data", []))
          // Group the elements of Array based on `level` property
          .groupBy("level")
          // `key` is group's name (level), `value` is the array of objects
          .map((value, key) => ({ level: key, courses: value }))
          .value();

        this.setState({ all_courses });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.csv_export !== csv_export) {
      const { result } = csv_export;
      if (result.success) {
        try {
          const url = window.URL.createObjectURL(new Blob([result.response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "classroom.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();
        } catch {
          _notify({
            type: "error",
            description: "An error occurred while exporting classroom sessions",
          });
        }
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }
  }

  _openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  _resetPagination = () => {
    const { room_sessions, pagination } = this.state;
    let reset_pagination = room_sessions.length - 1 === 0;
    let n_page = reset_pagination
      ? pagination.page === 1
        ? pagination.page
        : pagination.page - 1
      : pagination.page;

    this.setState(
      {
        pagination: {
          ...pagination,
          page: n_page,
        },
      },
      () => this._loadRoomSessions()
    );
  };

  _loadRoomSessions = () => {
    const { pagination, filter, showDeletedRecord } = this.state;

    const filters = _.pickBy(filter, _.identity);

    this.props.getAllRoomSessionsRequest({
      qs: _makeQueryString({ ...pagination, ...filters, show_deleted_record: showDeletedRecord }),
    });
  };

  _handleFilter = (values) => {
    const { pagination } = this.state;

    this.setState(
      {
        pagination: {
          ...pagination,
          page: 1,
        },
        filter: {
          course_uuid: _.get(values, "course_uuid", ""),
          teacher_account_uuid: _.get(values, "teacher_account_uuid", ""),
          student_account_uuid: _.get(values, "student_account_uuid", ""),
          start_from: !_.isEmpty(values.start_from)
            ? moment(values.start_from).format(_CONST.DATE_FORMAT)
            : "",
          start_to: !_.isEmpty(values.start_to)
            ? moment(values.start_to).format(_CONST.DATE_FORMAT)
            : "",
          classroom_session_status: _.get(values, "classroom_session_status", ""),
          deleted_at: !_.isEmpty(values.deleted_at) ? moment(values.deleted_at).format(_CONST.DATE_FORMAT) : ""
        },
      },
      () => this._loadRoomSessions()
    );
  };

  _handleClear = () => {
    this.formRef.current.resetFields();
    this.setState(
      {
        filter: {
          course_uuid: "",
          teacher_account_uuid: "",
          student_account_uuid: "",
          start_from: "",
          start_to: "",
        },
      },
      () => this._loadRoomSessions()
    );
  };

  _handleExport = () => {
    const { room_sessions, filter } = this.state;

    if (room_sessions.length > 0) {
      const filters = _.pickBy(filter, _.identity);

      this.props.exportRoomSessionsRequest({
        qs: _makeQueryString({ ...filters }),
      });
    } else {
      _notify({ type: "error", description: "No records found to export" });
    }
  };

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState(
      {
        pagination: {
          ...pagination,
          size,
          page,
        },
      },
      () => this._loadRoomSessions()
    );
  };

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          size,
          page: 1,
        },
      },
      () => this._loadRoomSessions()
    );
  };

  _handleRemoveRoomSessionModel = (record) => {
    this.setState({ 
      deleteClassroomRecord: record,
      showDeleteClassroomModal: true,
      deleteClassroomReason: "" 
    });
  };

  _handleRemoveRoomSession = () => {
    if (!this.state.deleteClassroomReason) {
      _notify({
        type: "error",
        description: "Reason is require for delete classroom session"
      });
      return;
    }

    this.props.removeRoomSessionRequest({ uuid: this.state.deleteClassroomRecord.uuid, deletedReason: this.state.deleteClassroomReason });
    this.setState({ 
      deleteClassroomRecord: {},
      showDeleteClassroomModal: false,
      deleteClassroomReason: "" 
    });
  }

  _handleDeleteReasonChange = (event) => {
    this.setState({ deleteClassroomReason: event?.target?.value });
  }

  _handleViewRoomSession = (record) => {
    this.props.getRoomSessionRequest({ uuid: record.uuid });
    this.props.getAllRoomSessionsRecordedVideosRequest({ uuid: record.uuid });
  };

  _handleViewTeacher = (record) => {
    this.props.getTeacherAccountRequest({ uuid: record.teacher_account_uuid });
  };

  _handleViewStudent = (record) => {
    this.props.getStudentAccountRequest({ uuid: record.student_account_uuid });
  };

  _getCourseTypeText = (course_type) => {
    try {
      return `${
        _.find(_COURSE_TYPES, (ct) => {
          return ct.value === course_type;
        }).text
      }`;
    } catch {
      return null;
    }
  };

  _getEnglishExposureLevelName = (level) => {
    try {
      return !_.isEmpty(level)
        ? _.find(_ENGLISH_EXPOSURE_LEVEL, (eel) => {
            return eel.value === level;
          }).text
        : "";
    } catch {
      return "";
    }
  };

  _handleChangeStatus = (value) => {
    this.setState({
      currentSelectedOption: value,
      currentRadioSelection: "",
      currentStatusReason: "",
    });
  };

  _handleChangeSubStatus = (e) => {
    this.setState({
      currentRadioSelection: e.target.value,
    });
  };

  _updateStatusClassroom = () => {
    const payload = {
      status: this.state.currentSelectedOption,
      sub_status: this.state.currentRadioSelection,
      reason: this.state.currentStatusReason,
      id: this.state.currentStatusRecord.uuid,
    };

    if (payload.status === "Incomplete" && !payload.sub_status) {
      this.setState({ sub_status_error: "Please select any one radio option" });
    } else {
      this.setState(
        { currentStatusRecord: {}, showUpdateStatusModal: false },
        () => {
          this.props.updateClassroomSessionRequest(payload);
          this._loadRoomSessions();
        }
      );
    }
  };

  displayTimeDuration = (classroom_duration) => {
    if(classroom_duration === null)
    {
      return "";
    }
    else{
      if(classroom_duration <=45){
        return `${classroom_duration} Minutes`;
      }else{
        let hours = Math.floor(classroom_duration / 60);
        let minutes = classroom_duration % 60;
  
        if(hours>=2){
          return `${hours} Hours`;
        }
        else{
          if(minutes===0){
            return `${hours} Hour`;
          }else{
            return `${hours} Hour ${minutes} Minutes`;
          }
        }
      }
    }
  }

  _handleTogleSessions = () => {
    this.setState({ showDeletedRecord: !this.state.showDeletedRecord, filter: this.state.defaultFilter  });
    this._resetPagination();
  }
  
  copyToClipboard = (video) => {
    var textField = document.createElement('textarea')
    textField.innerText = video.s3_url === null ? `${process.env.REACT_APP_API_BASE_URL}${video.video_path}` : `${process.env.REACT_APP_S3_URL}/video/${video.s3_url}`;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove();
    _notify({ type: "success", description: "Lesson URL copied successfully.!" });
  }

  render() {
    const {
      pagination,
      room_sessions,
      room_session,
      room_session_recorded_videos,
      total_records,
      teacher,
      student,
      showViewRoomSession,
      showViewTeacherDrawer,
      showViewStudentDrawer,
      all_teachers,
      all_courses,
      all_students,
      showUpdateStatusModal,
      currentStatusRecord,
      currentSelectedOption,
      currentRadioSelection,
      sub_status_error,
      showDeleteClassroomModal,
      showDeletedRecord,
      all_status
    } = this.state;

    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px",
    };

    let COLUMNS = [
      {
        title: "Teacher",
        key: "uuid",
        className: "col-teacher",
        width: `${showDeletedRecord ? "12%" : "13%"}`,
        render: (text, record) => (
          <div>
            <div className="name">
              {_.get(record, "teacher_account.profile.fullname", "")}
            </div>
            <div>
              <a onClick={() => this._handleViewTeacher(record)}>
                {_.get(record, "teacher_account.email", "")}
              </a>
            </div>
          </div>
        ),
      },
      {
        title: "Student",
        className: "col-student",
        width: "13%",
        render: (text, record) => (
          <div>
            <div className="name">
              {_.get(record, "account.profile.fullname", "")}
            </div>
            <div className="name">
              {_.get(record, "account.profile.nickname", "")}
            </div>
            <div>
              <a onClick={() => this._handleViewStudent(record)}>
                {_.get(record, "account.email", "")}
              </a>
            </div>
          </div>
        ),
      },
      {
        title: "Course",
        dataIndex: "course",
        className: "col-course",
        width: `${showDeletedRecord ? "10%" : "15%"}`,
        render: (text, record) => (
          <div>
            <div className="name">{_.get(record, "course.name", "")}</div>
            <div>
              <Tag color="magenta">
                Level {_.get(record, "course.level", "")}
              </Tag>
              <Tag color="volcano">Unit {_.get(record, "course.unit", "")}</Tag>
              <Tag color="purple">
                Lesson {_.get(record, "course.lesson", "")}
              </Tag>
            </div>
          </div>
        ),
      },
      {
        title: `${showDeletedRecord ? "Reason" : "Status"}`,
        dataIndex: `${showDeletedRecord ? "deleted_reason" : "status"}`,
        className: "col-course",
        width: `${showDeletedRecord ? "20%" : "9%"}`,
        render: (text, record) => (
          <div style={{ display: "block" }}>
            {showDeletedRecord ? 
              <div>
                {_.get(record, "deleted_reason", "")}
              </div> :
              <>
                <div style={{ margin: "2px" }}>
                  {" "}
                  {record.status && (
                    <Tag
                      color={
                        record.status === "Completed"
                          ? "green"
                          : record.status === "Incomplete"
                          ? "red"
                          : "blue"
                      }
                    >
                      {" "}
                      {_.get(record, "status", "")}
                    </Tag>
                  )}
                </div>
                <div style={{ margin: "2px" }}>
                  {record.sub_status && (
                    <Tag color="orange"> {_.get(record, "sub_status", "")}</Tag>
                  )}
                </div>
                <div style={{ margin: "2px" }}>
                  {record.reason && (
                    <Tag color="purple"> {_.get(record, "reason", "")}</Tag>
                  )}
                </div>
              </>
            } 
          </div>
        ),
      },
      {
        title: "Start At",
        width: `${showDeletedRecord ? "15%" : "20%"}`,
        render: (text, record) => (
          <div>
            {moment(record.start_at)
              .tz(_CONST.TIMEZONE)
              .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)}
          </div>
        ),
      },
      {
        title: "End At",
        width: `${showDeletedRecord ? "15%" : "20%"}`,
        render: (text, record) => (
          <div>
            {moment(record.end_at)
              .tz(_CONST.TIMEZONE)
              .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)}
          </div>
        ),
      },
      {
        title: `${showDeletedRecord ? "Deleted At" : "Action"}`,
        align: `${showDeletedRecord ? "Left" : "center"}`,
        width: `${showDeletedRecord ? "15%" : "10%"}`,
        render: (text, record) => (
          <div>
            {showDeletedRecord ?
              <div>
                {moment(record.deleted_at)
                  .tz(_CONST.TIMEZONE)
                  .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)}
              </div> :
              <div className="sk-button-group">
                <Tooltip placement="top" title="View">
                  <Button
                    size="small"
                    onClick={() => this._handleViewRoomSession(record)}
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title="Update Status">
                  <Button
                    size="small"
                    onClick={() => {
                      this.setState({
                        showUpdateStatusModal: true,
                        currentStatusRecord: record,
                      });
                    }}
                  >
                    <ReconciliationOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title="Remove">
                  <Button
                    type="danger"
                    size="small"
                    onClick={() => this._handleRemoveRoomSessionModel(record)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </div>
            }
          </div>
        ),
      },
    ];

    return (
      <React.Fragment>
        <Card 
          className="sk-card"
          title={showDeletedRecord ? "Deleted Sessions" : "Classroom Sessions"}
          extra={
            <div className="sk-button-group">
              <Button
                type="primary"
                icon={<RetweetOutlined  />}
                onClick={this._handleTogleSessions}
              >
                {showDeletedRecord ? "Classroom Sessions" : "Deleted Sessions"}
              </Button>
              <Button
                type="primary"
                icon={<ExportOutlined />}
                onClick={this._handleExport}
              >
                Export
              </Button>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  this.props.history.push(
                    _ROUTES.PATH_CLASSROOM_SESSIONS_ASSIGN
                  );
                  this.props.getStudentSelectedCourse({ id: "" });
                }}
              >
                Assign
              </Button>
            </div>
          }
        >
          <div className="filter-panel">
            <Collapse>
              <Collapse.Panel header={<b>Filters</b>} key="1">
                <Form
                  ref={this.formRef}
                  layout="vertical"
                  onFinish={this._handleFilter}
                  initialValues={{
                    student_account_uuid: undefined,
                    extended_min: 15,
                  }}
                >
                  <Row gutter={[16, 0]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <Form.Item
                        name="teacher_account_uuid"
                        label="Teacher Account"
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          placeholder="Select Teacher Account"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            const result = _.filter(
                              option.children,
                              function (value) {
                                return _.includes(
                                  value.props.children &&
                                    value.props.children.toLowerCase(),
                                  input.toLowerCase()
                                );
                              }
                            );
                            return result.length;
                          }}
                        >
                          {_.map(all_teachers, (teacher, key) => {
                            return (
                              <Select.Option
                                value={teacher.uuid}
                                key={key}
                                className="sk-opt-rows"
                              >
                                <div className="row1">
                                  {_.get(teacher, "profile.fullname", "")}
                                </div>
                                <div className="row2">
                                  {_.get(teacher, "email", "")}
                                </div>
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <Form.Item
                        name="student_account_uuid"
                        label="Student Account"
                      >
                        <Select
                          size="large"
                          showSearch
                          allowClear
                          placeholder="Select Student Account"
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            const result = _.filter(
                              option.children,
                              function (value) {
                                return _.includes(
                                  value.props.children &&
                                    value.props.children.toLowerCase(),
                                  input.toLowerCase()
                                );
                              }
                            );
                            return result.length;
                          }}
                        >
                          {_.map(all_students, (student, key) => {
                            return (
                              <Select.Option
                                value={student.uuid}
                                key={key}
                                className="sk-opt-rows"
                              >
                                <div className="row1">
                                  {_.get(student, "profile.fullname", "")}
                                </div>
                                <div className="row2">
                                  {_.get(student, "email", "")}
                                </div>
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <Form.Item name="course_uuid" label="Course">
                        <Select
                          size="large"
                          allowClear
                          placeholder="Select Course"
                        >
                          {_.map(all_courses, (course, key) => {
                            return (
                              <Select.OptGroup
                                key={key}
                                label={<b>Level {course.level}</b>}
                              >
                                {_.map(
                                  _.sortBy(course.courses, ["name"]),
                                  (option) => (
                                    <Select.Option
                                      key={option.uuid}
                                      value={option.uuid}
                                    >
                                      <div>
                                        <span>{option.name}</span>
                                        <span style={{ paddingLeft: "5px" }}>
                                          <b>{`Level ${option.level} - Unit ${option.unit} - Lesson ${option.lesson}`}</b>
                                        </span>
                                      </div>
                                    </Select.Option>
                                  )
                                )}
                              </Select.OptGroup>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <Form.Item name="start_from" label="Start Date From">
                        <DatePicker size="large" className="full-width" />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <Form.Item name="start_to" label="Start Date To">
                        <DatePicker size="large" className="full-width" />
                      </Form.Item>
                    </Col>
                    {!showDeletedRecord &&
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Form.Item
                          name="classroom_session_status"
                          label="Status"
                        >
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            placeholder="Select Status"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return _.includes(
                                option.children.props.children &&
                                option.children.props.children.toLowerCase(),
                                input.toLowerCase()
                              );
                            }}
                          >
                            {_.map(all_status, (status, key) => {
                              return (
                                <Select.Option
                                  value={status.value}
                                  key={key}
                                  className="sk-opt-rows"
                                >
                                  <div className="row1">
                                    {_.get(status, "text", "")}
                                  </div>
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                    }
                    {showDeletedRecord && 
                      <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                        <Form.Item name="deleted_at" label="Deleted At">
                          <DatePicker size="large" className="full-width" />
                        </Form.Item>
                      </Col>
                    }
                    <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                      <div
                        className="sk-button-group"
                        style={{ paddingTop: "30px" }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          icon={<SearchOutlined />}
                        >
                          Filter
                        </Button>
                        <Button size="large" onClick={this._handleClear}>
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Collapse.Panel>
            </Collapse>
          </div>
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className={`${showDeletedRecord ? "sk-table-responsive table-tr-cursor" : "sk-table-responsive"}`}
              columns={COLUMNS}
              dataSource={room_sessions}
              pagination={false}
              onRow={(row) => ({ onClick: () => showDeletedRecord && this._handleViewRoomSession(row) })}
            />
            {!_.isEmpty(room_sessions) && (
              <div className="sk-pagination">
                <Pagination
                  current={pagination.page}
                  onChange={this._handlePageChange}
                  pageSize={pagination.size}
                  pageSizeOptions={_PAGESIZE}
                  showSizeChanger={true}
                  onShowSizeChange={this._handlePageSizeChange}
                  total={total_records}
                />
              </div>
            )}
          </div>
        </Card>

        {currentStatusRecord && (
          <Modal
            visible={showUpdateStatusModal}
            title={"Update Course Session Status"}
            onCancel={() =>
              this.setState({
                showUpdateStatusModal: false,
                currentRadioSelection: "",
                sub_status_error: "",
                currentSelectedOption: "",
              })
            }
            onOk={this._updateStatusClassroom}
          >
            <Select
              allowClear
              placeholder="Select Type The Status"
              style={{ width: "100%" }}
              onChange={this._handleChangeStatus}
            >
              <Select.Option value="Completed">Completed</Select.Option>
              <Select.Option value="Scheduled">Scheduled</Select.Option>
              <Select.Option value="Incomplete">Incomplete</Select.Option>
            </Select>
            {currentSelectedOption === "Incomplete" && (
              <div style={{ padding: "10px" }}>
                <p style={{ marginTop: "2%" }}>
                  Please Select Any Reason For Incompletion
                </p>
                <Radio.Group
                  onChange={this._handleChangeSubStatus}
                  value={currentRadioSelection}
                >
                  <Radio style={radioStyle} value={"Cancelled by student"}>
                    Cancelled by student
                  </Radio>
                  <Radio style={radioStyle} value={"Cancelled by teacher"}>
                    Cancelled by teacher
                  </Radio>
                  <Radio style={radioStyle} value={"Regular student no-show"}>
                    Regular student no-show
                  </Radio>
                  <Radio style={radioStyle} value={"Trial student no-show"}>
                    Trial student no-show
                  </Radio>
                  <Radio style={radioStyle} value={"Teacher no-show"}>
                    Teacher no-show
                  </Radio>
                  <Radio style={radioStyle} value={"Others"}>
                    Others
                  </Radio>
                </Radio.Group>
                <p style={{ fontSize: "14px", color: "red" }}>
                  {sub_status_error}
                </p>
                <TextArea
                  placeholder="Please specify any reason for incompletion"
                  allowClear
                  style={{ marginTop: "2%" }}
                  onChange={(e) =>
                    this.setState({ currentStatusReason: e.target.value })
                  }
                />
              </div>
            )}
          </Modal>
        )}

        <Modal
          className="delete-class-room-modal"
          visible={showDeleteClassroomModal}
          title={"Confirmation"}
          onCancel={() =>
            this.setState({
              showDeleteClassroomModal: false,
              deleteClassroomRecord: {},
              deleteClassroomReason: ""
            })
          }
          okText="Confirm"
          cancelText="Cancel"
          okType="danger"
          onOk={this._handleRemoveRoomSession}
          >
            <div>
              <label>Please add reason for delete classroom session</label>
              <TextArea allowClear onChange={this._handleDeleteReasonChange} value={this.state.deleteClassroomReason}  className="margin-top-5" />
            </div>
        </Modal>

        {showViewRoomSession && (
          <Drawer
            title={"View Classroom Session"}
            className="sk-drawer"
            closable={true}
            onClose={() => this.setState({ showViewRoomSession: false })}
            visible={showViewRoomSession}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item label="UUID">
                    {_.get(room_session, "uuid", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Status">
                    <Tag
                      color={
                        room_session.status === "Completed"
                          ? "green"
                          : room_session.status === "Incomplete"
                          ? "red"
                          : "blue"
                      }
                    >
                      {_.get(room_session, "status", "")}
                    </Tag>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Sub Status">
                    <div>{_.get(room_session, "sub_status", "")}</div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item label="Incompletion Reason">
                    <div>{_.get(room_session, "reason", "")}</div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Teacher">
                    <div title="Full Name">
                      {_.get(
                        room_session,
                        "teacher_account.profile.fullname",
                        ""
                      )}
                    </div>
                    <div title="Email">
                      {_.get(room_session, "teacher_account.email", "")}
                    </div>
                    <div title="Phone Number">
                      {_.get(room_session, "teacher_account.phone_number", "")}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Student">
                    <div>
                      <span title="Full Name">
                        {_.get(room_session, "account.profile.fullname", "")}
                      </span>
                      <span title="Nick Name">{` (${_.get(
                        room_session,
                        "account.profile.nickname",
                        ""
                      )})`}</span>
                    </div>
                    <div title="Email">
                      {_.get(room_session, "account.email", "")}
                    </div>
                    <div title="Phone Number">
                      {_.get(room_session, "account.phone_number", "")}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Course Type">
                    <div>
                      {this._getCourseTypeText(room_session.course.type)}
                    </div>
                    <div>
                      <Tag color="magenta">
                        Level {_.get(room_session, "course.level", "")}
                      </Tag>
                      <Tag color="volcano">
                        Unit {_.get(room_session, "course.unit", "")}
                      </Tag>
                      <Tag color="purple">
                        Lesson {_.get(room_session, "course.lesson", "")}
                      </Tag>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Type">
                    {_.get(room_session, "type", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Classroom Type">
                    {_.get(room_session, "classroom_type", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Type Remarks">
                    {_.get(room_session, "remarks", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Start At">
                    {moment(room_session.start_at)
                      .tz(_CONST.TIMEZONE)
                      .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="End At">
                    {moment(room_session.end_at)
                      .tz(_CONST.TIMEZONE)
                      .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Classroom Duration">
                    <div>
                      {this.displayTimeDuration(room_session.classroom_duration)}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Video Recording">
                    <div>
                      {room_session.allow_video_recording}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Extended Time">
                    <div>
                      {_.isNumber(room_session.extended_min)
                        ? `${room_session.extended_min} Minutes`
                        : `-`}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Videoroom Id">
                    {_.get(room_session, "videoroom_id", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Reminder Call Status">
                    {_.get(room_session, "reminder_status", "") === 1
                      ? "Sent"
                      : "Not sent yet"}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Assigned At">
                    {
                      moment(room_session.assigned_at)
                        .tz(_CONST.TIMEZONE)
                        .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)
                    }
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Inserted At">
                    {
                      moment(room_session.inserted_at)
                        .tz(_CONST.TIMEZONE)
                        .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)
                    }
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Updated At">
                    {
                      moment(room_session.updated_at)
                        .tz(_CONST.TIMEZONE)
                        .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)
                    }
                  </Form.Item>
                </Col>
                {showDeletedRecord && 
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Deleted At">
                      {
                        moment(room_session.deleted_at)
                          .tz(_CONST.TIMEZONE)
                          .format(_CONST.CLASSROOM_DATETIME_DATETIME_FORMAT)
                      }
                    </Form.Item>
                  </Col>
                }
              </Row>

              <Row>
                <Col>
                  <Form.Item label="Recorded Videos For Classroom">
                    {room_session_recorded_videos.length > 0 ? (
                      <Row className="d-flex">
                        <Col>
                          {room_session_recorded_videos.map((video, index) => {
                            return (
                              <div key={`client-key-${index}`} className="d-flex">
                                <a
                                  className="copy_css"
                                  download
                                  onClick={() => {
                                    let downloadUrl = video.s3_url !== null ? `${process.env.REACT_APP_S3_URL}/video/${video.s3_url}` : `${process.env.REACT_APP_API_BASE_URL}${video.video_path}`;
                                    axios({
                                      url: downloadUrl,
                                      method: "GET",
                                      responseType: "blob",
                                    }).then((response) => {
                                      const url = window.URL.createObjectURL(response.data);
                                      const link = document.createElement("a");
                                      link.href = url;
                                      var name = response.config.url.split("/")
                                      link.setAttribute("download", `${name[name.length - 1]}`); //or any other extension
                                      document.body.appendChild(link);
                                      link.click();
                                    });
                                  }}
                                >
                                  Recorded Lesson {index + 1}
                                </a>
                                {video.s3_url !== null
                                  ?
                                  <a onClick={() => {
                                    this.copyToClipboard(video);
                                  }}>
                                    Copy Externally
                                  </a>
                                  : ""}
                                <br></br>
                              </div>
                            );
                          })}
                        </Col>
                        <Col className="xs-6">
                        {room_session_recorded_videos.map((video, index) => {
                          <h6>
                            Copy to Clipbaord
                          </h6>
                        })}
                      </Col>
                      </Row>
                    ) : (
                      <p>No recorded videos exists for this classroom</p>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        )}

        {showViewTeacherDrawer && (
          <Drawer
            title={"View Teacher Account"}
            className="sk-drawer"
            closable={true}
            onClose={() => this.setState({ showViewTeacherDrawer: false })}
            visible={showViewTeacherDrawer}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item label="UUID">
                    {_.get(teacher, "uuid", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Email">
                    {_.get(teacher, "email", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Phone Number">
                    {_.get(teacher, "phone_number", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Full Name">
                    {_.get(teacher, "profile.fullname", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Date of Birth">
                    {!_.isEmpty(_.get(teacher, "profile.date_of_birth", ""))
                      ? moment(
                          _.get(teacher, "profile.date_of_birth", "")
                        ).format(_CONST.DATE_FORMAT)
                      : "N/A"}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Last Authenticated At">
                    {_formatDateTime(
                      teacher.last_authenticated_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Registered At">
                    {_formatDateTime(
                      teacher.registered_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Inserted At">
                    {_formatDateTime(
                      teacher.inserted_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Updated At">
                    {_formatDateTime(
                      teacher.updated_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        )}

        {showViewStudentDrawer && (
          <Drawer
            title={"View Student Account"}
            className="sk-drawer"
            closable={true}
            onClose={() => this.setState({ showViewStudentDrawer: false })}
            visible={showViewStudentDrawer}
          >
            <Form layout="vertical">
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item label="UUID">
                    {_.get(student, "uuid", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Email">
                    {_.get(student, "email", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Phone Number">
                    {_.get(student, "phone_number", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Full Name">
                    {_.get(student, "profile.fullname", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Nick Name">
                    {_.get(student, "profile.nickname", "")}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Date of Birth">
                    {!_.isEmpty(_.get(student, "profile.date_of_birth", ""))
                      ? moment(
                          _.get(student, "profile.date_of_birth", "")
                        ).format(_CONST.DATE_FORMAT)
                      : "N/A"}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="English Exposure Level">
                    {this._getEnglishExposureLevelName(
                      _.get(student, "profile.english_exposure_level", "")
                    )}
                  </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Last Authenticated At">
                    {_formatDateTime(
                      student.last_authenticated_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Registered At">
                    {_formatDateTime(
                      student.registered_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Inserted At">
                    {_formatDateTime(
                      student.inserted_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Updated At">
                    {_formatDateTime(
                      student.updated_at,
                      _CONST.LONG_DATETIME_FORMAT
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, "room.list", {}),
  get_all_sessions_recorded_videos: _.get(
    state,
    "room.get_all_sessions_recorded_videos",
    {}
  ),
  view: _.get(state, "room.view", {}),
  remove: _.get(state, "room.remove", {}),
  csv_export: _.get(state, "room.csv_export", {}),
  view_teacher: _.get(state, "teacher.view", {}),
  view_student: _.get(state, "student.view", {}),
  teachers: _.get(state, "teacher.list", {}),
  students: _.get(state, "student.list", {}),
  courses: _.get(state, "course.list", {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllRoomSessionsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_ROOM_SESSIONS_REQUEST, payload }),
  getRoomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ROOM_SESSION_REQUEST, payload }),
  removeRoomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.REMOVE_ROOM_SESSION_REQUEST, payload }),
  getTeacherAccountRequest: (payload) =>
    dispatch({ type: reduxActions.GET_TEACHER_ACCOUNT_REQUEST, payload }),
  getStudentAccountRequest: (payload) =>
    dispatch({ type: reduxActions.GET_STUDENT_ACCOUNT_REQUEST, payload }),
  getAllTeacherAccountsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_TEACHER_ACCOUNTS_REQUEST, payload }),
  getAllStudentAccountsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_REQUEST, payload }),
  getAllCoursesRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_COURSES_REQUEST, payload }),
  exportRoomSessionsRequest: (payload) =>
    dispatch({ type: reduxActions.EXPORT_CLASSROOM_SESSIONS_REQUEST, payload }),
  updateClassroomSessionRequest: (payload) =>
    dispatch({ type: reduxActions.UPDATE_CLASS_ROOM_SESSION_REQUEST, payload }),
  getStudentSelectedCourse: payload => 
    dispatch({ type: reduxActions.STUDENT_SELECTED_COURSE_REQUEST, payload }),
  getAllRoomSessionsRecordedVideosRequest: (payload) =>
    dispatch({
      type: reduxActions.GET_ROOM_SESSION_RECORDED_VIDEOS_REQUEST,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomSessionsList);
