import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_LESSON_PACKAGES_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_LESSON_PACKAGES_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const remove = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_LESSON_PACKAGE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_LESSON_PACKAGE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const create = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.CREATE_LESSON_PACKAGE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CREATE_LESSON_PACKAGE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  list,
  remove,
  create,
});
