import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
  Card, Button, Tooltip, Table, Pagination, Modal, Drawer,
  Form, Row, Col, Image, Tabs, Input, Upload, Carousel, Tag, Spin, Divider
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileAddOutlined,
  EditOutlined,
  MenuOutlined,
  UploadOutlined,
  AppstoreOutlined,
  LeftOutlined,
  RightOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { _ROUTES, _PAGESIZE, _DEFAULT_ROWS_PER_PAGE, _CONST, _COURSE_TYPES } from '../../constants/GlobalConst';
import { _makeQueryString, _formatDateTime, _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

import './CourseStyle.css';

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class CoursesList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      total_records: 0,
      courses: [],
      course: {},
      slides: [],
      fileListEdit: [],
      audioList: [],
      isAudioDeleted: false,
      slides_loading: false,
      edit_slide_loading: false,
      edit_slide_obj: {},
      showViewCourseDrawer: false,
      showEditSlideModal: false,
      showSlidesModal: false,
      current_slide_show_index: 0,
      csv_data: [],
      csv_columns: [],
    };

    this.sliderRef = React.createRef();
  }

  componentDidMount() {
    this._loadCourses();
  }

  componentDidUpdate(prevProps) {
    const { list, remove, material_slides, update_slide_index, edit_slide, remove_slide } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          courses: _.get(result, 'response.data', []),
          lastCourse: _.get(result, 'response.lastCourse', ""),
          total_records: _.get(result, 'response.pagination.totalItems', 0),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.remove !== remove) {
      const { result } = remove;
      if (result.success) {
        _notify({ type: 'success', description: 'Course is successfully removed' });
        this._resetPagination();
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.material_slides !== material_slides) {
      const { result } = material_slides;
      if (result.success) {
        this.setState({
          slides: _.get(result, 'response.data', []),
          slides_loading: false,
          slide_show_loading: false,
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.update_slide_index !== update_slide_index) {
      const { result } = update_slide_index;
      if (result.success) {
        this.props.getCourseMaterialSlidesRequest({ uuid: this.state.course.uuid });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.edit_slide !== edit_slide) {
      const { result } = edit_slide;
      if (result.success) {
        _notify({ type: 'success', description: 'Course Matetial Slide is successfully updated' });
        this.setState({
          edit_slide_loading: false,
          showEditSlideModal: false,
          edit_slide_obj: {},
          fileListEdit: [],
          audioList: [],
          slides_loading: true,
        }, () => {
          this.props.getCourseMaterialSlidesRequest({ uuid: this.state.course.uuid });
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
        this.setState({
          edit_slide_loading: false
        })
      }
    }

    if(prevProps.remove_slide !== remove_slide){
      const { result } = remove_slide;
      if (result.success) {
        _notify({ type: 'success', description: 'Course Matetial Slide is successfully deleted' });
        this.setState({
          slides_loading: true,
        }, () => {
          this.props.getCourseMaterialSlidesRequest({ uuid: this.state.course.uuid });
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
        this.setState({
          slides_loading: false
        })
      }
    }

  }

  _resetPagination = () => {
    const { courses, pagination } = this.state;
    let reset_pagination = ((courses.length - 1) === 0);
    let n_page = (reset_pagination ? (pagination.page === 1 ? pagination.page : pagination.page - 1) : pagination.page);

    this.setState({
      pagination: {
        ...pagination,
        page: n_page,
      },
    }, () => {
      this._loadCourses();
    });
  }

  _loadCourses = () => {
    const { pagination } = this.state;

    this.props.getAllCoursesRequest({
      qs: _makeQueryString(pagination),
    });
  }

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        ...pagination,
        size,
        page,
      },
    }, () => this._loadCourses());
  }

  _handleSortByCourse = (sorter) => {
    const { pagination } = this.state;
    const sortData = {
      ...pagination,
      sort: sorter?.order
    }
    this.props.getAllCoursesRequest({
      qs: _makeQueryString(sortData),
    });
  }

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        size,
        page: 1,
      },
    }, () => this._loadCourses());
  }

  _handleRemoveCourse = (record) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to delete this course?',
      okText: 'Ok',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => {
        this.props.removeCourseRequest({ uuid: record.uuid });
      },
    });
  }

  _handleViewCourse = (record) => {
    const { csv_data } = record;
    if(Boolean(csv_data)){
      const column_array = [];
      const csv_parsed_data = JSON.parse(csv_data);
      
      const arrayColumn = (arr, n) => arr.map(data => data[n]);
      const length = csv_parsed_data.length > 0 ? csv_parsed_data[0].length : 0;

      for(var i=0; i < length; i++){
        column_array.push(arrayColumn(csv_parsed_data, i));
      }

      this.setState({
        csv_columns: null,
        csv_data: column_array,
        course: record,
        showViewCourseDrawer: true
      });
    }
    else{
      this.setState({
        course: record,
        showViewCourseDrawer: true
      });
    }
   
  }

  _handleEditCourse = (record) => {
    const dataToSend = {
      lastCourse: this.state.lastCourse?.order,
    };
    this.props.history.push(_.replace(_ROUTES.PATH_COURSES_EDIT, ':Id', record.uuid), dataToSend);
  }

  _handleTabChange = (key) => {
    const { course } = this.state;

    if (key === "tab_slides") {
      this.setState({
        slides_loading: true,
        slides: [],
      }, () => {
        this.props.getCourseMaterialSlidesRequest({ uuid: course.uuid });
      });
    }
  }

  _handleCloseDrawer = () => {
    this.setState({
      showViewCourseDrawer: false,
      csv_columns: [],
      csv_data: [],
    });
  }

  // EDIT SLIDE METHODS

  _handleEditSlide = (record) => {
    this.setState({
      showEditSlideModal: true,
      edit_slide_obj: {
        ...record,
        name: !_.isEmpty(record.name) ? record.name : '',
        tips: !_.isEmpty(record.tips) ? record.tips : '',
      },
    });
  }

  _handleDeleteSlide = (record) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to delete this slide?',
      okText: 'Ok',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => {
        this.props.removeCourseSlideRequest({ uuid: record.course_uuid, slide_uuid: record.uuid });
      },
    });
  }
  _handleEditFileUpload = (upload) => {
    this.setState({
      fileListEdit: [upload.file],
    });
    upload.onSuccess("ok");
  }

  _handleEditAudioUpload = (upload) => {
    this.setState({
      audioList: [upload.file],
    });
    upload.onSuccess("ok");
  }

  _handleFileBeforeUpload = (file, fileListEdit) => {
    const reader = new FileReader();

    reader.onload = e => {
      file.encoded_base64_data = e.target.result;

      this.setState({
        fileListEdit,
      });
    };
    reader.readAsDataURL(file);

    // Prevent upload
    return false;
  }

  _handleEditFileRemove = file => {
    this.setState({
      fileListEdit: [],
    });
  }

  _handleEditAudioRemove = file => {
    this.setState({
      audioList: [],
    });
  }

  _handleEditAudioDelete = file => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to delete this audio?',
      okText: 'Ok',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => {
        this.setState({
          audioList: [],
          isAudioDeleted: true
        });
      },
    });
  }

  _handleEditSlideFormFinish = (values) => {
    const { fileListEdit, edit_slide_obj, audioList, isAudioDeleted } = this.state;

    var formData = new FormData();
    formData.append('name', values.name);
    formData.append('tips', values.tips);

    if (fileListEdit.length) formData.append('image', fileListEdit[0]);
    if (audioList.length) formData.append('audio', audioList[0]);

    this.setState({ edit_slide_loading: true }, async () => {
      this.props.editCourseSlideRequest({
        uuid: edit_slide_obj.course_uuid,
        slide_uuid: edit_slide_obj.uuid,
        data: formData
      });
      if (isAudioDeleted) {
        await this.props.removeSlideAudioRequest({
          uuid: edit_slide_obj.course_audio.uuid,
        });
      }
    });
  }

  _handleCloseEditSlideModal = () => {
    this.setState({
      showEditSlideModal: false,
      fileListEdit: [],
      audioList: []
    });
  }

  _handleAddSlide = (record) => {
    this.props.history.push(_.replace(_ROUTES.PATH_COURSES_ADD_SLIDES, ':Id', record.uuid));
  }

  // SLIDE SHOW
  _handleViewSlideShow = (record) => {
    this.setState({
      course: record,
      showSlidesModal: true,
      slide_show_loading: true,
      current_slide_show_index: 0,
    }, () => {
      this.props.getCourseMaterialSlidesRequest({ uuid: record.uuid });
    });
  }

  _handleCloseSlideShow = () => {
    this.setState({
      course: {},
      showSlidesModal: false,
      current_slide_show_index: 0,
      slides: [],
    });
  }

  _nextSlide = () => {
    this.sliderRef.next();
  }

  _prevSlide = () => {
    this.sliderRef.prev();
  }

  _getCourseTypeText = (course_type) => {
    try {
      return `${_.find(_COURSE_TYPES, ct => { return ct.value === course_type }).text}`;
    } catch {
      return null;
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { slides, course } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(slides), oldIndex, newIndex).filter(el => !!el);

      this.setState({
        slides: newData,
        slides_loading: true,
      }, () => {
        this.props.updateSlideIndexRequest({
          uuid: course.uuid,
          data: {
            courseMaterialSlides: _.map(newData, (slide, index) => {
              return {
                uuid: slide.uuid,
                seq: index + 1,
              }
            })
          }
        });
      });
    }
  };

  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { slides } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = slides.findIndex(x => x.seq === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  render() {
    const {
      pagination, courses, course, slides, total_records, slides_loading, edit_slide_loading,
      edit_slide_obj, fileListEdit, showViewCourseDrawer, showEditSlideModal, showSlidesModal,
      current_slide_show_index, slide_show_loading, audioList, isAudioDeleted
    } = this.state;

    const img_obj = fileListEdit.length ? {
      src: fileListEdit[0].encoded_base64_data,
      name: fileListEdit[0].name,
    } : {
        src: `data:${edit_slide_obj.content_type};base64,${edit_slide_obj.encoded_base64_data}`,
        name: edit_slide_obj.name,
      };

    let COLUMNS = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Course Type, Level, Unit, Lesson',
        render: (text, record) => (
          <div>{`${this._getCourseTypeText(record.type)}, ${record.level}, ${record.unit}, ${record.lesson}`}</div>
        ),
      },
      {
        title: 'Order',
        key: 'order',
        dataIndex: 'order',        
        sorter: () => {},
      },
      {
        title: 'Status',
        align: "center",
        render: (text, record) => (
          <Tag color={record.is_active ? `#87d068` : `#f50`}>
            <span className="text-capitalize">{record.is_active ? "ACTIVE" : "INACTIVE"}</span>
          </Tag>
        ),
      },
      {
        title: 'Action',
        align: 'center',
        width: '18%',
        render: (text, record) => (
          <div className="sk-button-group">
            <Tooltip placement="top" title="Slide Show">
              <Button type="default" size="small" onClick={() => this._handleViewSlideShow(record)}><AppstoreOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="Add Slide">
              <Button type="default" size="small" onClick={() => this._handleAddSlide(record)}><FileAddOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="Edit">
              <Button type="default" size="small" onClick={() => this._handleEditCourse(record)}><EditOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="View">
              <Button type="default" size="small" onClick={() => this._handleViewCourse(record)}><EyeOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="Remove">
              <Button type="danger" size="small" onClick={() => this._handleRemoveCourse(record)}><DeleteOutlined /></Button>
            </Tooltip>
          </div>
        ),
      }
    ];

    let COLUMNS_SLIDES = [
      {
        title: 'Sort',
        dataIndex: 'sort',
        key: 'seq',
        width: 40,
        align: 'center',
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: 'Slide',
        className: 'drag-visible',
        render: (text, record) => (
          <Image
            width={100}
            height={100}
            src={`data:${record.content_type};base64,${record.encoded_base64_data}`}
            alt={record.name}
          />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Tips',
        dataIndex: 'tips'
      },
      {
        title: 'Action',
        align: 'center',
        width: '13%',
        render: (text, record) => (
           <>
           <div className="slide-action-button">
          <Tooltip placement="top" title="Edit Slide">
            <Button type="default" size="small" onClick={() => this._handleEditSlide(record)}><EditOutlined /></Button>
          </Tooltip>
          <Tooltip placement="top" title="Remove Slide">
            <Button type="danger" size="small" onClick={() => this._handleDeleteSlide(record)}><DeleteOutlined /></Button>
          </Tooltip>
          </div>
        </>
        ),
      }
    ];

    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
    const dataToSend = {
      lastCourse: this.state.lastCourse?.order,
    };
    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Courses'}
          extra={<div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(_ROUTES.PATH_COURSES_ADD, dataToSend)}>Create</Button>
          </div>}>
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive"
              columns={COLUMNS}
              dataSource={courses}
              onChange={(pagination, filters, sorter, extra) => this._handleSortByCourse(sorter)}
              pagination={false}
            />
            {(!_.isEmpty(courses)) && <div className="sk-pagination">
              <Pagination
                current={pagination.page}
                onChange={this._handlePageChange}
                pageSize={pagination.size}
                pageSizeOptions={_PAGESIZE}
                showSizeChanger={true}
                onShowSizeChange={this._handlePageSizeChange}
                total={total_records}
              />
            </div>}
          </div>
        </Card>

        {showViewCourseDrawer && <Drawer
          title={'View Course'}
          className="sk-drawer"
          closable={true}
          onClose={this._handleCloseDrawer}
          visible={showViewCourseDrawer}
        >
          <Form layout='vertical'>
            <Tabs defaultActiveKey="1" onChange={this._handleTabChange}>
              <Tabs.TabPane tab="Details" key="tab_details">
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item label="UUID">
                      {_.get(course, 'uuid', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Name">
                      {_.get(course, 'name', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Description">
                      {_.get(course, 'description', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Course Type">
                      {this._getCourseTypeText(course.type)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Level">
                      {_.get(course, 'level', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Unit">
                      {_.get(course, 'unit', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Lesson">
                      {_.get(course, 'lesson', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Order">
                      {_.get(course, 'order', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Status">
                    <Tag
                      color={course.is_active ? `#87d068` : `#f50`}
                    >
                      <span className="text-capitalize">{course.is_active ? "ACTIVE" : "INACTIVE"}</span>
                    </Tag>
                  </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Last Modified At">
                      {_formatDateTime(course.last_modified_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Added At">
                      {_formatDateTime(course.added_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Inserted At">
                      {_formatDateTime(course.inserted_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Updated At">
                      {_formatDateTime(course.updated_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Material Slides" key="tab_slides">
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Table
                      rowKey="seq"
                      loading={slides_loading}
                      bordered
                      size="small"
                      className="sk-table-responsive"
                      columns={COLUMNS_SLIDES}
                      dataSource={slides}
                      pagination={false}
                      components={{
                        body: {
                          wrapper: DraggableContainer,
                          row: this.DraggableBodyRow,
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              {this.state.csv_data.length > 0 && (
                <Tabs.TabPane tab="Report Card Summary" key="tab_csv_data"> 
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                      {
                         this.state.csv_data.map((item, index) => {
                          return (
                              <div className="report_cell" key={`key-${index}`}>
                                <div className="report_cell_header">
                                    {item[0]}
                                 </div>
                                  <div className="column_data_holder">
                                    {item.slice(1).map((suItem) => {
                                      if(Boolean(suItem)) {
                                        return  (<span>{suItem}</span>)
                                      } 
                                    })}
                                </div>
                            </div>
                          );
                        })
                       }
                    </Col>
                  </Row>
              </Tabs.TabPane>
              )}
            </Tabs>
          </Form>
        </Drawer>}

        {showEditSlideModal && <Modal
          visible={showEditSlideModal}
          title="Edit Slide"
          style={{top: 30}}
          maskClosable={false}
          footer={null}
          onCancel={this._handleCloseEditSlideModal}
        >
          <div>
            <Form
              layout='vertical'
              onFinish={this._handleEditSlideFormFinish}
              initialValues={{
                name: edit_slide_obj.name,
                tips: edit_slide_obj.tips
              }}>
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} className="text-center">
                  <Image
                    width={150}
                    height={150}
                    src={img_obj.src}
                    alt={img_obj.name}
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item name="name" label="Slide Name">
                    <Input size="large" placeholder="Slide Name" />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item name="tips" label="Tips">
                    <Input.TextArea
                      size="large"
                      placeholder="Tips"
                      autoSize={{ minRows: 2, maxRows: 3 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} sm={{ span: 12 }}>
                  <Upload
                    name="image"
                    accept=".jpg,.jpeg,.png"
                    multiple={false}
                    fileList={fileListEdit}
                    customRequest={this._handleEditFileUpload}
                    beforeUpload={this._handleFileBeforeUpload}
                    onRemove={this._handleEditFileRemove}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload Image</Button>
                  </Upload>
                </Col>
                <Divider />
                {edit_slide_obj.course_audio && !isAudioDeleted &&
                  <>
                    <Col xs={{ span: 24 }} sm={{ span: 17 }}>
                      <Form.Item label="Audio">
                        <audio controls src={`data:audio/mp3;base64,`+edit_slide_obj.course_audio.audio_data} />
                      </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 4 }} style={{ alignSelf: "center" }}>
                      <Tooltip placement="top" title={edit_slide_obj.course_audio.audio_name}>
                        {edit_slide_obj.course_audio.audio_name.substring(0,9)}
                      </Tooltip>
                    </Col>
                    <Col xs={{ span: 12 }} sm={{ span: 2 }} style={{ alignSelf: "center" }}>
                      <Tooltip placement="top" title="Remove Audio">
                        <Button type="danger" size="small" onClick={this._handleEditAudioDelete}><DeleteOutlined /></Button>
                      </Tooltip>
                    </Col>
                  </>
                }
                <Col xs={{ span: 12 }} sm={{ span: 12 }}>
                  <Upload
                    name="audio"
                    accept=".mp3"
                    multiple={false}
                    fileList={audioList}
                    beforeUpload={false}
                    customRequest={this._handleEditAudioUpload}
                    onRemove={this._handleEditAudioRemove}
                  >
                    <Button icon={<UploadOutlined />}>Click to Upload Audio</Button>
                  </Upload>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <div className="sk-button-group" style={{ textAlign: 'right', marginTop: "15px" }}>
                    <Button onClick={this._handleCloseEditSlideModal}>Cancel</Button>
                    <Button key="submit" type="primary" htmlType="submit" loading={edit_slide_loading}>Change</Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Modal>}

        {showSlidesModal && <Modal
          visible={showSlidesModal}
          title={`View Slides (${_.get(course, 'name', '')})`}
          width="45%"
          footer={slides.length !== 0 ? [
            <Button key="prev" onClick={this._prevSlide} disabled={current_slide_show_index === 0}>
              <LeftOutlined /> Prev
            </Button>,
            <Button key="next" onClick={this._nextSlide} disabled={current_slide_show_index === slides.length - 1}>
              Next <RightOutlined />
            </Button>,
          ] : null}
          onCancel={this._handleCloseSlideShow}
        >
          <div>
            {!slide_show_loading ? <Form>
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} className="course-slide-show">
                  {slides.length ? (<Carousel
                    dots={false}
                    ref={node => (this.sliderRef = node)}
                    afterChange={(current) => this.setState({ current_slide_show_index: current })}
                  >
                    {_.map(slides, (slide, key) => {
                      return (
                        <div key={key}>
                          <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                              <Form.Item label="Slide Name">
                                {slide.name}
                              </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                              <Form.Item label="Tips">
                                {slide.tips}
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} className="text-center">
                              <img
                                src={`data:${slide.content_type};base64,${slide.encoded_base64_data}`}
                                alt={slide.name}
                              />
                            </Col>
                          </Row>
                        </div>
                      )
                    })}
                  </Carousel>) : <div><Tag color="magenta">No Slides Found</Tag></div>}
                </Col>
              </Row>
            </Form> : <div className="text-center"><Spin /></div>}
          </div>
        </Modal>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, 'course.list', {}),
  remove: _.get(state, 'course.remove', {}),
  material_slides: _.get(state, 'course.material_slides', {}),
  update_slide_index: _.get(state, 'course.update_slide_index', {}),
  edit_slide: _.get(state, 'course.edit_slide', {}),
  remove_slide: _.get(state, 'course.remove_slide', {} )
});

const mapDispatchToProps = (dispatch) => ({
  getAllCoursesRequest: payload => dispatch({ type: reduxActions.GET_ALL_COURSES_REQUEST, payload }),
  removeCourseRequest: payload => dispatch({ type: reduxActions.REMOVE_COURSE_REQUEST, payload }),
  getCourseMaterialSlidesRequest: payload => dispatch({ type: reduxActions.GET_COURSE_MATERIAL_SLIDES_REQUEST, payload }),
  updateSlideIndexRequest: payload => dispatch({ type: reduxActions.UPDATE_SLIDE_INDEX_REQUEST, payload }),
  editCourseSlideRequest: payload => dispatch({ type: reduxActions.EDIT_COURSE_SLIDE_REQUEST, payload }),
  removeSlideAudioRequest: payload => dispatch({ type: reduxActions.REMOVE_SLIDE_AUDIO_REQUEST, payload }),
  removeCourseSlideRequest: payload => dispatch({ type: reduxActions.REMOVE_COURSE_SLIDE_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesList);
