import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_STUDENT_ACCOUNTS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_STUDENT_ACCOUNTS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const view = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_STUDENT_ACCOUNT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_STUDENT_ACCOUNT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const edit = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.EDIT_STUDENT_SUCCESS:
      return {
        result: action.payload.response,
      };
    case reduxActions.EDIT_STUDENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const csv_export = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REPORT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REPORT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const student_course = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.STUDENT_SELECTED_COURSE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.STUDENT_SELECTED_COURSE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const deactivate = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.DEACTIVATE_STUDENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.DEACTIVATE_STUDENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  list,
  view,
  edit,
  csv_export,
  deactivate,
  student_course
});
