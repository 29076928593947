import { API } from './API';

// fetch all teacher accounts
export function getAllTeacherAccounts(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/teachers?${req ? req.qs : ''}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// fetch single teacher account
export function getTeacherAccount(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/teachers/${req.uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// create teacher account
export function createTeacherAccount(req) {
  return new Promise((resolve, reject) => {
    return API('POST', `admin/teachers`, req).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// update teacher account
export function editTeacherAccount(req) {
  return new Promise((resolve, reject) => {
    return API('PUT', `admin/teachers/${req.uuid}`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}


