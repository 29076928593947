import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  InputNumber,
  Select,
  Radio,
  Input,
} from "antd";
import { SendOutlined } from "@ant-design/icons";
import { _ROUTES, _CONST, _COURSE_TYPES } from "../../constants/GlobalConst";
import { _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

const { TextArea } = Input;

class CreditAssign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      all_students: [],
      remarks: "",
      creditTypes: "Regular",
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.props.getAllStudentAccountsRequest({
      qs: 'cols={"account":["uuid","email"],"profile":["fullname"]}',
    });
  }

  componentDidUpdate(prevProps) {
    const { assign, students, history } = this.props;

    if (prevProps.assign !== assign) {
      const { result } = assign;
      if (result.success) {
        _notify({
          type: "success",
          description: "Credit is successfully assigned",
        });
        history.push(_ROUTES.PATH_CREDITS);
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }

    if (prevProps.students !== students) {
      const { result } = students;
      if (result.success) {
        this.setState({
          all_students: _.get(result, "response.data", []),
        });
      } else {
        _notify({
          type: "error",
          description: _.get(result, "response.message", ""),
        });
      }
    }
  }

  _handleFormFinish = (values) => {
    this.props.assignCreditRequest({ data: values });
  };

  onRemarkChange = (event) => {
    this.setState({ remarks: event.target.value });
  };

  handleCreditTypeChange = (value) => {
    this.setState({ creditTypes: value });
  };

  render() {
    const { all_students } = this.state;

    return (
      <React.Fragment>
        <Card className="sk-card" title={"Assign Credit"}>
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this._handleFormFinish}
            initialValues={{ student_account_uuid: undefined }}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="student_account_uuid"
                  label="Student Account"
                  rules={[
                    { required: true, message: "Student Account is required" },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    allowClear
                    placeholder="Select Student Account"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const result = _.filter(
                        option.children,
                        function (value) {
                          return _.includes(
                            value.props.children &&
                              value.props.children.toLowerCase(),
                            input.toLowerCase()
                          );
                        }
                      );
                      return result.length;
                    }}
                  >
                    {_.map(all_students, (student, key) => {
                      return (
                        <Select.Option
                          value={student.uuid}
                          key={key}
                          className="sk-opt-rows"
                        >
                          <div className="row1">
                            {_.get(student, "profile.fullname", "")}
                          </div>
                          <div className="row2">
                            {_.get(student, "email", "")}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="number_of_credits_regular"
                  label="Number of Credits (Regular)"
                  rules={[
                    {
                      required: true,
                      message: "Number of Credits (Regular) is required",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Number of Credits (Regular)"
                    className="full-width"
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="number_of_credits_marketing"
                  label="Number of Credits (Marketing)"
                  rules={[
                    {
                      required: true,
                      message: "Number of Credits (Marketing) is required",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Number of Credits (Marketing)"
                    className="full-width"
                    maxLength={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="remarks" label="Type Remarks">
                  <TextArea
                    placeholder="Add remarks for credit types"
                    allowClear
                    onChange={this.onRemarkChange}
                    value={this.state.remarks}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="sk-button-group">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<SendOutlined />}
                  >
                    Assign
                  </Button>
                  <Button
                    size="large"
                    onClick={() =>
                      this.props.history.push(_ROUTES.PATH_CREDITS)
                    }
                  >
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  students: _.get(state, "student.list", {}),
  assign: _.get(state, "credit.assign", {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllStudentAccountsRequest: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_REQUEST, payload }),
  assignCreditRequest: (payload) =>
    dispatch({ type: reduxActions.ASSIGN_CREDIT_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditAssign);
