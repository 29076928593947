import { notification } from 'antd';
import _ from 'lodash';
import moment from "moment-timezone";
import { _CONST } from '../constants/GlobalConst';

/* eslint-disable-next-line */
export const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

// antd notification function
export function _notify(config) {
  try {
    return notification[config.type]({
      message: (config.message || _.startCase(config.type)),
      description: config.description,
      duration: _.has(config, 'duration') ? config.duration : _CONST.NOTIFICATION_TIMEOUT,
      className: `notify__${config.type}`
    });
  } catch {
    return null;
  }
}

// return query string from object
export function _makeQueryString(data) {
  let queryString = '';
  try {
    return _.map((Object.keys(data) || []), key => {
      return key + '=' + data[key];
    }).join('&');
  } catch (error) {
    queryString = '';
  }

  return queryString;
}

// convert and format datetime with timezone
export function _formatDateTime(date_time, date_format) {
  try {
    return date_time ? moment(date_time).tz(_CONST.TIMEZONE).format(date_format) : 'N/A';
  } catch (error) {
    return 'N/A';
  }
}
