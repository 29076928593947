import { all } from "redux-saga/effects";
import authSagas from "./auth.saga";
import teacherSagas from "./teacher.saga";
import adminSagas from "./admin.saga";
import studentSagas from "./student.saga";
import packageSagas from "./package.saga";
import courseSagas from "./course.saga";
import creditSagas from "./credit.saga";
import roomSagas from "./room.saga";
import eventSagas from "./event.saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    teacherSagas(),
    adminSagas(),
    studentSagas(),
    packageSagas(),
    courseSagas(),
    creditSagas(),
    roomSagas(),
    eventSagas()
  ]);
}
