import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Card, Drawer, Button, Tooltip, Table, Row, Col, Form, Pagination, Modal } from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  ExportOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { _ROUTES, _PAGESIZE, _DEFAULT_ROWS_PER_PAGE, _CONST, _ENGLISH_EXPOSURE_LEVEL } from '../../constants/GlobalConst';
import { _makeQueryString, _formatDateTime, _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class StudentsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      cols: '{"account":["uuid","email","phone_number","receive_sms", "deactivate_request","deactivated_at"],"profile":["fullname"],"wallets":["lesson_credits","regular_lesson_credits", "marketing_lesson_credits"]}',
      total_records: 0,
      students: [],
      student: {},
      showViewStudentDrawer: false,
    };
  }

  componentDidMount() {
    this._loadStudents();
  }

  componentDidUpdate(prevProps) {
    const { list, view, csv_export, deactivate } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          students: _.get(result, 'response.data', []),
          total_records: _.get(result, 'response.pagination.totalItems', 0),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.view !== view) {
      const { result } = view;
      if (result.success) {
        this.setState({
          student: _.get(result, 'response.data', {}),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.csv_export !== csv_export) {
      const { result } = csv_export;
      if (result && result.success) {
        _notify({
          type: 'success', description: <div>
            <p>Please select & copy url down below to download the entire data after a minute once this CSV gets downloaded.</p>
            <br />
            <p>{result.response.data}</p>
            {/* <p>{process.env.REACT_APP_API_BASE_URL}/{result.response.data}</p> */}
          </div>, duration: 50
        })
        window.open(`${result.response.data}`)
        // window.open(`${process.env.REACT_APP_API_BASE_URL}/${result.response.data}`)
        // const url = window.URL.createObjectURL(new Blob([result.response]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `${Date.now()}_credit_report.csv`);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.deactivate !== deactivate) {
      const { result } = deactivate;
      if (result.success) {
        _notify({ type: 'success', description: 'Student Account is successfully deactivated' });
        this._resetPagination();
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
  }

  _resetPagination = () => {
    const { students, pagination } = this.state;
    let reset_pagination = ((students.length - 1) === 0);
    let n_page = (reset_pagination ? (pagination.page === 1 ? pagination.page : pagination.page - 1) : pagination.page);

    this.setState({
      pagination: {
        ...pagination,
        page: n_page,
      },
    }, () => {
      this._loadStudents();
    });
  }

  _loadStudents = () => {
    const { pagination, cols } = this.state;

    this.props.getAllStudentAccountsRequest({
      qs: `${_makeQueryString(pagination)}&cols=${cols}`,
    });
  }

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        ...pagination,
        size,
        page,
      },
    }, () => this._loadStudents());
  }

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        size,
        page: 1,
      },
    }, () => this._loadStudents());
  }

  _handleViewStudent = (record) => {
    this.setState({ showViewStudentDrawer: true }, () => {
      this.props.getStudentAccountRequest({ uuid: record.uuid });
    });
  }

  _handleEditStudent = (record) => {
    this.props.history.push(_.replace(_ROUTES.PATH_STUDENTS_EDIT, ':Id', record.uuid));
  }

  _getEnglishExposureLevelName = (level) => {
    try {
      return !_.isEmpty(level) ?
        _.find(_ENGLISH_EXPOSURE_LEVEL, eel => { return eel.value === level }).text
        : '';
    } catch {
      return '';
    }
  }

  _handleExport = async () => {
    this.props.getStudentReport();
  }

  _handleDeactivateStudent = (record) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to deactivate this student?',
      okText: 'Ok',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => {
        this.props.deactivateStudentAccountRequest({ uuid: record.uuid });
      },
    });
  }

  render() {
    const { pagination, students, student, total_records, showViewStudentDrawer } = this.state;
    let COLUMNS = [
      {
        title: 'Email',
        key: 'uuid',
        dataIndex: 'email'
      },
      {
        title: 'Full Name',
        dataIndex: ['profile', 'fullname']
      },
      {
        title: 'Phone #',
        dataIndex: 'phone_number'
      },
      {
        title: 'Regular Credits',
        dataIndex: ['wallet', 'regular_lesson_credits']
      }, {
        title: 'Marketing Credits',
        dataIndex: ['wallet', 'marketing_lesson_credits']
      },
      {
        title: 'Total Credits',
        dataIndex: ['wallet', 'lesson_credits']
      },
      {
        title: 'Receive SMS for Regular Class',
        dataIndex: "receive_sms",
        render: value => <>{value === "Yes" ? "On" : "Off"}</>,
      },
      {
        title: 'Deactivate Request',
        dataIndex: "deactivate_request",
      },
      {
        title: 'Deactivated',
        dataIndex: "deactivated_at",
        render: value => <>{value ? "Yes" : "No"}</>,
      },
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (text, record) => (
          <div className="sk-button-group">
            <Tooltip placement="top" title="Edit">
              <Button type="default" size="small" onClick={() => this._handleEditStudent(record)}><EditOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="View">
              <Button type="default" size="small" onClick={() => this._handleViewStudent(record)}><EyeOutlined /></Button>
            </Tooltip>
            {
              record.deactivate_request === "Yes" && !record.deactivated_at ?
                <Tooltip placement="top" title="Deactivate">
                  <Button type="danger" size="small" onClick={() => this._handleDeactivateStudent(record)}><DeleteOutlined /></Button>
                </Tooltip> : null
            }
          </div>
        ),
      }
    ];

    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Student Accounts'}
          extra={
            <div className="sk-button-group">
              <Button
                type="primary"
                icon={<ExportOutlined />}
                onClick={this._handleExport}
              >
                Export
              </Button>
            </div>
          }
        >
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive student-account-table"
              columns={COLUMNS}
              dataSource={students}
              pagination={false}
            />
            {(!_.isEmpty(students)) && <div className="sk-pagination">
              <Pagination
                current={pagination.page}
                onChange={this._handlePageChange}
                pageSize={pagination.size}
                pageSizeOptions={_PAGESIZE}
                showSizeChanger={true}
                onShowSizeChange={this._handlePageSizeChange}
                total={total_records}
              />
            </div>}
          </div>
        </Card>

        {showViewStudentDrawer && <Drawer
          title={'View Student Account'}
          className="sk-drawer"
          closable={true}
          onClose={() => this.setState({ showViewStudentDrawer: false })}
          visible={showViewStudentDrawer}
        >
          <Form layout='vertical'>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item label="UUID">
                  {_.get(student, 'uuid', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Email">
                  {_.get(student, 'email', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Phone Number">
                  {_.get(student, 'phone_number', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Full Name">
                  {_.get(student, 'profile.fullname', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Nick Name">
                  {_.get(student, 'profile.nickname', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Date of Birth">
                  {!_.isEmpty(_.get(student, 'profile.date_of_birth', '')) ? moment(_.get(student, 'profile.date_of_birth', '')).format(_CONST.DATE_FORMAT) : 'N/A'}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="English Exposure Level">
                  {this._getEnglishExposureLevelName(_.get(student, 'profile.english_exposure_level', ''))}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Receive SMS for Regular Class">
                  {_.get(student, 'receive_sms') === "Yes" ? "On" : "Off"}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Enable Screen Share for Teacher">
                  {_.get(student, 'screen_sharing_allowed') === true ? "On" : "Off"}
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Deactivate Request">
                  {_.get(student, 'deactivate_request', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Deactivated">
                  {_.get(student, 'deactivated_at') ? "Yes" : "No"}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Last Authenticated At">
                  {_formatDateTime(student.last_authenticated_at, _CONST.LONG_DATETIME_FORMAT)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Registered At">
                  {_formatDateTime(student.registered_at, _CONST.LONG_DATETIME_FORMAT)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Inserted At">
                  {_formatDateTime(student.inserted_at, _CONST.LONG_DATETIME_FORMAT)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item label="Updated At">
                  {_formatDateTime(student.updated_at, _CONST.LONG_DATETIME_FORMAT)}
                </Form.Item>
              </Col>
            </Row>
          </Form>

        </Drawer>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, 'student.list', {}),
  view: _.get(state, 'student.view', {}),
  csv_export: _.get(state, 'student.csv_export', {}),
  deactivate: _.get(state, 'student.deactivate', {})
});

const mapDispatchToProps = (dispatch) => ({
  getAllStudentAccountsRequest: payload => dispatch({ type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_REQUEST, payload }),
  getStudentAccountRequest: payload => dispatch({ type: reduxActions.GET_STUDENT_ACCOUNT_REQUEST, payload }),
  getStudentReport: payload => dispatch({ type: reduxActions.GET_ALL_STUDENT_CREDIT_REPORT, payload }),
  deactivateStudentAccountRequest: payload => dispatch({ type: reduxActions.DEACTIVATE_STUDENT_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);