import { API } from './API';

// fetch all sub admin accounts
export function getAllSubAdminAccounts(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/sub-admin?${req.qs}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// remove sub admin account
export function removeSubAdminAccount(req) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `admin/sub-admin/${req.uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// create admin/sub-admin account
export function createAdminAccount(req) {
  return new Promise((resolve, reject) => {
    return API('POST', `admin/account`, req).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// fetch all admin accounts
export function getAllAdminAccounts(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/account?${req.qs}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}