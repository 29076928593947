import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllLessonPackages,
  removeLessonPackage,
  createLessonPackage,
} from '../../services/package.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getLessonPackagesWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllLessonPackages, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_LESSON_PACKAGES_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_LESSON_PACKAGES_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeLessonPackageWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeLessonPackage, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_LESSON_PACKAGE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_LESSON_PACKAGE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* createLessonPackageWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(createLessonPackage, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CREATE_LESSON_PACKAGE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.CREATE_LESSON_PACKAGE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

export function* getLessonPackagesWatcher() {
  yield takeLatest(reduxActions.GET_ALL_LESSON_PACKAGES_REQUEST, getLessonPackagesWorker);
}

export function* removeLessonPackageWatcher() {
  yield takeLatest(reduxActions.REMOVE_LESSON_PACKAGE_REQUEST, removeLessonPackageWorker);
}

export function* createLessonPackageWatcher() {
  yield takeLatest(reduxActions.CREATE_LESSON_PACKAGE_REQUEST, createLessonPackageWorker);
}

export default function* rootSaga() {
  yield all([
    fork(getLessonPackagesWatcher),
    fork(removeLessonPackageWatcher),
    fork(createLessonPackageWatcher),
  ]);
}
