import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Route } from "react-router-dom";
import { Spin } from "antd";
import { _ROUTES } from "../../constants/GlobalConst";
class PrivateRoute extends React.Component {
  render() {
    const {
      component: Component,
      pageLoader,
      token,
      componentProps,
      allowSubAdmin,
      role,
      ...rest
    } = this.props;

    if (allowSubAdmin === false && role === "sub-admin") {
      window.location = _ROUTES.PATH_HOME;
      return null;
    }

    if (_.isEmpty(token)) {
      window.location = _ROUTES.PATH_LOGIN;
      return null;
    }

    if (token)
      return (
        <Route
          {...rest}
          render={(props) => (
            <div>
              <Spin
                spinning={pageLoader}
                wrapperClassName="spinner"
                style={{ zIndex: 150000 }}
              >
                <Component {...props} {...componentProps} />
              </Spin>
            </div>
          )}
        />
      );
  }
}

const mapStateToProps = (state) => ({
  token: _.get(state, "auth.login.result.response.token", ""),
  role: _.get(state, "auth.login.result.response.role", ""),
  pageLoader: _.get(state, "global.pageLoader.isVisible", false),
});

export default connect(mapStateToProps, null)(PrivateRoute);
