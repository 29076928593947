import React, { Component } from "react";
import { connect } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";
import moment from "moment";
import { AiOutlineBook, AiOutlineClose, AiOutlineExclamationCircle } from "react-icons/ai";
import * as reduxActions from "../../constants/ActionTypes";
import "./calendar.css";
import _ from "lodash";
import { _CONST } from "../../constants/GlobalConst";
import {
  Modal,
  Button,
  Input,
  DatePicker,
  Row,
  Col,
  Form,
  Radio,
  Select,
} from "antd";
let studentList,
  teacherList = [];

const { TextArea } = Input;

class index extends Component {
  showModal = (e) => {
    this.setState({
      show: !this.state.show,
    });
    if (this.state.show !== false) {
      this.setState({ updateMode: false });
      // Reset form when modal closes
      this.formRef.current.resetFields();
    }
  };

  onRemarkChange = (event) => {
    this.setState({ remarks: event.target.value });
  };

  handleChange = (value) => {
    this.setState({ eventTitle: value });
  };

  handleSubStateChange = (value) => {
    this.setState({ eventSubStatus: value });
  };

  handleOnChange = (value, event) => {
    // Clear events before fetching data
    this.setState({
      events: [],
    });

    // Call an API to fetch all events by specific teachers
    const { getAllEventByTeachers, getAllRoomSessionsByTeacher } = this.props;
    const payload = { id: value };
    this.setState({ currentSelectedTeacher: payload });
    getAllEventByTeachers(payload);
    getAllRoomSessionsByTeacher(payload);
  };

  handleCopyEventsFromLastWeek = () => {
    const { getAllEventByLastWeek } = this.props;
    if (!_.isEmpty(this.state.currentSelectedTeacher)) {
      const payload = {
        start: this.state.startLastWeekDay,
        end: this.state.endLastWeekDay,
        uuid: this.state.currentSelectedTeacher.id,
      };
      getAllEventByLastWeek(payload);
    } else {
      this.setState({ error: "Teacher has not been selected" });
    }
  };

  onDeleteEvent = () => {
    const { removeEventRequest } = this.props;
    removeEventRequest(this.state.eventID);
    this.setState({
      eventID: "",
      deleteMode: false,
      show: false,
    });
  };

  getRightContainerClass = (title, remark, sub_title) => {
    let classes = "";

    if (title === "Available") {
      classes = "event-card-available";
    } else if (sub_title === "Regular") {
      classes = "event-card-reserved";
    } else if (sub_title === "Trial") {
      classes = "event-card-trial";
    } else if (sub_title === "Special") {
      classes = "event-card-special";
    } else {
      classes = "event-card-scheduled";
    }

    if (Boolean(remark) === true) {
      classes += " event-note";
    }

    return classes;
  };

  constructor(props) {
    super(props);
    this.state = {
      events: [],
      eventID: "",
      showConfirmRemoveEventModal: false,
      show: false,
      available: false,
      remarks: "",
      updateMode: false,
      students: [],
      selectedStudent: {},
      teachers: [],
      selectedTeacher: {},
      currentSelectedTeacher: {},
      startLastWeekDay: null,
      endLastWeekDay: null,
      eventTitle: "",
      eventSubStatus: "",
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    const { getAllEvents, getAllStudentRequest, getAllTeacherRequest } =
      this.props;
    getAllEvents();
    getAllStudentRequest();
    getAllTeacherRequest();
  }

  componentDidUpdate(prevProps) {
    const {
      all_event,
      students,
      teachers,
      getAllEvents,
      getAllEventByTeachers,
      getAllRoomSessionsByTeacher,
      addBulkUserEvents,
      remove_event,
      add_event,
      add_bulk_event,
      update_event,
      all_event_by_teacher,
      get_all_sessions_by_teacher,
      all_event_by_last_week,
    } = this.props;

    /*
     * Update fetched last week events from current date in
     */
    if (prevProps.all_event_by_last_week !== all_event_by_last_week) {
      const { result } = all_event_by_last_week;
      if (result.success) {
        const lastWeekEventData = _.reverse(
          _.sortBy(_.get(result, "response.data.rows", []))
        );

        if (lastWeekEventData.length > 0) {
          let isEventAlreadyCopied = false;

          lastWeekEventData.forEach((event) => {
            var oldStartDate = new Date(event.start_date);
            var oldEndDate = new Date(event.end_date);
            var updatedStartDate = new Date(
              oldStartDate.setDate(oldStartDate.getDate() + 7)
            ).toISOString();
            var updateEndDate = new Date(
              oldEndDate.setDate(oldEndDate.getDate() + 7)
            ).toISOString();

            event.start_date = updatedStartDate;
            event.end_date = updateEndDate;
          });

          let stateEvents = this.state.events[0];

          for (let i = 0; i < stateEvents.length; i++) {
            if (
              lastWeekEventData[0].start_date === stateEvents[i].start &&
              lastWeekEventData[0].title === stateEvents[i].title
            ) {
              isEventAlreadyCopied = true;
              break;
            }
          }

          if (isEventAlreadyCopied !== true) {
            // Insert copied event to selected next week
            const payload = { events: lastWeekEventData };
            addBulkUserEvents(payload);
          }
        }
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    /*
     * Fetch all classroom sessions based on teacher selection
     */
    if (prevProps.get_all_sessions_by_teacher !== get_all_sessions_by_teacher) {
      const { result } = get_all_sessions_by_teacher;
      let allPrevListEvents = this.state.events;

      if (result.success) {
        let resultedData = _.reverse(
          _.sortBy(_.get(result, "response.data", []))
        );

        // Convert time into Hong Kong time zone
        resultedData.forEach((event) => {
          event.start = moment(event.start).tz(_CONST.TIMEZONE).format();
          event.end = moment(event.end).tz(_CONST.TIMEZONE).format();
        });

        let mergedData = [];

        if (resultedData.length > 0) {
          if (allPrevListEvents.length > 0) {
            mergedData[0] = allPrevListEvents[0].concat(resultedData);
          } else {
            mergedData[0] = resultedData;
          }
        } else {
          mergedData = allPrevListEvents;
        }

        this.setState({
          events: mergedData,
        });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    /*
     * Fetch all event based on teacher selection
     */
    if (prevProps.all_event_by_teacher !== all_event_by_teacher) {
      const { result } = all_event_by_teacher;
      if (result.success) {
        // Merge old events with newly fetched ones if existing evets are available
        let allPrevListEvents = this.state.events;

        let resultedData = _.reverse(
          _.sortBy(_.get(result, "response.data", []))
        );

        let mergedData = [];

        if (allPrevListEvents.length > 0) {
          // Keep all scheduled classroom sessions data if available
          let filterdEvents = allPrevListEvents[0].filter((event) => {
            return event.title === "Scheduled";
          });

          mergedData[0] = filterdEvents.concat(resultedData[0]);
        } else {
          mergedData = resultedData;
        }

        this.setState({
          events: mergedData,
        });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.update_event !== update_event) {
      const { result } = update_event;
      if (result.success) {
        /* Fetching udated events data based on cureent teacher selection */
        getAllEventByTeachers(this.state.currentSelectedTeacher);
        this.setState({ show: false });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.add_event !== add_event) {
      const { result } = add_event;
      if (result.success) {
        /* Fetching udated events data based on cureent teacher selection */
        getAllEventByTeachers(this.state.currentSelectedTeacher);
        this.setState({ show: false });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.add_bulk_event !== add_bulk_event) {
      const { result } = add_bulk_event;
      if (result.success) {
        /* Fetching udated events data based on cureent teacher selection */
        getAllEventByTeachers(this.state.currentSelectedTeacher);
        this.setState({ show: false });
      } else {
        this.setState({ error: _.get(result, "response.message", "") });
      }
    }

    if (prevProps.students !== students) {
      const { result } = students;
      if (result.success) {
        this.setState({ students: result.response.data });
        studentList = result.response.data.map((student) => {
          return (
            <option
              key={student.uuid}
              value={`${student.profile.fullname} (${student.profile.nickname})`}
            >
              {" "}
            </option>
          );
        });
      } else {
        this.setState({
          show: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
    if (prevProps.remove_event !== remove_event) {
      const { result } = remove_event;
      if (result.success) {
        this.setState({
          eventID: "",
          showConfirmRemoveEventModal: false,
        });
        /* Fetching udated events data based on cureent teacher selection */
        getAllEventByTeachers(this.state.currentSelectedTeacher);
      } else {
        this.setState({
          showConfirmRemoveEventModal: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
    if (prevProps.teachers !== teachers) {
      const { result } = teachers;

      if (result.success) {
        this.setState({ teachers: result.response.data });
        teacherList = result.response.data.map((teacher) => {
          return (
            <option key={teacher.uuid} value={`${teacher.profile.fullname}`}>
              {" "}
            </option>
          );
        });
      } else {
        this.setState({
          show: false,
          error: _.get(result, "response.message", ""),
        });
      }
    }
  }

  populateStudentOrTeacherName = (props, title) => {
    if (props.account !== null && title === "Reserved") {
      return props.account.profile.fullname;
    } else {
      return props.teacher_account.profile.fullname;
    }
  };

  removeButton = (args, element) => {
    return (
      <div
        data-id={args.event.extendedProps.uuid}
        className={this.getRightContainerClass(
          args.event.title,
          args.event.extendedProps.remarks,
          args.event.extendedProps.sub_title
        )}
      >
        {args.event.extendedProps.teacher_account && (
          <div
            className="event_inner_detail tooltip"
            onClick={() => {
              if (args.event.title !== "Scheduled") {
                this.updateDate(args.event);
              }
            }}
          >
            <Row justify={'space-between'} wrap={false}>
                {this.populateStudentOrTeacherName(
                  args.event.extendedProps,
                  args.event.title
                )}
                {Boolean(args.event.extendedProps.remarks) === true ? 
                  <AiOutlineExclamationCircle fontSize={22} color={'#ffdd57'}  /> : null} 
            </Row> 
            <p>
              {args.event.title === "Scheduled"
                ? `${moment(args.event.start)
                    .tz(_CONST.TIMEZONE)
                    .format(_CONST.CLASSROOM_TIME)} - ${moment(args.event.end)
                    .tz(_CONST.TIMEZONE)
                    .format(_CONST.CLASSROOM_TIME)}`
                : `${moment(args.event.start).format("hh:mm")} - ${
                    moment(args.event.start).format("YY-MM-DD") ==
                    moment(args.event.end).format("YY-MM-DD")
                      ? moment(args.event.end).format("hh:mm")
                      : "12:00"
                  }`}
            </p>
            {Boolean(args.event.extendedProps.remarks) === true ? (
              <p className="note-text" title={args.event.extendedProps.remarks}>
                {args.event.extendedProps.remarks}
              </p>
            ) : null}
            <span className="tooltiptext">
              <p>
                {this.populateStudentOrTeacherName(
                  args.event.extendedProps,
                  args.event.title
                )}
              </p>
              <p>
                {`(${moment(args.event.start).format("hh:mm")} - ${
                  moment(args.event.start).format("YY-MM-DD") ==
                  moment(args.event.end).format("YY-MM-DD")
                    ? moment(args.event.end).format("hh:mm")
                    : "12:00"
                })`}
              </p>
              {Boolean(args.event.extendedProps.remarks) === true ? (

              <p className="remark-text" title={args.event.extendedProps.remarks}> 
                 {args.event.extendedProps.remarks.length < 50 ? 
                    args.event.extendedProps.remarks : 
                      args.event.extendedProps.remarks.slice(0, 50) + '...'} 
              </p>

            ) : null} 
            </span>
          </div>
        )}
        {/*args.event.title !== "Scheduled" ? (
          <div
            className="close_event"
            onClick={() => this.deleteEvent(args.event.extendedProps.uuid)}
          >
            <AiOutlineClose style={{ marginTop: "2px" }} />
          </div>
        ) : null*/}
      </div>
    );
  };

  resetForm = () => {
    this.setState({
      remarks: "",
      eventTitle: "Reserved",
      eventSubStatus: "Regular",
    });
  };

  _onAdd = (values) => {
    const { addUserEvents } = this.props;
    const payload = {
      title: values.status,
      start_date: new Date(values.startDate),
      end_date: new Date(values.endDate),
      teacher_uuid: values.teacher_account_uuid,
      remarks: this.state.remarks,
      sub_title: this.state.eventSubStatus,
    };
    if (this.state.selectedStudent) {
      payload.student_uuid = values.student_account_uuid;
    }
    addUserEvents(payload);
    this.resetForm();
  };

  _onUpdate = (values) => {
    const { updateEventRequest } = this.props;

    let payload = {
      title: values.status,
      remarks: values.event_remarks,
      sub_title: values.sub_status,
      start_date: new Date(values.startDate),
      end_date: new Date(values.endDate),
      uuid: this.state.eventID,
    };
    if (this.state.selectedStudent) {
      payload.student_uuid = values.student_account_uuid;
    }
    if (this.state.selectedTeacher) {
      payload.teacher_uuid = values.teacher_account_uuid;
    }

    updateEventRequest(payload);
    this.resetForm();
  };

  _handleRemoveEventModalSubmit = () => {
    this.props.removeEventRequest({
      event_id: this.state.eventID,
    });
  };

  OnupdateEvent = (value) => {
    const { updateEventRequest } = this.props;

    let payload = {
      start_date: new Date(value.event.start),
      end_date: new Date(value.event.end),
      uuid: value.event.extendedProps.uuid,
    };
    updateEventRequest(payload);
  };

  render() {
    const { teachers } = this.state;
    return (
      <div
        className="container calendar-font"
        style={{
          padding: "25px",
          backgroundColor: "white",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div className="top-bar-container">
          <Select
            size="large"
            showSearch
            allowClear
            placeholder="Select teacher account to see scheduled sessions"
            optionFilterProp="children"
            onSelect={(value, event) => this.handleOnChange(value, event)}
            filterOption={(input, option) => {
              const result = _.filter(option.children, function (value) {
                return _.includes(
                  value.props.children && value.props.children.toLowerCase(),
                  input.toLowerCase()
                );
              });
              return result.length;
            }}
            style={{ zIndex: 20, width: "400px", height: "43px" }}
          >
            {_.map(teachers, (teacher, key) => {
              return (
                <Select.Option
                  value={teacher.uuid}
                  key={key}
                  className="sk-opt-rows"
                >
                  <div className="row1">
                    {_.get(teacher, "profile.fullname", "")}
                  </div>
                  <div className="row2">{_.get(teacher, "email", "")}</div>
                </Select.Option>
              );
            })}
          </Select>
          <Button
            type="primary"
            htmlType="button"
            size="large"
            onClick={this.handleCopyEventsFromLastWeek}
            disabled={
              _.isEmpty(this.state.currentSelectedTeacher) ? "disabled" : ""
            }
          >
            COPY EVENTS FROM LAST WEEK
          </Button>
        </div>
        <FullCalendar
          eventContent={this.removeButton}
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            listPlugin,
            momentPlugin,
          ]}
          contentHeight={650}
          aspectRatio={2}
          expandRows={true}
          initialView={"timeGridWeek"}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
          }}
          selectable={true}
          selectMirror={true}
          events={this.state.events[0]}
          editable={true}
          eventDurationEditable={true}
          eventResizableFromStart={true}
          select={this.selectedDate}
          eventDrop={this.OnupdateEvent}
          eventResize={this.OnupdateEvent}
          eventColor={"#ffff"}
          slotDuration={"00:15:00"}
          scrollTime={"09:00:00"}
          slotMinTime={"09:00:00"}
          slotMaxTime={"24:00:00"}
          datesSet={(args) => {
            var start = new Date(args.start);
            var end = new Date(args.end);
            this.setState({
              startLastWeekDay: new Date(
                start.setDate(start.getDate() - 7)
              ).toISOString(),
              endLastWeekDay: new Date(
                end.setDate(end.getDate() - 7)
              ).toISOString(),
            });
          }}
        />
        <Modal
          visible={this.state.show}
          footer={null}
          header={null}
          onCancel={this.showModal}
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this.state.updateMode ? this._onUpdate : this._onAdd}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  rules={[
                    { required: true, message: "Start Date is required" },
                  ]}
                >
                  <DatePicker
                    size="large"
                    className="full-width"
                    format="YYYY-MM-DD hh:mm A"
                    id="startDatePicker"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="endDate"
                  label="End Date"
                  rules={[{ required: true, message: "End Date is required" }]}
                >
                  <DatePicker
                    size="large"
                    className="full-width"
                    format="YYYY-MM-DD hh:mm A"
                    id="startDatePicker"
                    showTime
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} style={{ textAlign: "left" }}>
                <Form.Item
                  name="status"
                  label="Status"
                  rules={[
                    { required: true, message: "Please select a Status" },
                  ]}
                >
                  <Radio.Group
                    options={[
                      { label: "Available", value: "Available" },
                      { label: "Reserved", value: "Reserved" },
                    ]}
                    onChange={({ target: { value } }) =>
                      this.handleChange(value)
                    }
                    value={this.state.eventTitle}
                  />
                </Form.Item>
              </Col>
            </Row>
            {this.state.eventTitle == "Reserved" && (
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 24 }} style={{ textAlign: "left" }}>
                  <Form.Item
                    name="sub_status"
                    label="Select Substatus"
                    rules={[
                      { required: true, message: "Please select a Substatus" },
                    ]}
                  >
                    <Radio.Group
                      options={[
                        { label: "Regular", value: "Regular" },
                        { label: "Trial", value: "Trial" },
                        { label: "Special", value: "Special" },
                      ]}
                      onChange={({ target: { value } }) =>
                        this.handleSubStateChange(value)
                      }
                      value={this.state.eventSubStatus}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item
                  name="teacher_account_uuid"
                  label="Teacher Account"
                  rules={[
                    { required: true, message: "Teacher Account is required" },
                  ]}
                >
                  <Select
                    size="large"
                    showSearch
                    allowClear
                    placeholder="Select Teacher Account"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      const result = _.filter(
                        option.children,
                        function (value) {
                          return _.includes(
                            value.props.children &&
                              value.props.children.toLowerCase(),
                            input.toLowerCase()
                          );
                        }
                      );
                      return result.length;
                    }}
                  >
                    {_.map(this.state.teachers, (teacher, key) => {
                      return (
                        <Select.Option
                          value={teacher.uuid}
                          key={key}
                          className="sk-opt-rows"
                        >
                          <div className="row1">
                            {_.get(teacher, "profile.fullname", "")}
                          </div>
                          <div className="row2">
                            {_.get(teacher, "email", "")}
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {this.state.eventTitle == "Reserved" && (
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item
                    name="student_account_uuid"
                    label="Student Account"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            getFieldValue("status") === "Reserved" &&
                            (getFieldValue("sub_status") === "Trial" ||
                              getFieldValue("sub_status") === "Special") || value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject([
                            "Student Account is required",
                          ]);
                        },
                      }),
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      allowClear
                      placeholder="Select Student Account"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        const result = _.filter(
                          option.children,
                          function (value) {
                            return _.includes(
                              value.props.children &&
                                value.props.children.toLowerCase(),
                              input.toLowerCase()
                            );
                          }
                        );
                        return result.length;
                      }}
                    >
                      {_.map(this.state.students, (student, key) => {
                        return (
                          <Select.Option
                            value={student.uuid}
                            key={key}
                            className="sk-opt-rows"
                          >
                            <div className="row1">
                              {_.get(student, "profile.fullname", "")}
                            </div>
                            <div className="row2">
                              {_.get(student, "email", "")}
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={[16, 0]}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Form.Item name="event_remarks" label="Add Remarks">
                  <TextArea
                    placeholder="Add remarks to an event"
                    allowClear
                    onChange={this.onRemarkChange}
                    value={this.state.remarks}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                style={{ textAlign: "right" }}
              >
                <Button key="back" size="large" onClick={this.showModal}>
                  Cancel
                </Button>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Button type="primary" htmlType="submit" size="large">
                  {this.state.updateMode ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }

  updateDate = (event) => {
    this.setState(
      {
        eventID: event.extendedProps.uuid,
        updateMode: true,
        show: true,
        eventTitle: event.title,
      },
      () => {
        this.formRef.current.setFieldsValue({
          startDate: moment(event.start),
          endDate: moment(event.end),
          status: event.title,
          event_remarks: event.extendedProps.remarks,
          sub_status: event.extendedProps.sub_title,
          student_account_uuid: event.extendedProps.account
            ? event.extendedProps.account.uuid
            : "",
          teacher_account_uuid: event.extendedProps.teacher_account.uuid,
        });
      }
    );
  };

  deleteEvent = (uuid) => {
    this.setState({
      showConfirmRemoveEventModal: true,
      eventID: uuid,
    });
    Modal.confirm({
      title: "Are you sure delete this event?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        this._handleRemoveEventModalSubmit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  selectedDate = (arg) => {
    this.showModal();
    this.resetForm();
    if (this.state.show) {
      let momentStartDate = moment(arg.startStr);
      let momentEndDate = moment(arg.endStr);
      let diffrence = momentEndDate.diff(momentStartDate, "minutes");
      if (diffrence < 30) {
        momentEndDate = moment(arg.endStr).add(30 - diffrence, "minutes");
      }
      this.formRef.current.setFieldsValue({
        startDate: moment(arg.startStr),
        endDate:
          moment(momentEndDate).format("hh:mm a") == "12:00 am"
            ? moment(momentEndDate).subtract(1, "minute")
            : moment(momentEndDate),
      });
    }
  };
}

const mapStateToProps = (state) => {
  return {
    all_event: _.get(state, "event.all_event", {}),
    all_event_by_teacher: _.get(state, "event.all_event_by_teacher", {}),
    get_all_sessions_by_teacher: _.get(
      state,
      "room.get_all_sessions_by_teacher",
      {}
    ),
    remove_event: _.get(state, "event.remove_event", {}),
    add_event: _.get(state, "event.add_event", {}),
    add_bulk_event: _.get(state, "event.add_bulk_event", {}),
    update_event: _.get(state, "event.update_event", {}),
    all_event_by_last_week: _.get(state, "event.all_event_by_last_week", {}),
    students: _.get(state, "student.list", {}),
    teachers: _.get(state, "teacher.list", {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllEvents: () => dispatch({ type: reduxActions.GET_ALL_EVENT_REQUEST }),
  getAllEventByTeachers: (payload) =>
    dispatch({ type: reduxActions.GET_ALL_EVENT_BY_TEACHER_REQUEST, payload }),
  getAllEventByLastWeek: (payload) =>
    dispatch({ type: reduxActions.GET_LAST_WEEK_EVENTS_REQUEST, payload }),
  getAllRoomSessionsByTeacher: (payload) =>
    dispatch({
      type: reduxActions.GET_ALL_ROOM_SESSIONS_REQUEST_BY_TEACHER,
      payload,
    }),
  addUserEvents: (payload) =>
    dispatch({ type: reduxActions.ADD_EVENT_REQUEST, payload }),
  addBulkUserEvents: (payload) =>
    dispatch({ type: reduxActions.ADD_EVENT_BULK_REQUEST, payload }),
  removeEventRequest: (payload) =>
    dispatch({ type: reduxActions.REMOVE_EVENT_REQUEST, payload }),
  updateEventRequest: (payload) =>
    dispatch({ type: reduxActions.UPDATE_EVENT_REQUEST, payload }),
  getAllStudentRequest: () =>
    dispatch({ type: reduxActions.GET_ALL_STUDENT_ACCOUNTS_REQUEST }),
  getAllTeacherRequest: () =>
    dispatch({ type: reduxActions.GET_ALL_TEACHER_ACCOUNTS_REQUEST }),
});

export default connect(mapStateToProps, mapDispatchToProps)(index);
