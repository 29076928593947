import { API } from './API';

// fetch all courses
export function getAllCourses(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/courses?${req.qs}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// fetch course details
export function getCourseDetail(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/courses/${req.uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// remove course
export function removeCourse(req) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `admin/courses/${req.uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// create course
export function createCourse(req) {
  return new Promise((resolve, reject) => {
    return API('POST', `admin/courses`, req).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// edit course
export function editCourse(req) {
  return new Promise((resolve, reject) => {
    return API('PUT', `admin/courses/${req.uuid}`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// get course material slides
export function getCourseMaterialSlides(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/courses/${req.uuid}/course-materials`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// update course material slide index
export function updateSlideIndex(req) {
  return new Promise((resolve, reject) => {
    return API('PUT', `admin/courses/${req.uuid}/course-materials-sequence`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function editCourseSlide(req) {
  return new Promise((resolve, reject) => {
    return API('PUT', `admin/courses/${req.uuid}/course-materials/${req.slide_uuid}`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function addNewCourseSlide(req) {
  return new Promise((resolve, reject) => {
    return API('POST', `admin/courses/${req.uuid}/course-materials`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function removeSlideAudio(req) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `admin/courses/course-audio/${req.uuid}`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function removeCourseSlide(req) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `admin/courses/${req.uuid}/course-materials/${req.slide_uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}