import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllCourses,
  getCourse,
  removeCourse,
  createCourse,
  editCourse,
  getCourseMaterialSlides,
  updateSlideIndex,
  editCourseSlide,
  getCourseDetail,
  removeSlideAudio,
  removeCourseSlide
} from '../../services/course.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getCoursesWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllCourses, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_COURSES_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_COURSES_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getCourseDetailWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getCourseDetail, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_COURSE_DETAIL_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_COURSE_DETAIL_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeCourseWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeCourse, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_COURSE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_COURSE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* createCourseWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(createCourse, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CREATE_COURSE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.CREATE_COURSE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* editCourseWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(editCourse, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.EDIT_COURSE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.EDIT_COURSE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getCourseMaterialSlidesWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getCourseMaterialSlides, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_COURSE_MATERIAL_SLIDES_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_COURSE_MATERIAL_SLIDES_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* updateSlideIndexWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(updateSlideIndex, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.UPDATE_SLIDE_INDEX_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.UPDATE_SLIDE_INDEX_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* editCourseSlideWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(editCourseSlide, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.EDIT_COURSE_SLIDE_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.EDIT_COURSE_SLIDE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeSlideAudioWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeSlideAudio, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.HIDE_PAGE_LOADER,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.EDIT_COURSE_SLIDE_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeCourseSlideWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeCourseSlide, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_COURSE_SLIDE_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_COURSE_SLIDE_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}
/* WATCHERS SAGA */

export function* getCoursesWatcher() {
  yield takeLatest(reduxActions.GET_ALL_COURSES_REQUEST, getCoursesWorker);
}

export function* getCourseDetailWatcher() {
  yield takeLatest(reduxActions.GET_COURSE_DETAIL_REQUEST, getCourseDetailWorker);
}

export function* removeCourseWatcher() {
  yield takeLatest(reduxActions.REMOVE_COURSE_REQUEST, removeCourseWorker);
}

export function* createCourseWatcher() {
  yield takeLatest(reduxActions.CREATE_COURSE_REQUEST, createCourseWorker);
}

export function* editCourseWatcher() {
  yield takeLatest(reduxActions.EDIT_COURSE_REQUEST, editCourseWorker);
}

export function* getCourseMaterialSlidesWatcher() {
  yield takeLatest(reduxActions.GET_COURSE_MATERIAL_SLIDES_REQUEST, getCourseMaterialSlidesWorker);
}

export function* updateSlideIndexWatcher() {
  yield takeLatest(reduxActions.UPDATE_SLIDE_INDEX_REQUEST, updateSlideIndexWorker);
}

export function* editCourseSlideWatcher() {
  yield takeLatest(reduxActions.EDIT_COURSE_SLIDE_REQUEST, editCourseSlideWorker);
}

export function* removeCourseSlideAudioWatcher() {
  yield takeLatest(reduxActions.REMOVE_SLIDE_AUDIO_REQUEST, removeSlideAudioWorker);
}

export function* removeCourseSlideWatcher() {
  yield takeLatest(reduxActions.REMOVE_COURSE_SLIDE_REQUEST, removeCourseSlideWorker);
}

export default function* rootSaga() {
  yield all([
    fork(getCoursesWatcher),
    fork(getCourseDetailWatcher),
    fork(removeCourseWatcher),
    fork(createCourseWatcher),
    fork(getCourseMaterialSlidesWatcher),
    fork(updateSlideIndexWatcher),
    fork(editCourseSlideWatcher),
    fork(editCourseWatcher),
    fork(removeCourseSlideAudioWatcher),
    fork(removeCourseSlideWatcher),
  ]);
}
