import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as reduxActions from "../../constants/ActionTypes";
import {
  getAllUserEvent,
  getAllEvent,
  getEvent,
  addUserEvents,
  getTimeEvent,
  updateUserEvents,
  deleteUserEvent,
  getAllEventByTeachers,
  getAllEventByLastWeek,
  addBulkUserEvents,
} from "../../services/event.service";

function* getAllUserEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllUserEvent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_EVENTS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_EVENTS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getEvent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_EVENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_EVENT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getTimeEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getTimeEvent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_TIME_EVENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_TIME_EVENT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAllEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllEvent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_EVENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_EVENT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAllEventByTeacherWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllEventByTeachers, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_EVENT_BY_TEACHER_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_EVENT_BY_TEACHER_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAllEventByLastWeekWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllEventByLastWeek, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_LAST_WEEK_EVENTS_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_LAST_WEEK_EVENTS_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getOpenSlotWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllUserEvent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_OPEN_SLOT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_OPEN_SLOT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* addEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(addUserEvents, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.ADD_EVENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.ADD_EVENT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* addEventBulkWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(addBulkUserEvents, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.ADD_EVENT_BULK_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.ADD_EVENT_BULK_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(deleteUserEvent, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_EVENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_EVENT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* updateEventWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(updateUserEvents, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.UPDATE_EVENT_SUCCESS,
        payload: resp,
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.UPDATE_EVENT_FAILURE,
      payload: error,
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

export function* getUserEventWatcher() {
  yield takeLatest(reduxActions.GET_EVENTS_REQUEST, getAllUserEventWorker);
}

export function* getTimeEventWatcher() {
  yield takeLatest(reduxActions.GET_TIME_EVENT_REQUEST, getTimeEventWorker);
}

export function* getEventWatcher() {
  yield takeLatest(reduxActions.GET_EVENT_REQUEST, getEventWorker);
}

export function* getAllEventWatcher() {
  yield takeLatest(reduxActions.GET_ALL_EVENT_REQUEST, getAllEventWorker);
}

export function* getAllEventByTeacherWatcher() {
  yield takeLatest(
    reduxActions.GET_ALL_EVENT_BY_TEACHER_REQUEST,
    getAllEventByTeacherWorker
  );
}

export function* getAllEventByLastWeekWatcher() {
  yield takeLatest(
    reduxActions.GET_LAST_WEEK_EVENTS_REQUEST,
    getAllEventByLastWeekWorker
  );
}

export function* addUserEventsWatcher() {
  yield takeLatest(reduxActions.ADD_EVENT_REQUEST, addEventWorker);
}

export function* addUserEventsBulkWatcher() {
  yield takeLatest(reduxActions.ADD_EVENT_BULK_REQUEST, addEventBulkWorker);
}
export function* removeEventWatcher() {
  yield takeLatest(reduxActions.REMOVE_EVENT_REQUEST, removeEventWorker);
}
export function* updateEventWatcher() {
  yield takeLatest(reduxActions.UPDATE_EVENT_REQUEST, updateEventWorker);
}
export function* getOpenSlotWatcher() {
  yield takeLatest(reduxActions.GET_OPEN_SLOT_REQUEST, getOpenSlotWorker);
}
export default function* rootSaga() {
  yield all([
    fork(getUserEventWatcher),
    fork(getEventWatcher),
    fork(getAllEventWatcher),
    fork(getAllEventByTeacherWatcher),
    fork(getAllEventByLastWeekWatcher),
    fork(removeEventWatcher),
    fork(addUserEventsWatcher),
    fork(addUserEventsBulkWatcher),
    fork(updateEventWatcher),
    fork(getOpenSlotWatcher),
    fork(getTimeEventWatcher),
  ]);
}
