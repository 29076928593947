export const _PAGESIZE = ["50", "100", "500", "1000"];
export const _DEFAULT_ROWS_PER_PAGE = 50;

export const _ROUTES = {
  PATH_HOME: "/",
  PATH_LOGIN: "/login",
  PATH_LOGOUT: "/logout",
  PATH_DASHBOARD: "/dashboard",

  PATH_CALENDAR: "/calendar",
  PATH_WATCH_VIDEO: "/watch-video",

  PATH_TEACHERS: "/teacher-accounts",
  PATH_TEACHERS_ADD: "/teacher-accounts/register",
  PATH_TEACHERS_EDIT: "/teacher-accounts/:Id/edit",

  PATH_SUB_ADMINS: "/sub-admin-accounts",
  PATH_SUB_ADMINS_ADD: "/sub-admin-accounts/register",

  PATH_STUDENTS: "/student-accounts",
  PATH_STUDENTS_EDIT: "/student-accounts/:Id/edit",

  PATH_ADMINS: "/admin-accounts",
  PATH_ADMINS_ADD: "/admin-accounts/register",

  PATH_COURSES: "/courses",
  PATH_COURSES_ADD: "/courses/add",
  PATH_COURSES_EDIT: "/courses/:Id/edit",
  PATH_COURSES_ADD_SLIDES: "/courses/:Id/add-slides",

  PATH_LESSON_PACKAGES: "/lesson-packages",
  PATH_LESSON_PACKAGES_ADD: "/lesson-packages/add",

  PATH_CREDITS: "/credits",
  PATH_CREDITS_ASSIGN: "/credits/assign",

  PATH_CLASSROOM_SESSIONS: "/classroom-sessions",
  PATH_CLASSROOM_SESSIONS_ASSIGN: "/classroom-sessions/assign",
  PATH_CLASSROOM_SESSIONS_ASSIGN_SLOT: "/classroom-sessions/assign/:Id/",
};

export const _CONST = {
  TIMEZONE: "Asia/Hong_Kong",
  CURRENCY_SIGN: "$",
  DATE_FORMAT: "YYYY-MM-DD",
  LONG_DATETIME_FORMAT: "YYYY-MM-DD hh:mm:ss A",
  CLASSROOM_DATETIME_DATETIME_FORMAT: "YYYY-MM-DD dddd hh:mmA z",
  HK_DATETIME_DATETIME_FORMAT: "YYYY-MM-DD hh:mm:ss",
  CLASSROOM_TIME: "hh:mm",
  NOTIFICATION_TIMEOUT: 7, // seconds > When set to 0 or null, it will never be closed automatically
  RX_NUMBER_ONLY: "^-?\\d*(\\.\\d+)?$",
};

export const _COURSE_TYPES = [
  { value: "major_course", text: "Major Course" },
  { value: "other_course", text: "Other Course" },
];

export const _ENGLISH_EXPOSURE_LEVEL = [
  {
    value: "none_or_minimun_english_exposure",
    text: "None / Minimum English exposure",
  },
  { value: "one_year_english_exposure", text: "1 year of English exposure" },
  {
    value: "two_or_more_years_english_exposure",
    text: "2 or more years of English exposure",
  },
];

export const _CLASSROOM_SESSION_STATUS = [
  { value: "Scheduled", text: "Scheduled" },
  { value: "Completed", text: "Completed" },
  { value: "Incomplete", text: "Incomplete" }
];
