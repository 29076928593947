import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { Layout, Menu, Drawer } from 'antd';
import ScrollBar from './ScrollBar';
import { Menus } from './Menus';
import Logo from '../../assets/images/logo.png';
import LogoText from '../../assets/images/logo_text.png';
import { _ROUTES } from '../../constants/GlobalConst';

const { SubMenu } = Menu;

class Sider extends Component {

  _getChildMenusForSubAdmin = (child) => {
    const { role } = this.props;

    return _.filter(child, mc => {
      const found = _.find(mc.access, mca => { return mca === role });
      return !_.isEmpty(found);
    });
  }

  _loadSider = (collapsed) => {
    const { location: { pathname }, role } = this.props;

    const selectedKeysOrg = pathname.substr(1);
    let defaultOpenKeys = selectedKeysOrg.split('/')[0];
    let selectedKeys = defaultOpenKeys;

    if (_.isEmpty(defaultOpenKeys)) defaultOpenKeys = 'dashboard';
    if (_.isEmpty(selectedKeys)) selectedKeys = 'dashboard';

    return (<Layout.Sider
      width={256}
      theme="dark"
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="lg"
      onBreakpoint={this._handleChangeBreakPoint}
    >
      <div className="brand">
        <div className="app-logo">
          <a href={_ROUTES.PATH_HOME}>
            <img alt="logo" className="logo" src={Logo} />
            {!collapsed && <img alt="logo text" className="logo-text" src={LogoText} />}
          </a>
        </div>
      </div>


      <div className="menu-container">
        <ScrollBar
          options={{
            suppressScrollX: true, // Disabled horizontal scrolling, https://github.com/utatti/perfect-scrollbar#options
          }}
        >
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline">

            {_.map((Menus || []), (menu) => {
              if (_.has(menu, 'child')) {
                let childMenus = role === 'sub-admin' ? this._getChildMenusForSubAdmin(menu.child) : menu.child;
                if (childMenus.length) {
                  return (
                    <SubMenu
                      key={menu.key}
                      title={
                        <span>
                          <React.Fragment>{menu.icon}</React.Fragment>
                          <span>{menu.name}</span>
                        </span>
                      }
                    >
                      {_.map((menu.child || []), (submenu) => {
                        return (
                          <Menu.Item key={submenu.key}>
                            <NavLink to={submenu.path}>
                              <span>{submenu.name}</span>
                            </NavLink>
                          </Menu.Item>
                        )
                      })}
                    </SubMenu>
                  );
                } else {
                  return null;
                }
              } else {
                const found = _.find(menu.access, ma => { return ma === role });
                if (!_.isEmpty(found)) {
                  return (
                    <Menu.Item key={menu.key}>
                      <NavLink to={menu.path}>
                        <React.Fragment>{menu.icon}</React.Fragment>
                        <span>{menu.name}</span>
                      </NavLink>
                    </Menu.Item>
                  );
                } else {
                  return null;
                }
              }
            })}
          </Menu>
        </ScrollBar>
      </div>
    </Layout.Sider>);
  }

  _handleCloseMenuDrawer = () => {
    this.props.handleCloseMenuDrawer();
  }

  _handleChangeBreakPoint = (broken) => {
    this.props.handleChangeBreakPoint(broken);
  }


  render() {
    const { isMobile, collapsed } = this.props;

    return (
      <React.Fragment>
        {isMobile ? (
          <Drawer
            className="menu-drawer"
            maskClosable
            closable={false}
            onClose={this._handleCloseMenuDrawer}
            visible={collapsed}
            placement="left"
          >
            {this._loadSider(false)}
          </Drawer>
        ) : (
            this._loadSider(collapsed)
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    role: _.get(state, 'auth.login.result.response.role', ''),
  };
};

export default connect(mapStateToProps, null)(Sider);
