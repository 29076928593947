import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Card, Button, Row, Col, Form, DatePicker, Select, Input, Switch } from 'antd';
import {
  SendOutlined,
} from '@ant-design/icons';
import { _ROUTES, _CONST, _ENGLISH_EXPOSURE_LEVEL } from '../../constants/GlobalConst';
import { _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class StudentForm extends React.Component {

  constructor(props) {
    super(props);

    const { match: { params: { Id } } } = props;
    this.state = {
      student_account_uuid: Id,
      student: {},
      mode: !_.isEmpty(Id) ? 'edit' : 'add',
      receive_sms: "Yes",
      screen_sharing_allowed: false
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { student_account_uuid, mode } = this.state;
    if (mode === 'edit') this.props.getStudentAccountRequest({ uuid: student_account_uuid });
  }

  componentDidUpdate(prevProps) {
    const { view, edit, history } = this.props;

    if (prevProps.view !== view) {
      const { result } = view;
      if (result.success) {
        const student = _.get(result, 'response.data', {});
        this.setState({
          student
        }, () => {
          this.formRef.current.setFieldsValue({
            email: _.get(student, 'email', ''),
            phone_number: _.get(student, 'phone_number', ''),
            fullname: _.get(student, 'profile.fullname', ''),
            nickname: _.get(student, 'profile.nickname', ''),
            date_of_birth: student && student.profile.date_of_birth ? moment(student.profile.date_of_birth) : "",
            english_exposure_level: _.get(student, 'profile.english_exposure_level', ''),
            receive_sms: _.get(student, 'receive_sms', 'Yes'),
            screen_sharing_allowed: student.screen_sharing_allowed ? _.get(student, 'screen_sharing_allowed') : false
          });
          this.setState({
            receive_sms: _.get(student, 'receive_sms', 'Yes'),
            screen_sharing_allowed: student.screen_sharing_allowed ? _.get(student, 'screen_sharing_allowed') : false
          })
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
        history.push(_ROUTES.PATH_STUDENTS);
      }
    }

    if (prevProps.edit !== edit) {
      const { result } = edit;
      if (result.success) {
        _notify({ type: 'success', description: 'Student Account is successfully updated' });
        history.push(_ROUTES.PATH_STUDENTS);
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

  }

  _handleFormFinish = (values) => {
    const { student_account_uuid } = this.state;
    values.is_skip_phone = true;
    const req = {
      uuid: student_account_uuid,
      data: {
        ...values,
        date_of_birth: moment(values.date_of_birth).format(_CONST.DATE_FORMAT),
        receive_sms: this.state.receive_sms
      }
    };
    this.props.editStudentRequest(req);
  }

  handleChangeReceiveSms = (checked,event) => {
    if(checked === true){
      this.setState({receive_sms: "Yes"})
    }else{
      this.setState({receive_sms: "No"})
    }
  }

  handleChangeScreenSharing = (checked) => {
    this.setState({ screen_sharing_allowed: checked })
  }

  render() {
    const { mode } = this.state;
    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={`${mode === 'edit' ? 'Update' : 'Add'} Student Account`}>
          <Form
            ref={this.formRef}
            layout="vertical"
            scrollToFirstError
            onFinish={this._handleFormFinish}
            initialValues={{ english_exposure_level: '' }}
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="email" label="Email"
                  rules={[
                    { type: 'email', message: 'Email is not valid' },
                    { required: true, message: 'Email is required' },
                  ]}>
                  <Input
                    size="large"
                    placeholder="Email"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="phone_number" label="Phone Number"
                  rules={[
                    { required: true, message: 'Phone Number is required' },
                  ]}>
                  <Input
                    size="large"
                    placeholder="Phone Number"
                    maxLength={15}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="fullname" label="Full Name"
                  rules={[
                    { required: true, message: 'Full Name is required' },
                  ]}>
                  <Input
                    size="large"
                    placeholder="Full Name"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="nickname" label="Nick Name"
                  rules={[
                    { required: true, message: 'Nick Name is required' },
                  ]}>
                  <Input
                    size="large"
                    placeholder="Nick Name"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="date_of_birth" label="Date Of Birth"
                  rules={[
                    { required: true, message: 'Date Of Birth is required' }
                  ]}>
                  <DatePicker
                    size="large"
                    className="full-width"
                    disabledDate={(current) => current && current < moment().subtract(1, 'day')}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="english_exposure_level" label="English Exposure Level"
                  rules={[
                    { required: true, message: 'English Exposure Level is required' }
                  ]}>
                  <Select
                    size="large"
                  >
                    <Select.Option value="">Select English Exposure Level</Select.Option>
                    {_.map(_ENGLISH_EXPOSURE_LEVEL, (level, key) => {
                      return (
                        <Select.Option value={level.value} key={key}>
                          {level.text}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Form.Item name="password" label="Password"
                  rules={[
                    { min: 6, message: 'Password must be greater or equal to 6 characters.' }
                  ]}>
                  <Input
                    type="password"
                    size="large"
                    placeholder="Password"
                    maxLength={50}
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Row justify="space-between">
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item name="receive_sms" label="Receive SMS for Regular Class" >
                      <Switch checked={this.state.receive_sms === "Yes" ? true : false} onChange={this.handleChangeReceiveSms} />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item name="screen_sharing_allowed" label="Enable Screen Share for Teacher" >
                      <Switch checked={this.state.screen_sharing_allowed} onChange={this.handleChangeScreenSharing} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <div className="sk-button-group">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    icon={<SendOutlined />}
                  >{`${mode === 'edit' ? 'Update' : 'Add'}`}</Button>
                  <Button
                    size="large"
                    onClick={() => this.props.history.push(_ROUTES.PATH_STUDENTS)}
                  >Back</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  view: _.get(state, 'student.view', {}),
  edit: _.get(state, 'student.edit', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getStudentAccountRequest: payload => dispatch({ type: reduxActions.GET_STUDENT_ACCOUNT_REQUEST, payload }),
  editStudentRequest: payload => dispatch({ type: reduxActions.EDIT_STUDENT_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentForm);