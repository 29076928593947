import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getAllSubAdminAccounts,
  removeSubAdminAccount,
  createAdminAccount,
  getAllAdminAccounts,
} from '../../services/admin.service';
import * as reduxActions from "../../constants/ActionTypes";

/* WORKERS SAGA */

function* getSubAdminAccountsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllSubAdminAccounts, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_SUB_ADMIN_ACCOUNTS_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_SUB_ADMIN_ACCOUNTS_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* removeSubAdminAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(removeSubAdminAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.REMOVE_SUB_ADMIN_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.REMOVE_SUB_ADMIN_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* createSubAdminAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(createAdminAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CREATE_SUB_ADMIN_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.CREATE_SUB_ADMIN_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* getAdminAccountsWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(getAllAdminAccounts, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.GET_ALL_ADMIN_ACCOUNTS_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.GET_ALL_ADMIN_ACCOUNTS_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

function* createAdminAccountWorker(action) {
  try {
    yield put({ type: reduxActions.SHOW_PAGE_LOADER });
    const resp = yield call(createAdminAccount, action.payload);
    if (resp.success) {
      yield put({
        type: reduxActions.CREATE_ADMIN_ACCOUNT_SUCCESS,
        payload: resp
      });
    }
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  } catch (error) {
    yield put({
      type: reduxActions.CREATE_ADMIN_ACCOUNT_FAILURE,
      payload: error
    });
    yield put({ type: reduxActions.HIDE_PAGE_LOADER });
  }
}

/* WATCHERS SAGA */

export function* getSubAdminAccountsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_SUB_ADMIN_ACCOUNTS_REQUEST, getSubAdminAccountsWorker);
}

export function* removeSubAdminAccountWatcher() {
  yield takeLatest(reduxActions.REMOVE_SUB_ADMIN_ACCOUNT_REQUEST, removeSubAdminAccountWorker);
}

export function* createSubAdminAccountWatcher() {
  yield takeLatest(reduxActions.CREATE_SUB_ADMIN_ACCOUNT_REQUEST, createSubAdminAccountWorker);
}

export function* getAdminAccountsWatcher() {
  yield takeLatest(reduxActions.GET_ALL_ADMIN_ACCOUNTS_REQUEST, getAdminAccountsWorker);
}

export function* createAdminAccountWatcher() {
  yield takeLatest(reduxActions.CREATE_ADMIN_ACCOUNT_REQUEST, createAdminAccountWorker);
}

export default function* rootSaga() {
  yield all([
    fork(getSubAdminAccountsWatcher),
    fork(removeSubAdminAccountWatcher),
    fork(createSubAdminAccountWatcher),
    fork(getAdminAccountsWatcher),
    fork(createAdminAccountWatcher),
  ]);
}
