import { API } from './API';

// user login
export function userLogin(data) {
  return new Promise((resolve, reject) => {
    return API('POST', `auth/login`, data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// user logout
export function userLogout() {
  return new Promise((resolve, reject) => {
    return API('GET', `auth/logout`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}


