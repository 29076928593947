import { API } from "./API";

// fetch all room sessions
export function getAllRoomSessions(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `admin/classroom-session?${req.qs}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// fetch all room sessions by teacher
export function getAllRoomSessionsByTeacher(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `classroom-session/${req.id}/sessionsbyteacher`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// fetch room session
export function getRoomSession(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `admin/classroom-session/${req.uuid}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// fetch room session recorded videos
export function getRoomSessionRecordedVideos(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `admin/classroom-session/${req.uuid}/recorded_videos`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// remove room session
export function removeRoomSession(req) {
  return new Promise((resolve, reject) => {
    const uuid = req.uuid;
    const payload = {
      deletedReason: req.deletedReason
    }
    return API("PUT", `admin/classroom-session/${uuid}`, payload)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// assign room session
export function assignRoomSession(req) {
  return new Promise((resolve, reject) => {
    return API("POST", `admin/classroom-session`, req.data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// export room sessions
export function exportRoomSessions(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `admin/classroom-session/export?${req.qs}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// update classroom status
export function updateClassroomSession(data) {
  return new Promise((resolve, reject) => {
    return API("PUT", `classroom-session/${data.id}`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
