import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Button, Tooltip, Table, Pagination, Modal } from 'antd';
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { _ROUTES, _PAGESIZE, _DEFAULT_ROWS_PER_PAGE, _CONST } from '../../constants/GlobalConst';
import { _makeQueryString, _formatDateTime, _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class SubAdminsList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      total_records: 0,
      subadmins: [],
    };
  }

  componentDidMount() {
    this._loadSubAdmins();
  }

  componentDidUpdate(prevProps) {
    const { list, remove } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          subadmins: _.get(result, 'response.data', []),
          total_records: _.get(result, 'response.pagination.totalItems', 0),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.remove !== remove) {
      const { result } = remove;
      if (result.success) {
        _notify({ type: 'success', description: 'Sub-Admin is successfully removed' });
        this._resetPagination();
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

  }

  _resetPagination = () => {
    const { subadmins, pagination } = this.state;
    let reset_pagination = ((subadmins.length - 1) === 0);
    let n_page = (reset_pagination ? (pagination.page === 1 ? pagination.page : pagination.page - 1) : pagination.page);

    this.setState({
      pagination: {
        ...pagination,
        page: n_page,
      },
    }, () => {
      this._loadSubAdmins();
    });
  }

  _loadSubAdmins = () => {
    const { pagination } = this.state;

    this.props.getAllSubAdminAccountsRequest({
      qs: _makeQueryString(pagination),
    });
  }

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        ...pagination,
        size,
        page,
      },
    }, () => this._loadSubAdmins());
  }

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        size,
        page: 1,
      },
    }, () => this._loadSubAdmins());
  }

  _handleRemoveSubAdmin = (record) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to delete this sub-admin account?',
      okText: 'Ok',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => {
        this.props.removeSubAdminAccountsRequest({ uuid: record.uuid });
      },
    });
  }

  render() {
    const { pagination, subadmins, total_records } = this.state;
    let COLUMNS = [
      {
        title: 'Email',
        key: 'uuid',
        dataIndex: 'email'
      },
      {
        title: 'Role',
        dataIndex: 'role'
      },
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (text, record) => (
          <Tooltip placement="top" title="Remove">
            <Button type="danger" size="small" onClick={() => this._handleRemoveSubAdmin(record)}><DeleteOutlined /></Button>
          </Tooltip>
        ),
      }
    ];

    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Sub-Admin Accounts'}
          extra={<div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(_ROUTES.PATH_SUB_ADMINS_ADD)}>Create</Button>
          </div>}>
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive"
              columns={COLUMNS}
              dataSource={subadmins}
              pagination={false}
            />
            {(!_.isEmpty(subadmins)) && <div className="sk-pagination">
              <Pagination
                current={pagination.page}
                onChange={this._handlePageChange}
                pageSize={pagination.size}
                pageSizeOptions={_PAGESIZE}
                showSizeChanger={true}
                onShowSizeChange={this._handlePageSizeChange}
                total={total_records}
              />
            </div>}
          </div>
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, 'admin.subadmin_list', {}),
  remove: _.get(state, 'admin.subadmin_remove', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllSubAdminAccountsRequest: payload => dispatch({ type: reduxActions.GET_ALL_SUB_ADMIN_ACCOUNTS_REQUEST, payload }),
  removeSubAdminAccountsRequest: payload => dispatch({ type: reduxActions.REMOVE_SUB_ADMIN_ACCOUNT_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminsList);
