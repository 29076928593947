import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import numeral from 'numeral';
import { Card, Button, Tooltip, Table, Pagination, Modal, Drawer, Form, Row, Col } from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { _ROUTES, _PAGESIZE, _DEFAULT_ROWS_PER_PAGE, _CONST } from '../../constants/GlobalConst';
import { _makeQueryString, _formatDateTime, _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class PackagesList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      total_records: 0,
      packages: [],
      lesson_package: {},
      showViewPackageDrawer: false,
    };
  }

  componentDidMount() {
    this._loadPackages();
  }

  componentDidUpdate(prevProps) {
    const { list, remove } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          packages: _.get(result, 'response.data', []),
          total_records: _.get(result, 'response.pagination.totalItems', 0),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.remove !== remove) {
      const { result } = remove;
      if (result.success) {
        _notify({ type: 'success', description: 'Lesson Package is successfully removed' });
        this._resetPagination();
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

  }

  _resetPagination = () => {
    const { packages, pagination } = this.state;
    let reset_pagination = ((packages.length - 1) === 0);
    let n_page = (reset_pagination ? (pagination.page === 1 ? pagination.page : pagination.page - 1) : pagination.page);

    this.setState({
      pagination: {
        ...pagination,
        page: n_page,
      },
    }, () => {
      this._loadPackages();
    });
  }

  _loadPackages = () => {
    const { pagination } = this.state;

    this.props.getAllPackagesRequest({
      qs: _makeQueryString(pagination),
    });
  }

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        ...pagination,
        size,
        page,
      },
    }, () => this._loadPackages());
  }

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        size,
        page: 1,
      },
    }, () => this._loadPackages());
  }

  _handleRemovePackage = (record) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to delete this lesson package?',
      okText: 'Ok',
      cancelText: 'Cancel',
      okType: 'danger',
      onOk: () => {
        this.props.removePackageRequest({ uuid: record.uuid });
      },
    });
  }

  _handleViewPackage = (record) => {
    this.setState({
      lesson_package: record,
      showViewPackageDrawer: true,
    })
  }

  _loadPackageItems = (lesson_package) => {
    try {
      return _.map(lesson_package.items || [], (item, key) => {
        return (
          <div key={key}>{item}</div>
        )
      });
    } catch {
      return '';
    }
  }

  render() {
    const { pagination, packages, lesson_package, total_records, showViewPackageDrawer } = this.state;
    let COLUMNS = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name'
      },
      {
        title: 'Description',
        dataIndex: 'description'
      },
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (text, record) => (
          <div className="sk-button-group">
            <Tooltip placement="top" title="View">
              <Button type="default" size="small" onClick={() => this._handleViewPackage(record)}><EyeOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="Remove">
              <Button type="danger" size="small" onClick={() => this._handleRemovePackage(record)}><DeleteOutlined /></Button>
            </Tooltip>
          </div>
        ),
      }
    ];

    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Lesson Packages'}
          extra={<div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(_ROUTES.PATH_LESSON_PACKAGES_ADD)}>Create</Button>
          </div>}>
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive"
              columns={COLUMNS}
              dataSource={packages}
              pagination={false}
            />
            {(!_.isEmpty(packages)) && <div className="sk-pagination">
              <Pagination
                current={pagination.page}
                onChange={this._handlePageChange}
                pageSize={pagination.size}
                pageSizeOptions={_PAGESIZE}
                showSizeChanger={true}
                onShowSizeChange={this._handlePageSizeChange}
                total={total_records}
              />
            </div>}
          </div>

          {showViewPackageDrawer && <Drawer
            title={'View Lesson Package'}
            className="sk-drawer"
            closable={true}
            onClose={() => this.setState({ showViewPackageDrawer: false })}
            visible={showViewPackageDrawer}
          >
            <Form layout='vertical'>
              <Row>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Form.Item label="UUID">
                    {_.get(lesson_package, 'uuid', '')}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Name">
                    {_.get(lesson_package, 'name', '')}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Description">
                    {_.get(lesson_package, 'description', '')}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Type">
                    {_.get(lesson_package, 'type', '')}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Number of Lessons">
                    {_.get(lesson_package, 'number_of_lessons', '')}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Price">
                    {_CONST.CURRENCY_SIGN}{numeral((_.get(lesson_package, 'price.amount') / 100)).format('0,0.00')}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Items">
                    {this._loadPackageItems(lesson_package)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Last Modified At">
                    {_formatDateTime(lesson_package.last_modified_at, _CONST.LONG_DATETIME_FORMAT)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Added At">
                    {_formatDateTime(lesson_package.added_at, _CONST.LONG_DATETIME_FORMAT)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Inserted At">
                    {_formatDateTime(lesson_package.inserted_at, _CONST.LONG_DATETIME_FORMAT)}
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                  <Form.Item label="Updated At">
                    {_formatDateTime(lesson_package.updated_at, _CONST.LONG_DATETIME_FORMAT)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>}
        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, 'lesson_package.list', {}),
  remove: _.get(state, 'lesson_package.remove', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAllPackagesRequest: payload => dispatch({ type: reduxActions.GET_ALL_LESSON_PACKAGES_REQUEST, payload }),
  removePackageRequest: payload => dispatch({ type: reduxActions.REMOVE_LESSON_PACKAGE_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PackagesList);
