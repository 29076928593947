import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_COURSES_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_COURSES_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const view = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_COURSE_DETAIL_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_COURSE_DETAIL_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const remove = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_COURSE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_COURSE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const create = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.CREATE_COURSE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.CREATE_COURSE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const edit = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.EDIT_COURSE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.EDIT_COURSE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const material_slides = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_COURSE_MATERIAL_SLIDES_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_COURSE_MATERIAL_SLIDES_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const update_slide_index = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.UPDATE_SLIDE_INDEX_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.UPDATE_SLIDE_INDEX_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const edit_slide = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.EDIT_COURSE_SLIDE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.EDIT_COURSE_SLIDE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const remove_slide = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_COURSE_SLIDE_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_COURSE_SLIDE_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  list,
  view,
  remove,
  create,
  edit,
  material_slides,
  update_slide_index,
  edit_slide,
  remove_slide
});
