import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { Card, Drawer, Button, Tooltip, Tabs, Table, Row, Col, Form, Pagination } from 'antd';
import {
  EyeOutlined,
  DiffOutlined,
  PlusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { _ROUTES, _PAGESIZE, _DEFAULT_ROWS_PER_PAGE, _CONST } from '../../constants/GlobalConst';
import { _makeQueryString, _formatDateTime, _notify } from "../../helpers/Utils";
import * as reduxActions from "../../constants/ActionTypes";

class TeachersList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      pagination: {
        size: _DEFAULT_ROWS_PER_PAGE,
        page: 1,
      },
      cols: '{"account":["uuid","email","phone_number"],"profile":["fullname"]}',
      total_records: 0,
      teachers: [],
      teacher: {},
      events: [],
      openSlot: [],
      showViewTeacherDrawer: false,
    };
  }

  componentDidMount() {
    this._loadTeachers();
  }

  componentDidUpdate(prevProps) {
    const { list, view, user_event, open_slot } = this.props;

    if (prevProps.list !== list) {
      const { result } = list;
      if (result.success) {
        this.setState({
          teachers: _.get(result, 'response.data', []),
          total_records: _.get(result, 'response.pagination.totalItems', 0),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.view !== view) {
      const { result } = view;
      if (result.success) {
        this.setState({
          teacher: _.get(result, 'response.data', {}),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }

    if (prevProps.user_event !== user_event) {
      const { result } = user_event;
      if (result.success) {
        this.setState({
          events: _.get(result, 'response.data.rows', []),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
    if (prevProps.open_slot !== open_slot) {
      const { result } = open_slot;
      if (result.success) {
        this.setState({
          openSlot: _.get(result, 'response.data.rows', []),
        });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
  }

  _loadTeachers = () => {
    const { pagination, cols } = this.state;

    this.props.getAllTeacherAccountsRequest({
      qs: `${_makeQueryString(pagination)}&cols=${cols}`,
    });
  }

  // Pagination : page change event
  _handlePageChange = (page, size) => {
    const { pagination } = this.state;

    this.setState({
      pagination: {
        ...pagination,
        size,
        page,
      },
    }, () => this._loadTeachers());
  }

  // Pagination : page size change event
  _handlePageSizeChange = (current, size) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        size,
        page: 1,
      },
    }, () => this._loadTeachers());
  }

  _handleViewTeacher = (record) => {
    this.setState({ showViewTeacherDrawer: true }, () => {
      this.props.getTeacherAccountRequest({ uuid: record.uuid });
      this.props.getEventRequest({ id: record.uuid, status: "Reserved" });
      this.props.getOpenSlot({ id: record.uuid, status: "Available" });
    });
  }

  _handleEditTeacher = (record) => {
    this.props.history.push(_.replace(_ROUTES.PATH_TEACHERS_EDIT, ':Id', record.uuid));
  }

  render() {
    const { pagination, teachers, teacher, total_records, showViewTeacherDrawer } = this.state;
    let COLUMNS = [
      {
        title: 'Email',
        key: 'uuid',
        dataIndex: 'email'
      },
      {
        title: 'Full Name',
        dataIndex: ['profile', 'fullname']
      },
      {
        title: 'Phone #',
        dataIndex: 'phone_number'
      },
      {
        title: 'Action',
        align: 'center',
        width: '10%',
        render: (text, record) => (
          <div className="sk-button-group">
            <Tooltip placement="top" title="Edit">
              <Button type="default" size="small" onClick={() => this._handleEditTeacher(record)}><EditOutlined /></Button>
            </Tooltip>
            <Tooltip placement="top" title="View">
              <Button type="default" size="small" onClick={() => this._handleViewTeacher(record)}><EyeOutlined /></Button>
            </Tooltip>
          </div>

        ),
      }
    ];

    let EVENT_COLUMNS = [
      {
        title: 'Id',
        key: 'index',
        width: '50px',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Start Date',
        key: 'start',
        dataIndex: 'start',
        width: '200px',
        render: (text, record, index) => moment(text).format('YYYY-MM-DD hh:mm').toString(),
      },
      {
        title: 'End Date',
        key: 'end',
        dataIndex: 'end',
        width: '200px',
        render: (text, record, index) => moment(text).format('YYYY-MM-DD hh:mm').toString(),
      },
      {
        title: 'Student',
        key: 'student',
        dataIndex: ['account', 'profile', 'fullname'],
        width: '200px',
      },
      {
        title: 'Action',
        align: 'center',
        width: '50px',
        render: (text, record) => (
          <div className="sk-button-group">
            <Tooltip placement="top" title="Assign class">
              <Button type="default" size="small"
                onClick={() => this.props.history.push(_.replace(_ROUTES.PATH_CLASSROOM_SESSIONS_ASSIGN_SLOT, ':Id', record.uuid))}
              ><DiffOutlined /></Button>
            </Tooltip>
          </div>
        ),
      }
    ];
    let OPEN_SLOT_COLUMNS = [
      {
        title: 'Id',
        key: 'index',
        width: '50px',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Start Date',
        key: 'start',
        dataIndex: 'start',
        width: '200px',
        render: (text, record, index) => moment(text).format('YYYY-MM-DD hh:mm').toString(),
      },
      {
        title: 'End Date',
        key: 'end',
        dataIndex: 'end',
        width: '200px',
        render: (text, record, index) => moment(text).format('YYYY-MM-DD hh:mm').toString(),
      },
    ];
    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Teacher Accounts'}
          extra={<div>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(_ROUTES.PATH_TEACHERS_ADD)}>Create</Button>
          </div>}>
          <div>
            <Table
              rowKey="uuid"
              bordered
              size="small"
              className="sk-table-responsive"
              columns={COLUMNS}
              dataSource={teachers}
              pagination={false}
            />
            {(!_.isEmpty(teachers)) && <div className="sk-pagination">
              <Pagination
                current={pagination.page}
                onChange={this._handlePageChange}
                pageSize={pagination.size}
                pageSizeOptions={_PAGESIZE}
                showSizeChanger={true}
                onShowSizeChange={this._handlePageSizeChange}
                total={total_records}
              />
            </div>}
          </div>
        </Card>

        {showViewTeacherDrawer && <Drawer
          title={'View Teacher Account'}
          className="sk-drawer"
          closable={true}
          onClose={() => this.setState({ showViewTeacherDrawer: false })}
          visible={showViewTeacherDrawer}
        >
          <Form layout='vertical'>
            <Tabs defaultActiveKey="1" onChange={this._handleTabChange}>
              <Tabs.TabPane tab="Details" key="tab_details">
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item label="UUID">
                      {_.get(teacher, 'uuid', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Email">
                      {_.get(teacher, 'email', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Phone Number">
                      {_.get(teacher, 'phone_number', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Full Name">
                      {_.get(teacher, 'profile.fullname', '')}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Date of Birth">
                      {!_.isEmpty(_.get(teacher, 'profile.date_of_birth', '')) ? moment(_.get(teacher, 'profile.date_of_birth', '')).format(_CONST.DATE_FORMAT) : 'N/A'}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Last Authenticated At">
                      {_formatDateTime(teacher.last_authenticated_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Registered At">
                      {_formatDateTime(teacher.registered_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Inserted At">
                      {_formatDateTime(teacher.inserted_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                    <Form.Item label="Updated At">
                      {_formatDateTime(teacher.updated_at, _CONST.LONG_DATETIME_FORMAT)}
                    </Form.Item>
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Reserved Slot" key="tab_reserved">
                <Table
                  bordered
                  size="small"
                  align="center"
                  className="sk-table-responsive"
                  columns={EVENT_COLUMNS}
                  dataSource={this.state.events}
                  pagination={false}
                  style={{ width: '600px' }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Open Slot" key="tab_open">
                <Table
                  bordered
                  size="small"
                  align="center"
                  className="sk-table-responsive"
                  columns={OPEN_SLOT_COLUMNS}
                  dataSource={this.state.openSlot}
                  pagination={false}
                  style={{ width: '450px' }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Drawer>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  list: _.get(state, 'teacher.list', {}),
  view: _.get(state, 'teacher.view', {}),
  user_event: _.get(state, 'event.user_event', []),
  open_slot: _.get(state, 'event.open_slot', []),
});

const mapDispatchToProps = (dispatch) => ({
  getAllTeacherAccountsRequest: payload => dispatch({ type: reduxActions.GET_ALL_TEACHER_ACCOUNTS_REQUEST, payload }),
  getTeacherAccountRequest: payload => dispatch({ type: reduxActions.GET_TEACHER_ACCOUNT_REQUEST, payload }),
  getEventRequest: payload => dispatch({ type: reduxActions.GET_EVENTS_REQUEST, payload }),
  getOpenSlot: payload => dispatch({ type: reduxActions.GET_OPEN_SLOT_REQUEST, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeachersList);
