import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Card, Button, Row, Col, Form, Input, Image, Upload, Modal, Table, Tooltip } from 'antd';
import {
  InboxOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { _ROUTES, _CONST, _COURSE_TYPES } from '../../constants/GlobalConst';
import { _notify } from "../../helpers/Utils";
import { addNewCourseSlide, getCourseMaterialSlides } from "../../services/course.service";
import * as reduxActions from "../../constants/ActionTypes";

class CourseAdd extends React.Component {

  constructor(props) {
    super(props);

    const { match: { params: { Id } } } = this.props;

    this.state = {
      courseId: Id,
      course: {},
      fileList: [],
      showAddSlidesModal: false,
      uploadLoading: false,
    };
  }

  componentDidMount() {
    this.props.getCourseDetailRequest({ uuid: this.state.courseId });
  }

  componentDidUpdate(prevProps) {
    const { view } = this.props;

    if (prevProps.view !== view) {
      const { result } = view;
      if (result.success) {
        this.setState({ course: _.get(result, 'response.data', {}) });
      } else {
        _notify({ type: 'error', description: _.get(result, 'response.message', '') });
      }
    }
  }

  _handleFileBeforeUpload = (file, fileList) => {
    const lastIndex = fileList.length - 1;
    const reader = new FileReader();

    reader.onload = e => {
      file.base64 = e.target.result;
      file.form_fields = {
        name: file.name.replace(/\.[^/.]+$/, ""),
        tips: '',
      };

      const currentIndex = _.findIndex(fileList, f => { return f.uid === file.uid });

      if (currentIndex === lastIndex) {
        setTimeout(() => {
          this.setState({
            fileList,
            showAddSlidesModal: true,
          }, () => this.props.hidePageLoader());
        });
      } else {
        this.props.showPageLoader();
      }
    };
    reader.readAsDataURL(file);

    // Prevent upload
    return false;
  }

  _handleRemoveFileUpload = (record) => {
    const { fileList } = this.state;

    this.setState({
      fileList: _.filter(fileList, file => { return file.uid != record.uid })
    });
  }

  _handleCloseAddSlidesModal = () => {
    this.setState({
      showAddSlidesModal: false,
      fileList: [],
    });
  }

  _handleChange = (payload, index) => {
    const { fileList } = this.state;

    if (_.has(payload, 'name')) fileList[index].form_fields.name = payload.name;
    if (_.has(payload, 'tips')) fileList[index].form_fields.tips = payload.tips;
    if (_.has(payload, 'audio')) fileList[index].form_fields.audio = payload.audio[0];

    this.setState({ fileList });
  }

  _handleUploadFiles = () => {
    const { fileList, courseId } = this.state;

    if (fileList.length) {
      this.setState({ uploadLoading: true }, () => {
        getCourseMaterialSlides({ uuid: courseId }).then(res => {
          const slides = _.get(res, 'response.data', []) || [];
          const current_seq = slides.length + 1;

          _.map(fileList, async (file, index) => {
            const isLast = (fileList.length - 1 === index);
            file.seq = current_seq + index; // to maintain and pass the slide sequence num from FE
            await this.uploadCourseSlide(file, isLast);
          });

        });

      });
    } else {
      _notify({ type: 'error', description: 'Sorry, No slides found to upload' });
    }
  }

  async uploadCourseSlide(slide, isLast) {
    const { courseId, fileList } = this.state;

    let formData = new FormData();
    formData.append('name', _.get(slide, 'form_fields.name', ''));
    formData.append('tips', _.get(slide, 'form_fields.tips', ''));
    formData.append('image', slide);
    formData.append('seq', _.get(slide, 'seq', ''));
    formData.append('audio', _.get(slide, 'form_fields.audio', ''));

    await addNewCourseSlide({
      uuid: courseId,
      data: formData,
    }).then(res => {
      if (isLast) {
        _notify({ type: 'success', description: `Course Material Slide${fileList.length > 1 ? `s are` : ` is`} successfully added` });
        this.props.history.push(_ROUTES.PATH_COURSES);
      }
    }).catch(err => {
      _notify({ type: 'error', description: 'Error while uploading slides. Please try again' });
      this.setState({ uploadLoading: false })
    })
  }

  _getCourseTypeText = (course_type) => {
    try {
      return `${_.find(_COURSE_TYPES, ct => { return ct.value === course_type }).text}`;
    } catch {
      return null;
    }
  }

  render() {
    const { course, fileList, showAddSlidesModal, uploadLoading } = this.state;

    let COLUMNS_SLIDES = [
      {
        title: 'Image',
        render: (text, record) => {
          return (<Image
            width={100}
            height={100}
            src={record.base64}
            alt={record.name}
          />)
        }
      },
      {
        title: 'Name',
        render: (text, record, index) => (
          <Input
            size="large"
            defaultValue={record.form_fields.name.replace(/\.[^/.]+$/, "")}
            placeholder="Slide Name"
            onChange={({ target: { value } }) => this._handleChange({ name: value }, index)}
          />
        ),
      },
      {
        title: 'Tips',
        render: (text, record, index) => (
          <Input.TextArea
            placeholder="Tips"
            autoSize={{ minRows: 2, maxRows: 3 }}
            onChange={({ target: { value } }) => this._handleChange({ tips: value }, index)}
          />
        ),
      },
      {
        title: 'Audio',
        render: (text, record, index) => (
          <Input
            type="file"
            accept=".mp3"
            placeholder="Audio"
            onChange={({ target: { files } }) => this._handleChange({ audio: files }, index)}
          />
        ),
      },
      {
        title: 'Action',
        align: 'center',
        width: '12%',
        render: (text, record) => (
          <Tooltip placement="top" title="Remove">
            <Button type="danger" size="small" onClick={() => this._handleRemoveFileUpload(record)}><DeleteOutlined /></Button>
          </Tooltip>
        ),
      }
    ];

    return (
      <React.Fragment>
        <Card
          className="sk-card"
          title={'Add Course Material Slides'}>

          <Form layout="vertical">
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 11 }}>
                <Form.Item label="Name">
                  {_.get(course, 'name', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 4 }}>
                <Form.Item label="Course Type">
                  {this._getCourseTypeText(course.type)}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                <Form.Item label="Level">
                  {_.get(course, 'level', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                <Form.Item label="Unit">
                  {_.get(course, 'unit', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 3 }}>
                <Form.Item label="Lesson">
                  {_.get(course, 'lesson', '')}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Form.Item label="Description">
                  {_.get(course, 'description', '')}
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                <Upload.Dragger
                  name='file'
                  accept=".jpg,.jpeg,.png"
                  multiple={true}
                  showUploadList={false}
                  beforeUpload={this._handleFileBeforeUpload}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                </Upload.Dragger>
              </Col>
            </Row>
          </Form>
        </Card>

        {showAddSlidesModal && <Modal
          visible={showAddSlidesModal}
          title="Add Slides"
          width="70%"
          maskClosable={false}
          okText="Upload"
          onCancel={this._handleCloseAddSlidesModal}
          onOk={this._handleUploadFiles}
          confirmLoading={uploadLoading}
        >
          <Table
            rowKey="uid"
            bordered
            size="small"
            className="sk-table-responsive"
            columns={COLUMNS_SLIDES}
            dataSource={fileList}
            pagination={false}
          />
        </Modal>}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  view: _.get(state, 'course.view', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getCourseDetailRequest: payload => dispatch({ type: reduxActions.GET_COURSE_DETAIL_REQUEST, payload }),
  showPageLoader: () => dispatch({ type: reduxActions.SHOW_PAGE_LOADER }),
  hidePageLoader: () => dispatch({ type: reduxActions.HIDE_PAGE_LOADER }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseAdd);
