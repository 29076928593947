import { API } from './API';

// fetch all student accounts
export function getAllStudentAccounts(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/students?${req ? req.qs : ''}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// fetch single student account
export function getStudentAccount(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/students/${req.uuid}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function editStudentAccount(req) {
  return new Promise((resolve, reject) => {
    return API('PUT', `admin/students/${req.uuid}`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function deactivateStudentAccount(req) {
  return new Promise((resolve, reject) => {
    return API('DELETE', `admin/students/${req.uuid}`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function getStudentCreditReport(req){
  return new Promise((resolve, reject) => {
    return API('GET', `admin/students/student-credit-report`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function getStudentCourse(req){
  return new Promise((resolve, reject) => {
    return API('GET', `admin/classroom-session/student/${req.id}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}