import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const list = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_CREDITS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_CREDITS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const assign = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.ASSIGN_CREDIT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.ASSIGN_CREDIT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

const logs = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_CREDITS_LOG_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_CREDITS_LOG_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
}

export default combineReducers({
  list,
  assign,
  logs,
});
