import { API } from "./API";

export function getAllUserEvent(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `events/${req.id}/${req.status}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllEvent(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `events/all`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllEventByTeachers(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `events/allteachers/${req.id}/byteacher`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllEventByLastWeek(req) {
  return new Promise((resolve, reject) => {
    return API(
      "GET",
      `events/allastweek/${req.start}/${req.end}/${req.uuid}/bylastweek`
    )
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEvent(req) {
  return new Promise((resolve, reject) => {
    return API("GET", `events/event/${req.id}/event`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTimeEvent(req) {
  return new Promise((resolve, reject) => {
    return API("POST", `events/${req.id}`, req)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addUserEvents(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `events/`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addBulkUserEvents(data) {
  return new Promise((resolve, reject) => {
    return API("POST", `events/addbulkevents`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserEvents(data) {
  return new Promise((resolve, reject) => {
    return API("PUT", `events/${data.uuid}`, data)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteUserEvent(data) {
  return new Promise((resolve, reject) => {
    return API("DELETE", `events/${data.event_id}`)
      .then((response) => {
        response.success ? resolve(response) : reject(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
