import { API } from './API';

// fetch all credits
export function getAllCredits(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/credits?${req.qs}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// assign credit
export function assignCredit(req) {
  return new Promise((resolve, reject) => {
    return API('POST', `admin/credits`, req.data).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}

// credits log
export function getCreditsLog(req) {
  return new Promise((resolve, reject) => {
    return API('GET', `admin/credits/logs?${req.qs}`).then((response) => {
      response.success ? resolve(response) : reject(response);
    }).catch((error) => {
      reject(error);
    });
  });
}
