import { combineReducers } from "redux";
import * as reduxActions from "../../constants/ActionTypes";

const user_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_EVENTS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_EVENTS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const all_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_EVENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_EVENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const all_event_by_teacher = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_ALL_EVENT_BY_TEACHER_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_ALL_EVENT_BY_TEACHER_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const all_event_by_last_week = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_LAST_WEEK_EVENTS_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_LAST_WEEK_EVENTS_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const get_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_EVENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_EVENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// remove Event
const remove_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.REMOVE_EVENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.REMOVE_EVENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// add Event
const add_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.ADD_EVENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.ADD_EVENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

// Add Bulk Events
const add_bulk_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.ADD_EVENT_BULK_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.ADD_EVENT_BULK_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const update_event = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.UPDATE_EVENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.UPDATE_EVENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const open_slot = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_OPEN_SLOT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_OPEN_SLOT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

const time_session = (state = {}, action) => {
  switch (action.type) {
    case reduxActions.GET_TIME_EVENT_SUCCESS:
      return {
        result: action.payload,
      };
    case reduxActions.GET_TIME_EVENT_FAILURE:
      return {
        result: action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  user_event,
  all_event,
  all_event_by_teacher,
  all_event_by_last_week,
  remove_event,
  add_event,
  add_bulk_event,
  update_event,
  open_slot,
  get_event,
  time_session,
});
