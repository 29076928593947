import React, { Component } from 'react';
import _ from 'lodash';
import { enquireScreen, unenquireScreen } from 'enquire-js'
import { Layout } from 'antd';
import Sider from './components/layout/Sider';
import Header from './components/layout/Header';
import AppRoutes from './Routes';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      const { isMobile } = this.state;
      if (isMobile !== mobile) {
        this.setState({
          isMobile: mobile,
          collapsed: !_.isBoolean(mobile),
        });
      }
    })
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler)
  }

  render() {
    const { isMobile, collapsed } = this.state;
    return (
      <div className="root-container">
        <Layout>
          <Sider
            isMobile={isMobile}
            collapsed={collapsed}
            handleChangeBreakPoint={(broken) => this.setState({ collapsed: broken })}
            handleCloseMenuDrawer={() => this.setState({ collapsed: !collapsed })}
            {...this.props}
          />
          <div className="site-container">
            <Header
              isMobile={isMobile}
              collapsed={collapsed}
              toggleSidebar={() => this.setState({ collapsed: !collapsed })}
              {...this.props}
            />
            <Layout.Content className="site-content">
              <AppRoutes />
            </Layout.Content>
          </div>
        </Layout>
      </div>
    );
  }
}

export default App;
